const shared={
    check:'Waste',
    title:'Wastes',
    addTitle:'Waste',
    url:'waste',
    type:'waste',
    addApi:'api/emission-factor',
    editApi:'api/emission-factor',
    detailApi:'api/emission-factor',
    listApi:'api/emission-factors',
    statusApi:'api/emission-factor',
    deleteApi:'api/emission-factor'
}

export default shared