import environment from "../../environment"

const types=[
    {id:environment.resellerTypeId,name:'Reseller'},
    {id:environment.productTypeId,name:'Product'},
    {id:environment.sustainableId,name:'Sustainability'},
    {id:environment.vehicleTypeId,name:'Vehicle'},
    {id:environment.kpiTypeId,name:'KPIs'},
    // {id:environment.accommodationTypeId,name:'Accommodation'},
    {id:environment.activityTypeId,name:'Activity'},
    {id:environment.transportTypeId,name:'Transport Means'},
    {id:environment.goalTypeId,name:'Goal'},
    // {id:environment.emissionId,name:'Emission'},
]

const typeName=(id)=>{
    let ext=types.find(itm=>itm.id==id)
    return ext?ext.name:'--'
}


const shared={
    types:types,
    typeName:typeName
}

export default shared