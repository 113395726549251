/*
 * @file: index.js
 * @description: App Configration
 * @date: 3 April 2022
 * @author: Mohit
 * */

import React from 'react';
import { PersistGate } from 'redux-persist/es/integration/react';
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route,Navigate } from 'react-router-dom';
import configureStore from './config';
import { Auth } from './methods/auth';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Forgotpassword from './pages/Forgotpassword';
import Resetpassword from './pages/Resetpassword';
import Profile from './pages/Profile'
import Roleslist from './pages/Roleslist'
import Settings from './pages/Settings';
import ContentManagement from './pages/ContentManagement';
import './scss/main.scss';
import "react-pagination-js/dist/styles.css"; // import css
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-quill/dist/quill.snow.css';

import ContactDetails from './pages/Settings/ContactDetails';
import DashboardPage from './pages/Dashboard/DashboardPage';
import Users from './pages/Users';
import AddEditUser from './pages/Users/AddEditUser';
import UserDetail from './pages/Users/Profiledetail';
import Categories from './pages/Categories';
import AddEditCategory from './pages/Categories/AddEdit';
import CategoryDetail from './pages/Categories/Detail';
import ResellerCategories from './pages/ResellerCategories';
import ResellerCategoryDetail from './pages/ResellerCategories/Detail';
import AddEditResellerCategory from './pages/ResellerCategories/AddEdit';
import Employees from './pages/Employees';
import Roles from './pages/Roles';
import AddEditRole from './pages/Roles/AddEdit';
import AddEditContinent from './pages/Continents/AddEditContinent';
import Continent from './pages/Continents/index'
import Countriess from './pages/Countries';
import AddEditCountry from './pages/Countries/AddEditCountry';
import CountryDetail from './pages/Countries/Detail';
import Cities from './pages/CityPages';
import AddEditCity from './pages/CityPages/AddEditCity';
import CityDetail from './pages/CityPages/Detail';
import Plans from './pages/Plans';
import AddEditPlan from './pages/Plans/AddEdit';
import Features from './pages/Features';
import AddEditFeature from './pages/Features/AddEdit';
import Currency from './pages/CurrencyPages';
import AddEditCurrency from './pages/CurrencyPages/AddEditCurrency';
import CurrencyDetail from './pages/CurrencyPages/Detail';
import Coupons from './pages/Coupons';
import CouponsDetail from './pages/Coupons/Detail/index'
// import Department from './pages/Department';
import AddEditCoupon from './pages/Coupons/AddEdit';
import AddEditDepartment from './pages/Department/AddEdit';
import departmentDetail from './pages/Department/Detail/index'

import EmailTemplate from './pages/EmailTemplate';
import AddEditEmailTemplate from './pages/EmailTemplate/AddEdit';
import Regions from './pages/Regions';
import AddEditRegion from './pages/Regions/AddEdit';
import BookingSystem from './pages/BookingSystem';
import AddEditBookingSystem from './pages/BookingSystem/AddEdit';
import Types from './pages/CategoriesType';
import AddEditTypes from './pages/CategoriesType/AddEdit';
import ApplyCurrency from './pages/CurrencyPages/Applycurrencys';
import POS from './pages/POS';
import AddEditPOS from './pages/POS/AddEdit';
import Orders from './pages/POS/Orders/Orders';
import Holiday from './pages/Holiday';
import AddEditPrice from './pages/DynamicPrice/AddEdit';
import DynamicPrice from './pages/DynamicPrice';
import AddEditEarlyBirdPricing from './pages/DynamicPrice/Early/AddEdit';
import AddEditEarlyBirdTimeFrames from './pages/DynamicPrice/TimeFrames/AddEdit';
import AddEditEarlyBirdInventory from './pages/DynamicPrice/Inventory/AddEdit';
import AddEditProductCategory from './pages/Categories/ProductCategory/AddEdit';
import productCategory from './pages/Categories/ProductCategory/Product';
import AddEditLastMinuteFixedDates from './pages/DynamicPrice/LastMinuteFixedDates/AddEdit';
import AddEditLastMinuteInventory from './pages/DynamicPrice/LastMinuteInventory/AddEdit';
import Customer from "./pages/Customers";
import AddEditCustomer from "./pages/Customers/AddEdit";
import ViewCustomer from "./pages/Customers/Profiledetail";
import Reseller from './pages/ResellerPages';
import AddEditReseller from './pages/ResellerPages/AddEditReseller';
import CRM from "./pages/CRM";
import ViewCRM from "./pages/CRM/View";
import AddEditCRM from "./pages/CRM/AddEdit";
import LogoSetting from './pages/Setting/index';

import 'react-phone-input-2/lib/style.css'
import Reviews from './pages/Reviews/index';


import AccountingSystem from "./pages/AccountingSystem";
import AddEditAccountingSystem from "./pages/AccountingSystem/AddEdit";

import Holidays from './pages/Holidays';
import AddEditHolidays from './pages/Holidays/AddEdit';
import RefundReason from "./pages/RefundReason";
import RefundReasonAddEdit from "./pages/RefundReason/AddEdit";
import Waiver from './pages/Waiver';
import AddEditWaiver from './pages/Waiver/AddEdit';
import ViewWaiver from './pages/Waiver/View';
import SustainableCategory from './pages/Categories/SutainableCategory/Product';
import AddEditSustanabilityCategory from './pages/Categories/SutainableCategory/AddEdit';
import Department from './pages/Department'
import Leads from './pages/LeadModules';
import AddEditLeads from './pages/LeadModules/AddEdit';
import CompanyFacility from "./pages/CompanyFacility";
import AddEditCompanyFacility from "./pages/CompanyFacility/AddEdit";
import ViewCompanyFacility from "./pages/CompanyFacility/View";
import Fuel from "./pages/Fuel";
import AddEditFuel from "./pages/Fuel/AddEdit";
import ViewFuel from "./pages/Fuel/View";
import Vehicle from "./pages/Vehicle";
import AddEditVehicle from "./pages/Vehicle/AddEdit";
import ViewVehicle from "./pages/Vehicle/View";
import Waste from "./pages/Waste";
import AddEditWaste from "./pages/Waste/AddEdit";
import ViewWaste from "./pages/Waste/View";
import Procurement from "./pages/Procurement";
import AddEditProcurement from "./pages/Procurement/AddEdit";
import ViewProcurement from "./pages/Procurement/View";
import Accomodation from "./pages/Accomodation";
import AddEditAccomodation from "./pages/Accomodation/AddEdit";
import ViewAccomodation from "./pages/Accomodation/View";
import Flight from "./pages/Flights";
import AddEditFlight from "./pages/Flights/AddEdit";
import ViewFlight from "./pages/Flights/View";
import EmployeeCommuting from "./pages/EmployeeCommuting";
import AddEditEmployeeCommuting from "./pages/EmployeeCommuting/AddEdit";
import ViewEmployeeCommuting from "./pages/EmployeeCommuting/View";
import KPIs from "./pages/KPI";
import AddEditKPIs from "./pages/KPI/AddEdit";
import ViewKPIs from "./pages/KPI/View";
import EmissionFactor from './pages/EmissionFactor';
import AddEditEmissionFactor from './pages/EmissionFactor/AddEdit';
import EntryData from './pages/EntryData';
import AddEditEntryData from './pages/EntryData/AddEdit';

/************ store configration *********/
const { persistor, store } = configureStore();


export default () => {

    const routes = [
        {
            path: '/company-facility',
            component: CompanyFacility
        },
        {
            path: '/company-facility/add',
            component: AddEditCompanyFacility
        },
        {
            path: '/company-facility/edit/:id',
            component: AddEditCompanyFacility
        },
        {
            path: '/company-facility/detail/:id',
            component: ViewCompanyFacility
        },
        {
            path: '/fuel',
            component: Fuel
        },
        {
            path: '/fuel/add',
            component: AddEditFuel
        },
        {
            path: '/fuel/edit/:id',
            component: AddEditFuel
        },
        {
            path: '/fuel/detail/:id',
            component: ViewFuel
        },
        {
            path: '/vehicle',
            component: Vehicle
        },
        {
            path: '/vehicle/add',
            component: AddEditVehicle
        },
        {
            path: '/vehicle/edit/:id',
            component: AddEditVehicle
        },
        {
            path: '/vehicle/detail/:id',
            component: ViewVehicle
        },
        {
            path: '/waste',
            component: Waste
        },
        {
            path: '/waste/add',
            component: AddEditWaste
        },
        {
            path: '/waste/edit/:id',
            component: AddEditWaste
        },
        {
            path: '/waste/detail/:id',
            component: ViewWaste
        },
        {
            path: '/procurement',
            component: Procurement
        },
        {
            path: '/procurement/add',
            component: AddEditProcurement
        },
        {
            path: '/procurement/edit/:id',
            component: AddEditProcurement
        },
        {
            path: '/procurement/detail/:id',
            component: ViewProcurement
        },
        {
            path: '/accomodation',
            component: Accomodation
        },
        {
            path: '/accomodation/add',
            component: AddEditAccomodation
        },
        {
            path: '/accomodation/edit/:id',
            component: AddEditAccomodation
        },
        {
            path: '/accomodation/detail/:id',
            component: ViewAccomodation
        },
        {
            path: '/flight',
            component: Flight
        },
        {
            path: '/flight/add',
            component: AddEditFlight
        },
        {
            path: '/flight/edit/:id',
            component: AddEditFlight
        },
        {
            path: '/flight/detail/:id',
            component: ViewFlight
        },
        {
            path: '/employee-commuting',
            component: EmployeeCommuting
        },
        {
            path: '/employee-commuting/add',
            component: AddEditEmployeeCommuting
        },
        {
            path: '/employee-commuting/edit/:id',
            component: AddEditEmployeeCommuting
        },
        {
            path: '/employee-commuting/detail/:id',
            component: ViewEmployeeCommuting
        },
        {
            path: '/kpi',
            component: KPIs
        },
        {
            path: '/kpi/add',
            component: AddEditKPIs
        },
        {
            path: '/kpi/edit/:id',
            component: AddEditKPIs
        },
        {
            path: '/kpi/detail/:id',
            component: ViewKPIs
        },
        {
            path: '/entry-data',
            component: EntryData
        },
        {
            path: '/entry-data/add',
            component: AddEditEntryData
        },
        {
            path: '/entry-data/edit/:id',
            component: AddEditEntryData
        },
        {
            path: '/entry-data/detail/:id',
            component: ViewKPIs
        },
        {
            path: '/emission/:scope',
            component: EmissionFactor
        },
        {
            path: '/emission/:scope/add',
            component: AddEditEmissionFactor
        },
        {
            path: '/emission/:scope/edit/:id',
            component: AddEditEmissionFactor
        },
    ]

    return (<>
        <Provider store={store}>
            <PersistGate loading={'loading ...'} persistor={persistor}>

                <Router>
                    <Routes>
                        {routes.map(itm => {
                            return <Route exact={true} path={itm.path} store={store} key={itm.path} requireAuth={Auth} Component={itm.component} />
                        })}

                        <Route exact={true} path="/dashboard" store={store} requireAuth={Auth} Component={Dashboard} />
                        <Route exact={true} path="/employees" store={store} requireAuth={Auth} Component={Employees} />
                        <Route exact={true} path="/dashboardpage" store={store} requireAuth={Auth} Component={DashboardPage} />
                        <Route exact={true} store={store} path="/reviews/edit/:id" requireAuth={Auth} Component={LogoSetting} />
                        <Route exact={true} store={store} path="/setting" requireAuth={Auth} Component={LogoSetting} />
                        <Route exact={true} path="/settings/appointment/contact-details" store={store} requireAuth={Auth} Component={ContactDetails} />
                        <Route exact={true} path="/rolelist" store={store} requireAuth={Auth} Component={Roleslist} />
                        <Route exact={true} path="/pos" store={store} requireAuth={Auth} Component={POS} />
                        <Route exact={true} path="/pos/orders" store={store} requireAuth={Auth} Component={Orders} />
                        <Route exact={true} path="/pos/add" store={store} requireAuth={Auth} Component={AddEditPOS} />
                        <Route exact={true} path="/pos/edit/:id" store={store} requireAuth={Auth} Component={AddEditPOS} />
                        <Route exact={true} path="/users" store={store} requireAuth={Auth} Component={Users} />
                        <Route exact={true} path="/customer" store={store} requireAuth={Auth} Component={Customer} />
                        <Route exact={true} path="/customer/add" store={store} requireAuth={Auth} Component={AddEditCustomer} />
                        <Route exact={true} path="/customer/edit/:id" store={store} requireAuth={Auth} Component={AddEditCustomer} />
                        <Route exact={true} path="/customer/view/:id" store={store} requireAuth={Auth} Component={ViewCustomer} />

                        <Route exact={true} path="/roles" store={store} requireAuth={Auth} Component={Roles} />
                        <Route exact={true} path="/roles/add" store={store} requireAuth={Auth} Component={AddEditRole} />
                        <Route exact={true} path="/roles/edit/:id" store={store} requireAuth={Auth} Component={AddEditRole} />
                        <Route exact={true} path="/users/add" store={store} requireAuth={Auth} Component={AddEditUser} />
                        <Route exact={true} path="/users/:role" store={store} requireAuth={Auth} Component={Users} />
                        <Route exact={true} path="/users/edit/:id" store={store} requireAuth={Auth} Component={AddEditUser} />
                        <Route exact={true} path="/users/:role/add" store={store} requireAuth={Auth} Component={AddEditUser} />
                        <Route exact={true} path="/users/:role/edit/:id" store={store} requireAuth={Auth} Component={AddEditUser} />
                        <Route exact={true} path="/userDetail/:id" store={store} requireAuth={Auth} Component={UserDetail} />
                        <Route exact={true} path="/categories" store={store} requireAuth={Auth} Component={Categories} />
                        <Route exact={true} path="/categories/add" store={store} requireAuth={Auth} Component={AddEditCategory} />
                        <Route exact={true} path="/categories/edit/:id" store={store} requireAuth={Auth} Component={AddEditCategory} />
                        <Route exact={true} path="/category/:type" store={store} requireAuth={Auth} Component={Categories} />
                        <Route exact={true} path="/category/:type/add" store={store} requireAuth={Auth} Component={AddEditCategory} />
                        <Route exact={true} path="/category/product/:type" store={store} requireAuth={Auth} Component={productCategory} />
                        <Route exact={true} path="/category/product/:type/add" store={store} requireAuth={Auth} Component={AddEditProductCategory} />
                        <Route exact={true} path="/category/product/:type/edit/:id" store={store} requireAuth={Auth} Component={AddEditProductCategory} />


                        {/*  -------------------------------------------------------- */}
                        <Route exact={true} path="/category/sustanability/:type" store={store} requireAuth={Auth} Component={SustainableCategory} />
                        <Route exact={true} path="/category/sustanability/:type/add" store={store} requireAuth={Auth} Component={AddEditSustanabilityCategory} />
                        <Route exact={true} path="/category/sustanability/:type/edit/:id" store={store} requireAuth={Auth} Component={AddEditSustanabilityCategory} />

                        <Route exact={true} path="/category/:type/edit/:id" store={store} requireAuth={Auth} Component={AddEditCategory} />
                        <Route exact={true} path="/reseller-categories" store={store} requireAuth={Auth} Component={ResellerCategories} />
                        <Route exact={true} path="/reseller-categories/add" store={store} requireAuth={Auth} Component={AddEditResellerCategory} />
                        <Route exact={true} path="/reseller-categories/edit/:id" store={store} requireAuth={Auth} Component={AddEditResellerCategory} />
                        <Route exact={true} path="/reseller-categories/detail/:id" store={store} requireAuth={Auth} Component={ResellerCategoryDetail} />
                        <Route exact={true} path="/categoryDetail/:id" store={store} requireAuth={Auth} Component={CategoryDetail} />
                        <Route exact={true} path="/content" store={store} requireAuth={Auth} Component={ContentManagement} />
                        <Route exact={true} path="/profile" store={store} requireAuth={Auth} Component={Profile} />
                        <Route exact={true} path="/profile/:tab" store={store} requireAuth={Auth} Component={Settings} />
                        <Route exact={true} path="/login" store={store} requireAuth={Auth} Component={Login} />
                        <Route path='/' element={ <Navigate to="/login" /> }/>
                        <Route exact={true} path="/forgotpassword" store={store} requireAuth={Auth} Component={Forgotpassword} />
                        <Route exact={true} path="/resetpassword" store={store} requireAuth={Auth} Component={Resetpassword} />
                        {/* Categories Type */}
                        <Route exact={true} path="/types" store={store} requireAuth={Auth} Component={Types} />
                        <Route exact={true} path="/types/add" store={store} requireAuth={Auth} Component={AddEditTypes} />
                        <Route exact={true} path="/types/edit/:id" store={store} requireAuth={Auth} Component={AddEditTypes} />
                        {/* Feature */}
                        <Route exact={true} path="/features" store={store} requireAuth={Auth} Component={Features} />
                        <Route exact={true} path="/features/add" store={store} requireAuth={Auth} Component={AddEditFeature} />
                        <Route exact={true} path="/features/edit/:id" store={store} requireAuth={Auth} Component={AddEditFeature} />
                        {/* Continents */}
                        <Route exact={true} path="/continents" store={store} requireAuth={Auth} Component={Continent} />
                        <Route exact={true} path="/continents/add" store={store} requireAuth={Auth} Component={AddEditContinent} />
                        <Route exact={true} path="/continents/edit/:id" store={store} requireAuth={Auth} Component={AddEditContinent} />
                        {/* Plans */}
                        <Route exact={true} path="/plans" store={store} requireAuth={Auth} Component={Plans} />
                        <Route exact={true} path="/plans/add" store={store} requireAuth={Auth} Component={AddEditPlan} />
                        <Route exact={true} path="/plans/edit/:id/:copy" store={store} requireAuth={Auth} Component={AddEditPlan} />
                        {/* Booking System */}
                        <Route exact={true} path="/bookingSystem" store={store} requireAuth={Auth} Component={BookingSystem} />
                        <Route exact={true} path="/bookingSystem/add" store={store} requireAuth={Auth} Component={AddEditBookingSystem} />
                        <Route exact={true} path="/bookingSystem/edit/:id" store={store} requireAuth={Auth} Component={AddEditBookingSystem} />
                        {/* Accounting System */}
                        <Route exact={true} path="/accountingSystem" store={store} requireAuth={Auth} Component={AccountingSystem} />
                        <Route exact={true} path="/accountingSystem/add" store={store} requireAuth={Auth} Component={AddEditAccountingSystem} />
                        <Route exact={true} path="/accountingSystem/edit/:id" store={store} requireAuth={Auth} Component={AddEditAccountingSystem} />
                        {/* Coupon */}
                        <Route exact={true} path="/coupon" store={store} requireAuth={Auth} Component={Coupons} />
                        <Route exact={true} path="/coupon/add" store={store} requireAuth={Auth} Component={AddEditCoupon} />
                        <Route exact={true} path="/coupon/edit/:id" store={store} requireAuth={Auth} Component={AddEditCoupon} />
                        <Route exact={true} path="/coupon/view/:id" store={store} requireAuth={Auth} Component={CouponsDetail} />



                        {/*Department*/}
                        <Route exact={true} path="/department" store={store} requireAuth={Auth} Component={Department} />

                        <Route exact={true} path="/department/add" store={store} requireAuth={Auth} Component={AddEditDepartment} />

                        <Route exact={true} path="/department/view/:id" store={store} requireAuth={Auth} Component={departmentDetail} />

                        {/* Holidays */}
                        <Route exact={true} path="/holidays" store={store} requireAuth={Auth} Component={Holidays} />
                        <Route exact={true} path="/holidays/add" store={store} requireAuth={Auth} Component={AddEditHolidays} />
                        <Route exact={true} path="/holidays/edit/:id" store={store} requireAuth={Auth} Component={AddEditHolidays} />
                        {/* Country */}
                        <Route exact={true} path="/countries" store={store} requireAuth={Auth} Component={Countriess} />
                        <Route exact={true} path="/countries/add" store={store} requireAuth={Auth} Component={AddEditCountry} />
                        <Route exact={true} path="/countries/edit/:id" store={store} requireAuth={Auth} Component={AddEditCountry} />
                        <Route exact={true} path="/countries/:id" store={store} requireAuth={Auth} Component={CountryDetail} />
                        {/* Region */}
                        <Route exact={true} path="/regions" store={store} requireAuth={Auth} Component={Regions} />
                        <Route exact={true} path="/regions/add" store={store} requireAuth={Auth} Component={AddEditRegion} />
                        <Route exact={true} path="/regions/edit/:id" store={store} requireAuth={Auth} Component={AddEditRegion} />
                        {/* City */}
                        <Route exact={true} path="/cities" store={store} requireAuth={Auth} Component={Cities} />
                        <Route exact={true} path="/cities/add" store={store} requireAuth={Auth} Component={AddEditCity} />
                        <Route exact={true} path="/cities/edit/:id" store={store} requireAuth={Auth} Component={AddEditCity} />
                        <Route exact={true} path="/cities/:id" store={store} requireAuth={Auth} Component={CityDetail} />


                        <Route exact={true} path="/department/edit/:id" store={store} requireAuth={Auth} Component={AddEditDepartment} />
                        {/* Currency */}
                        <Route exact={true} path="/currency" store={store} requireAuth={Auth} Component={Currency} />
                        <Route exact={true} path="/currency/add" store={store} requireAuth={Auth} Component={AddEditCurrency} />
                        <Route exact={true} path="/currency/edit/:id" store={store} requireAuth={Auth} Component={AddEditCurrency} />
                        <Route exact={true} path="/currency/:id" store={store} requireAuth={Auth} Component={CurrencyDetail} />
                        {/* Apply Currency */}
                        <Route exact={true} path="/applycurrency" store={store} requireAuth={Auth} Component={ApplyCurrency} />
                        {/* Holiday */}
                        <Route exact={true} path="/holiday" store={store} requireAuth={Auth} Component={Holiday} />
                        {/* Last Minute Pricing */}
                        <Route exact={true} path="/dynamicprice/lastminutefixeddates/add" requireAuth={Auth} Component={AddEditLastMinuteFixedDates} />
                        <Route exact={true} path="/dynamicprice/lastminutefixeddates/edit/:id/:copy" requireAuth={Auth} Component={AddEditLastMinuteFixedDates} />
                        <Route exact={true} path="/dynamicprice/lastminuteinventory/add" requireAuth={Auth} Component={AddEditLastMinuteInventory} />
                        <Route exact={true} path="/dynamicprice/lastminuteinventory/edit/:id/:copy" requireAuth={Auth} Component={AddEditLastMinuteInventory} />
                        {/* Early Bird Pricing */}
                        <Route exact={true} path="/dynamicprice/earlybirdcustomdates/add" requireAuth={Auth} Component={AddEditEarlyBirdPricing} />
                        <Route exact={true} path="/dynamicprice/earlybirdcustomdates/edit/:id/:copy" requireAuth={Auth} Component={AddEditEarlyBirdPricing} />
                        <Route exact={true} path="/dynamicprice/earlybirdtimeframes/add" requireAuth={Auth} Component={AddEditEarlyBirdTimeFrames} />
                        <Route exact={true} path="/dynamicprice/earlybirdtimeframes/edit/:id/:copy" requireAuth={Auth} Component={AddEditEarlyBirdTimeFrames} />
                        <Route exact={true} path="/dynamicprice/earlybirdinventory/add" requireAuth={Auth} Component={AddEditEarlyBirdInventory} />
                        <Route exact={true} path="/dynamicprice/earlybirdinventory/edit/:id/:copy" requireAuth={Auth} Component={AddEditEarlyBirdInventory} />
                        {/* Dynamic Price */}
                        <Route exact={true} path="/dynamicpricelist" store={store} requireAuth={Auth} Component={DynamicPrice} />
                        <Route exact={true} path="/dynamicprice/add" store={store} requireAuth={Auth} Component={AddEditPrice} />
                        <Route exact={true} path="/dynamicprice/:type" store={store} requireAuth={Auth} Component={DynamicPrice} />
                        <Route exact={true} path="/dynamicprice/:type/add" store={store} requireAuth={Auth} Component={AddEditPrice} />
                        <Route exact={true} path="/dynamicprice/:type/edit/:id/:copy" store={store} requireAuth={Auth} Component={AddEditPrice} />

                        <Route exact={true} path="/crm" store={store} requireAuth={Auth} Component={CRM} />
                        <Route exact={true} path="/crm/add" store={store} requireAuth={Auth} Component={AddEditCRM} />
                        <Route exact={true} path="/crm/edit/:id" store={store} requireAuth={Auth} Component={AddEditCRM} />
                        <Route exact={true} path="/crm/view/:id" store={store} requireAuth={Auth} Component={ViewCRM} />


                        {/*  Here are the Routes for the Waiver Module  */}

                        <Route exact={true} path="/waiver" store={store} requireAuth={Auth} Component={Waiver} />
                        <Route exact={true} path="/waiver/add" store={store} requireAuth={Auth} Component={AddEditWaiver} />
                        <Route exact={true} path="/waiver/edit/:id" store={store} requireAuth={Auth} Component={AddEditWaiver} />
                        <Route exact={true} path="/waiver/view/:id" store={store} requireAuth={Auth} Component={ViewWaiver} />

                        {/* Email Template */}
                        <Route exact={true} path="/emailtemplate" store={store} requireAuth={Auth} Component={EmailTemplate} />
                        <Route exact={true} path="/emailtemplate/add" store={store} requireAuth={Auth} Component={AddEditEmailTemplate} />
                        <Route exact={true} path="/emailtemplate/edit/:id" store={store} requireAuth={Auth} Component={AddEditEmailTemplate} />

                        {/*  For Reseller  */}
                        <Route exact={true} path="/reseller" store={store} requireAuth={Auth} Component={Reseller} />
                        <Route exact={true} path="/reviews" store={store} requireAuth={Auth} Component={Reviews} />
                        <Route exact={true} path="/reseller/addedit/:id?" store={store} requireAuth={Auth} Component={AddEditReseller} />

                        {/* Refund Reson */}
                        <Route exact={true} path="/refund-reason" store={store} requireAuth={Auth} Component={RefundReason} />
                        <Route exact={true} path="/refund-reason/add" store={store} requireAuth={Auth} Component={RefundReasonAddEdit} />
                        <Route exact={true} path="/refund-reason/edit/:id" store={store} requireAuth={Auth} Component={RefundReasonAddEdit} />

                        <Route exact={true} path="/leads" store={store} requireAuth={Auth} Component={Leads} />
                        <Route exact={true} path="/leads/add/:id?" store={store} requireAuth={Auth} Component={AddEditLeads} />
                    </Routes>
                </Router>
            </PersistGate>
        </Provider>
        <div id="loader" className="loaderDiv d-none">
            <div>
                <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
            </div>
        </div>

    </>
    );
};
