import React, { useState, useEffect, useRef } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";
import statusModel from "../../models/status.model";
import { couponType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import SelectDropdown from "../../components/common/SelectDropdown";
import datepipeModel from "../../models/datepipemodel";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, Form, Space } from 'antd';
import ReactDatePicker from "react-datepicker";
const { RangePicker } = DatePicker;
import { Tooltip } from "antd";
import { LeadsType } from "../../models/type.model";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import addressModel from "../../models/address.model";
import { FaSpinner } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import { LuMail } from "react-icons/lu";
import ImageUpload from "../../components/common/ImageUpload";
import PhoneInput from "react-phone-input-2";
import { CountryCodeFinder } from "../../components/common/CountryFile/CountryCodeList";

const AddEditLeads = () => {
    const calendorref = useRef();
    const [blur, setblur] = useState(false)
    const defaultvalue = () => {
        let keys = { ...LeadsType }
        Object.keys(LeadsType).map(itm => {
            keys[itm] = ''
        })
        keys.status = 'active'
        return keys
    }
    const { id } = useParams()
    const minDate = new Date()
    const [form, setform] = useState(defaultvalue())
    const [loaging, setLoader] = useState(false)
    const [categories, setCategories] = useState(false)
    const [category, setCategory] = useState([])
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'status', required: true },
        { key: 'discountType', required: true },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if(!AddressSellected){return}
        let invalid = methodModel.getFormError(formValidation, form)

        // if (form.discountType == 'Percentage' && Number(form.discountAmount) > 100) {
        //     toast.error("Discount Amount Maximum value is 100")
        //     return
        // }

        // if (invalid) return
        if(!form?.logo){return}
        let method = 'post'
        let url = 'api/lead'
        let value = {
            ...form
        }
        if (value.id) {
            method = 'put'
            url = 'api/lead/update'
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                history("/leads")
            }
            loader(false)
        })
    }

 

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get('api/lead/detail', { id }).then(res => {
                if (res.success) {
                    let value = res.data 
                    let payload = couponType
                    console.log(payload,'payload')
                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })
                    setAddressSellected(true)
let resultUpload=value?.logo.includes('https')?true:false;
                    setform({
                        ...value,
                        uploadLogo:resultUpload
                    });
                    setblur(true)
                }
                loader(false)
            })
        } 
    }, [id])

    const isExist = (p) => {
        let value = false
        if (category.find(itm => itm == p)) {
            value = true
        }
        return value
    }

    const setcheckbox = (arr = []) => {
        let els = document.querySelectorAll("input.categoryCheckbox")
        els.forEach(el => {
            if (arr.find(itm => itm == el.id)) {
                el.checked = true
            } else {
                el.checked = false
            }
        })
    }

    const checkbox = (itm) => {
        let arr = category
        let exist = isExist(itm.id)
        if (exist) {
            arr = arr.filter(aitm => aitm != itm.id)
        } else {
            arr.push(itm.id)
        }
        setcheckbox(arr)
        setCategory(arr)
    }

    const toggle = (pi, si = -1, ssi = -1) => {
        let arr = categories
        if (ssi >= 0) {
            arr[pi].childCategories[si].childCategories[ssi].active = true
        } else if (si >= 0) {
            arr[pi].childCategories[si].active = true
        } else if (pi >= 0) {
            arr[pi].active = true
        }
        setCategories(p => p = arr)
    }

    const imageResult = (e, key) => {
        setform({...form,logo:e.value})
    }

    const TableRow = ({ itm, className, pi = -1, si = -1, ssi = -1 }) => {
        return <tr className={`data_row ${className}`}>
            <td className="checkboxTd" onClick={e => checkbox(itm)}>
                <input type="checkbox" className="categoryCheckbox" disabled id={`${itm.id}`}
                //  checked={isExist(itm.id)}
                />
                {itm.checked}
            </td>
            <td className='table_dats'> <div className='user_detail' onClick={e => checkbox(itm)}>
                <img src={methodModel.userImg(itm.banner)} className="user_imgs" />
                <div className='user_name'>
                    <h4 className='user'>
                        {itm.name}
                    </h4>
                </div>
            </div></td>
            <td className="text-right">
                <i className={`fa ${itm.active ? 'fa-chevron-up' : 'fa-chevron-down'}`} onClick={e => toggle(pi, si, ssi)}></i>
            </td>
        </tr>
    }

    //  For Dedtapicker 
    const dateconvert = (date) => {
        console.log(date, "THis is the date")

        const startdatedate = date[0];
        const enddate = date[1];
        const convertedstart = datepipeModel.datetostring(startdatedate);
        const convertedend = datepipeModel.datetostring(enddate);
        setform({ ...form, dateFrom: convertedstart, dateTo: convertedend });
        // alert(convertedstart)
        // alert(convertedend)
    }

    const [TimezoneLoader, setTimezoneLoader]=useState(false);
    const [AddressSellected, setAddressSellected]=useState(false)

    const addressResult = async (e) => {
        console.log(e,"This is the consoled data ")
        let address = {}
        if (e.place) {
            address = addressModel.getAddress(e.place)
            console.log(address, "This is the main data which we recieve after the data doing ---------")
            setAddressSellected(true)
        } else {
            setAddressSellected(false)
        }
        setform({
            ...form,
            
            address: e.value,
            country: address.country || '',
            city: address.city || '',
            state: address.state || '',
            zipcode: address.zipcode || '',
            lat: address.lat || '',
            lng: address.lng || ''
        })
        if (e.place) {
            setTimezoneLoader(true)
            const apires = await addressModel.gettimeZone(e.place)
            setTimezoneLoader(false)

            const code = CountryCodeFinder(address.country,'000999')
            setform({
                ...form,
                address: e.value,
                mobileNo: code,
                countryCode: address.countryCode || '',
                country: address.country || '',
                city: address.city || '',
                state: address.state || '',
                pinCode: address.zipcode || '',
                lat: address.lat || '',
                lng: address.lng || '',
                timezone: apires?.data?.timeZoneId || ''
            })
        }
    }
    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">

                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to="/leads" className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border  transition-all    mr-3"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                           <h3 className="text-base font-medium text-[#0A0D14]">
                                {form && form.id ? 'Edit' : 'Add'} Lead
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Leads</p>
                        </div>
                    </div>


                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <label>Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                                value={form.name}
                                onChange={e => setform({ ...form, name: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Logo<span className="star">*</span></label>
                            <div className="col-span-8">
                                <div className="flex flex-wrap items-center gap-3">
                                    <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-10 !px-3 shadow-box !rounded-md">
                                        <input
                                            className="h-4 w-4 checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary"
                                            type="radio"
                                            value="yes"
                                            checked={form.uploadLogo ? true : false}
                                            onChange={(e) =>
                                                setform({ ...form, uploadLogo: true,logo:'' })
                                            }
                                            name="showAddress"
                                        />
                                        Enter URL
                                    </label>
                                    <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-10 !px-3 shadow-box !rounded-md">
                                        <input
                                            className="h-4 w-4 checked:!ring-[#71B55C] checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary"
                                            type="radio"
                                            value="no"
                                            checked={!form.uploadLogo ? true : false}
                                            onChange={(e) =>
                                                setform({ ...form, uploadLogo: false })
                                            }
                                            name="showAddress"
                                        />
                                        Upload
                                    </label>
                                </div>
                                {form.uploadLogo ? (
                                    <>
                                        <div className="relative mt-[18px]">
                                            <input
                                                type="text"
                                                className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                                                value={form?.logo}
                                                onChange={e => setform({ ...form, logo: e.target.value })}
                                                required
                                            />
                                            
                                            <BsCheckCircleFill className="text-[#6db562] text-lg opacity-30 absolute top-[11px] right-3.5" />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                            <div className="col-span-12 md:col-span-6 mt-2">
                                                <ImageUpload model="users" result={e => imageResult(e, 'banner')} value={form?.logo} />
                                            </div>
                                    </>
                                )}
                                {submitted&&!form?.logo?<p className="text-red-600">Logo is required</p>:null}
                            </div>
                            
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Address<span className="star">*</span></label>
                            <GooglePlaceAutoComplete
                                value={form.address}
                                result={addressResult}
                                id="address"
                                placeholder="Type address"
                            />

                            {submitted&&!AddressSellected?<span className="text-red-600">Address is Required</span>:null}
                        
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <label>TimeZone  {TimezoneLoader && (
                                <FaSpinner className="animate-spin inline-block" />
                            )}<span className="star">*</span></label>
                            <input
                                type="text"
                                disabled
                                className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                                value={form?.timezone}
                                onChange={e => setform({ ...form, timezone: e.target.value })}
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <label>Mobile Number<span className="star">*</span></label>
                           <div className="mobile_number">
                           <PhoneInput
                                className="custom-input"
                                country={'us'}
                                value={form.mobileNo}
                                enableSearch={true}
                                inputStyle={{}}
                                limitMaxLength
                                placeholder=""
                                required
                                onChange={e => setform({ ...form, mobileNo: e })}
                                countryCodeEditable={true}
                            />
                           </div>
                            </div>

                        <div className="col-span-12 md:col-span-6">
                            <label>Email<span className="star">*</span></label>
                            <input
                                type="email"
                                className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                                value={form?.email}
                                onChange={e => setform({ ...form, email: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Website Address<span className="star">*</span></label>
                            <input
                                type="url"
                                className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                                value={form?.website}
                                onChange={e => setform({ ...form, website: e.target.value })}
                                required
                            />
                        </div>
                
                        <div className="col-span-12 md:col-span-6">
                            <label>Status<span className="star">*</span></label>
                            <div className="custom_dropdown">
                                <SelectDropdown
                                    isSingle={true}
                                    id="statusDropdown"
                                    displayValue="name"
                                    intialValue={form.status}
                                    result={e => { setform({ ...form, status: e.value }) }}
                                    options={statusModel.list}
                                />
                                {submitted && !form.status ? <div className="text-danger">Status is Required</div> : <></>}
                            </div>
                        </div>

            
             

                
               
                    
    
        
               
               
  
                    </div>

                    <div className="flex justify-end mt-6">

                        <button type="submit" className=" text-white bg-[#545454] font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                    </div>




                </div>


            </form>
        </Layout>


        <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Select Category</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AddEditLeads