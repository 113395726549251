import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import methodModel from '../../methods/methods';
// import DatePicker from "react-datepicker";
import datepipeModel from '../../models/datepipemodel';
import { DatePicker, Space } from 'antd';
import DateRangePicker from "../../components/common/DateRangePicker";
import moment from 'moment';
const { RangePicker } = DatePicker;
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { PiFileCsv } from 'react-icons/pi';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { FiEdit3, FiPlus } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';
import { useState } from 'react';



const Html = ({
    sortClass,
    sorting,
    tab,
    minDate,
    filter,
    reset,
    edit,
    view,
    tabChange,
    colClick,
    ChangeRole,
    ChangeStatus,
    openModal,
    statusChange,
    pageChange,
    addCol,
    deleteItem,
    dynamicStyle = false,
    className = null,
    exportCsv,
    uTableCols,
    removeCol,
    filters,
    tableCols,
    loaging,
    data,
    dateconvert,
    types,
    exportfun,
    total={total},
    isAllow
   
}) => {
    return (
        <Layout>
            <div className="flex justify-between items-center">

                <div>
                   <h3 className="text-base font-medium text-[#0A0D14]">
                        Coupons
                    </h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Coupons</p>
                </div>
                <div className="flex filterFlex">

                    <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed mr-3" onClick={() => exportfun()}>
                        <PiFileCsv className="text-typo text-xl" />  Export CSV
                    </button>

                    {isAllow('addCoupons') ?
                        <Link className="bg-primary border border-solid border-[#27A376] shadow-[1px 2px 4px 0px #0d0d121f] py-[10px] px-[12px] leading-[20px] mr-[8px] flex items-center   hover:bg-[#71B55C] text-[14px]  rounded-[8px] text-white  font-medium hover:no-underline" to="/coupon/add">
                            <FiPlus className="text-xl text-white w-[18px] h-[18px] me-[4px]" /> Add Coupon
                        </Link>
                        : <></>}

                </div>



            </div>


            <div className="shadow-box w-full bg-white rounded-[16px] mt-6">
                <div className='flex justify-between p-4'>
                    <div className='dropdown addDropdown  chnages_date'>

                    <div>
                    <DateRangePicker
                    value={
                        filters.startDate
                        ? {
                            startDate: new Date(filters.startDate),
                            endDate: new Date(filters.endDate),
                            }
                        : { startDate: "", endDate: "" }
                    }
                    id="daterange"
                    onChange={(e) => blockDateChange(e)}
                    />
                </div>

                        {/* <DatePicker
                        selected={datepipeModel.stringToDate(filters.dateFrom)}
                        className="form-control"
                        minDate={minDate}
                        placeholderText="Date From & Date To"
                        onChange={(date) => filter({ dateFrom: datepipeModel.datetostring(date) })}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    /> */}
                        {/* <DatePicker
                        selected={datepipeModel.stringToDate(filters.dateTo)}
                        className="form-control"
                        placeholderText="Date To"
                        minDate={datepipeModel.stringToDate(filters.dateFrom) || minDate}
                        onChange={(date) => filter({ dateTo: datepipeModel.datetostring(date) })}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    /> */}
                        {/* {filters.dateFrom || filters.dateTo || filters.status ? <>
                            <button className="btn btn-primary ml-3" onClick={e => reset()}>
                                Reset
                            </button>
                        </> : <></>} */}
                    </div>


                    <div className="dropdown1 addDropdown1 ">

                        <Menu as="div" className="relative mr-3 list_box_active_state ml-auto">
                            <div>
                                <Menu.Button className="flex items-center w-full border justify-center rounded-[8px] bg-white py-[9px] px-[12px] text-[14px]   font-normal  text-[#525866]  hover:bg-gray-200 font-medium -tracking-[0.084px] border-[#DFE1E6]">
                                    {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}

                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#6db562] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                    }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-lg scrollbar`}>
                                    <div className="mt-2">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active mb-0' : 'text-gray-700 block px-4 py-2 text-sm mb-0'} onClick={() => ChangeStatus("")}>All Status</a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm active mb-0' : 'text-gray-700 block px-4 py-2 text-sm mb-0'} onClick={() => ChangeStatus("active")} >Active</a>
                                            )}
                                        </Menu.Item>

                                        {/* <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                            <p className="border-t"></p>
                                        </Menu.Item> */}


                                        <Menu.Item className="">
                                            {({ active }) => (
                                                <a className={filters.status == 'Inactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                                            )}
                                        </Menu.Item>

                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>




                    </div>

                </div>

<div className='mx-[24px] pt-[24px] border-t border-[#E2E4E9]'>
<div className="scrollbar w-full overflow-auto ">
                    <table class="w-full">
                        <thead className="text-[#525866] text-[14px] capitalize bg-[#F6F8FA] font-normal">
                            <tr>
                                <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]' onClick={e => sorting('title')}>Title<span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]' onClick={e => sorting('createdAt')}>Date<span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]' onClick={e => sorting('discountType')}>Discount Type<span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]' onClick={e => sorting('discountAmount')}>Discount Amount<span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]'>Status</th>
                                <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loaging && data && data.map((itm, i) => {
                                return <tr className=''>
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]' onClick={e => edit(itm.id)}>{itm.title}</td>
                                    {/* <td className='!text-typo !border-l-0  !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => edit(itm.id)}>{itm.dateFrom} - {itm.dateTo}</td> */}
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{datepipeModel.date(itm.dateFrom)} - {datepipeModel.date(itm.dateTo)}</td>
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{itm.discountType}</td>
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{itm.discountAmount}</td>
                                    {/* <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9] pointer' onClick={e => edit(itm.id)}>{datepipeModel.datetime(itm.createdAt)}</td> */}
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'> <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                        <Tooltip placement="top" title="Acitve / Inactive">
                                            <span className='contract'>
                                                {itm.status == 'deactive' ? 'Inactive' : 'Active'}
                                            </span>
                                        </Tooltip>
                                    </div></td>

                                    {/* dropdown */}
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>
                                        <div className="flex items-center  gap-1.5">
                                            {isAllow('editCoupons') ?
                                                <Tooltip placement="top" title="Edit">
                                                    <a className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                                        <FiEdit3 className='text-[#2b2b2bb3]' />
                                                    </a>
                                                </Tooltip>
                                                : <></>}
                                            {isAllow('deleteCoupons') ?
                                                <Tooltip placement="top" title="Delete">
                                                    <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl' onClick={() => deleteItem(itm.id)}>
                                                        <BsTrash3 />
                                                    </span>
                                                </Tooltip>
                                                : <></>}
                                        </div>
                                    </td>
                                    {/* end */}
                                </tr>
                            })
                            }
                        </tbody>
                    </table>
                </div>
</div>
               


                {!loaging && total == 0 ?  <div className="no-data-found text-center p-5">
                    <img
                        src="/assets/img/no-data-f.png"
                        className="w-[100px] mx-auto	opacity-50	"
                    />
                    <p className="uppercase	text-sm tracking-widest ">No Data Found</p>
                    </div> : <></>}
                {
                    !loaging && total > filters.count ? <div className='paginationWrapper p-4 mt-15'>
                        {/* <span>Show {filters.count} from {total} Categories</span> */}
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </div>
        </Layout>
    );
};

export default Html;
