let host = document.location.host;
let apiUrl ='https://ecoapi.dazhboards.com/'
// if(host.includes('localhost')){
//   apiUrl='http://74.208.74.173:6031/'
// }else if(host.includes('stageadmin.ecometrixs.com')){
//   apiUrl='http://74.208.74.173:6031/'
// }else{
//   apiUrl='http://74.208.74.173:6031/'
// }

const environment = {
    api: apiUrl,
  tinyapiKey:"umvdq7vkiacu5ldyurqgqxct553ehzvebsrsccd42pna9x99",

    // map_api_key:'AIzaSyCbRhC6h9Pp43-5t_Knyrd_ewAdLMIJtCg',
    map_api_key:'AIzaSyAID0kSQQmScLcv5PornXUOEG0LPn8hfKQ',
    travellingCatId:'663c6330b5d876d3de39858c',
    accommodationTypeId:'661fd0257c7ddfb85d1c19ba',
    activityTypeId:'661fd3337c7ddfb85d1c2115',
    planTypeId:'64ad2ebce5aa778d26a54f02',
    vehicleTypeId:'66177a0d73b003ed224b7db5',
    transportTypeId:'6629fb8dff82c17bdc606a05',
    kpiTypeId:'6618f9bcf517b68368e00f8b',
    goalTypeId:'6673ce5551bae5b04d456e0d',
    userRoleId:'64b15102b14de6c28838f7d2',
    sustainableId:"64b149e3cd1f5ed827c13812",
    emissionId:"6668319fca7009c405c22000",
    ghgEmissionId:"666839b20a801a7b512885a0",
    adminRoleId:'64b152a909d268f038611929',
    resellerTypeId:'64b23b7d690d1d5f7ae76102',
    productTypeId:'64a7d198fa039f179c0320ca'
  };
  export default environment;
