import { useState } from 'react'
import methodModel from '../../../methods/methods'
import SelectDropdown from '../SelectDropdown';
import MultiSelectDropdown from "../MultiSelectDropdown";
import './style.scss'
import ReactQuill from 'react-quill';

export default function FormControl({
    name,
    id = "",
    displayValue = 'name',
    placeholder = "",
    type = 'text',
    options = [],
    error,
    label,
    required = false,
    onInputChange=(e)=>{},
    onChange,
    maxlength = '',
    minlength = '',
    min='',
    value,
    theme='',
    disabled=false
}) {


    const [text,setText]=useState('')

    const add=()=>{
        let arr=value||[]
        if(text){
            arr.push(text)
        }
        onChange(arr)
        setText('')
    }

    const remove=(i)=>{
        let arr=value||[]
        arr=arr.filter((itm,index)=>index!=i)
        onChange(arr)
    }

    const addItem = (v) => {
        let arr = value || []
        let ext = arr?.find((itm) => itm == v)

        if (ext) {
            arr = arr.filter(itm=>itm != v)
        } else {
            arr.push(v)
        }

        onChange(arr)
    }

    return <>
        <div className="formWrapper h-100">
            {label ? <>
                <label>{label} {required ? <><span className="star">*</span></> : <></>}</label>
            </> : <></>}

            {type=='toggle'?<>
            <div className=''>
            <label class="flex  cursor-pointer mb-0 items-center mt-1 ">
  <input type="checkbox" checked={value} onChange={e=>onChange(e.target.checked)} value="" class="sr-only peer"/>
  <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#71B55C]"></div>
  {/* <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> */}
</label>
            </div>
            


            </>:type=='textarea'?<>
            <textarea rows={5} 
            name={name}
            className="relative shadow-box bg-white w-full rounded-lg flex items-center gap-2 overflow-hidden px-2"
            required={required}
            placeholder={placeholder}
            value={value||''}
            maxLength={maxlength}
            minLength={minlength}
            min={min}
            disabled={disabled}
            onChange={e => onChange(e.target.value)}
            ></textarea>
            </>:type == 'select' ? <SelectDropdown
                id={`statusDropdown_${id}`}
                displayValue={displayValue}
                placeholder={placeholder}
                onInputChange={onInputChange}
                intialValue={value||''}
                name={name}
                theme={theme}
                result={e => { onChange(e.value) }}
                options={options}
            /> :type == 'multiselect' ? <MultiSelectDropdown
            id={`statusDropdown_${id}`}
            displayValue={displayValue}
            placeholder={placeholder}
            intialValue={value||''}
            name={name}
            theme={theme}
            result={e => { onChange(e.value) }}
            options={options}
        /> : type == 'number' ? <input
                type="text"
                name={name}
                className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                required={required}
                placeholder={placeholder}
                value={value||''}
                maxLength={maxlength}
                minLength={minlength}
                min={min}
                disabled={disabled}
                onChange={e => onChange(methodModel.isNumber(e))}
            /> : type=='badge'?<>
            <div className='flex'>
            <input
                type="text"
                className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                placeholder={placeholder}
                value={text}
                onChange={e => setText(e.target.value)}
            />
            <button type='button' className='btn btn-primary ml-2' onClick={add}>Add</button>
            </div>
            <div className=''>
                {value?.map((itm,i)=>{
                    return <span className='badge badge-primary m-1'>
                        {itm}
                    <i className='fa fa-times ml-1' onClick={()=>remove(i)}></i>
                </span>
                })}
                
            </div>
            </>:type=='radio'?<>
            {options.map(itm => {
                                return <label className="flex">
                                    <input type="radio"
                                        checked={value == itm.id ? true : false}
                                        onChange={e => onChange(itm.id)}
                                        className="mr-2" name={name} disabled={disabled} />
                                    {itm.name}</label>
                            })}
            </>:type=='editor'?<>
            <ReactQuill theme="snow" value={value} onChange={e=>{
                               onChange(e)
                            }} />
            </>:type=='checkbox'?<>
            {options.map(itm => {
                                return <label className="flex">
                                    <input type="checkbox"
                                        checked={value?.includes(itm.id) ? true : false}
                                        onChange={e =>addItem(itm.id)}
                                        className="mr-2" disabled={disabled}/>
                                    {itm.name}</label>
                            })}
            </>:<input
                type={type}
                name={name}
                className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                required={required}
                placeholder={placeholder}
                value={value||''}
                maxLength={maxlength}
                minLength={minlength}
                min={min}
                disabled={disabled}
                onChange={e => onChange(e.target.value)}
            />
            }

            {error ? <>
                <div className='text-danger small mt-1'>{error}</div>
            </> : <></>}
        </div>
    </>
}