import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import statusModel from "../../models/status.model";
import FormControl from "../../components/common/FormControl";


const Waste = ({form, setform,getError}) => {

    const [country, setCountry] = useState([])
    const [countryLoader, setCountryLoader] = useState(false)


    const units = [
        "kgCO2e/kg",
        "kgCO2e/GJ",
        "kgCO2e/litre"
    ].map(itm => ({ name: itm, id: itm }))

    const scopes = [1, 2, 3].map(itm => ({ name: itm, id: itm }))

    const getCountries = (p = {}) => {
        setCountryLoader(true)
        ApiClient.get('api/country/listing', { status: 'active', sortBy: 'name asc', ...p }).then(res => {
            setCountryLoader(false)
            if (res.success) {
                setCountry(res.data)
            }
        })
    }


    useEffect(() => {
        getCountries()
    }, [])

    return <>
          <div className="col-span-12 md:col-span-6">
            <FormControl
                type="text"
                label="Waste Type"
                value={form.emission_source}
                onChange={e => setform({ ...form, emission_source: e })}
                required
            />
        </div>
          <div className="col-span-12 md:col-span-6">
            <FormControl
                type="text"
                label="Disposal Type"
                value={form.disposal_type}
                onChange={e => setform({ ...form, disposal_type: e })}
                required
            />
        </div>
          <div className="col-span-12 md:col-span-6">
            <FormControl
                type="number"
                label="Emission Factor"
                value={form.emission_factor}
                onChange={e => setform({ ...form, emission_factor: e })}

            />
        </div>

          <div className="col-span-12 md:col-span-6">
            <FormControl
                type="text"
                label="Tier"
                value={form.tier}
                onChange={e => setform({ ...form, tier: e })}

            />
        </div>

          <div className="col-span-12 md:col-span-6">
            <FormControl
                type="select"
                label="Unit"
                options={units}
                placeholder="Select Option"
                value={form.unit}
                theme="search"
                onChange={e => setform({ ...form, unit: e })}

            />
        </div>

          <div className="col-span-12 md:col-span-6">
            <FormControl
                type="multiselect"
                label="Scope"
                placeholder="Select Option"
                value={form.scope}
                options={scopes}
                theme="search"
                onChange={e => setform({ ...form, scope: e })}
                required
            />
        </div>


          <div className="col-span-12 md:col-span-6">
            <FormControl
                type="select"
                onInputChange={e => {
                    // getCountries({search:e})
                }}
                label={<>
                    Location
                    {countryLoader ? <>
                        <span className="ml-2 text-success text-xs">Loading...</span>
                    </> : <></>}
                </>}
                placeholder="Globally"
                options={[
                    { id: '', name: 'Globally' },
                    ...country
                ]}
                value={form.country_id}
                theme="search"
                onChange={e => setform({ ...form, country_id: e })}
            />
        </div>

          <div className="col-span-12 md:col-span-6">
            <FormControl
                type="text"
                label="Source"
                value={form.source}
                onChange={e => setform({ ...form, source: e })}

            />
        </div>


          <div className="col-span-12 md:col-span-6">
            <FormControl
                type="select"
                name="status"
                label="Status"
                displayValue="name"
                placeholder="Select Status"
                value={form.status}
                onChange={e => setform({ ...form, status: e })}
                options={statusModel.list}
                required
                error={getError('status')}
            />
        </div>


    </>
}

export default Waste