const shared={
    check:'EmployeeCommuting',
    title:'Employee Commuting',
    addTitle:'Employee Commuting',
    url:'employee-commuting',
    type:'employee_commuting',
    addApi:'api/emission-factor',
    editApi:'api/emission-factor',
    detailApi:'api/emission-factor',
    listApi:'api/emission-factors',
    statusApi:'api/emission-factor',
    deleteApi:'api/emission-factor'
}

export default shared