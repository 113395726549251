import Layout from "../../components/global/layout";
import { useEffect, useState } from "react";
import List from "./list";

export default function EmissionFactor(){

    return <>
    <Layout>
    {/* <h3 className="text-base font-medium text-[#0A0D14] mb-3">GHG Emission Factors {scope}</h3> */}
    <List />
    </Layout>
    </>
}