const flightTypes=[
    {id:'domestic_flight',name:'Domestic Flight'},
    {id:'international_flight',name:'International Flight'},
    {id:'helicopter',name:'Helicopter'},
]

const typeName=(id)=>{
    let ext=flightTypes.find(itm=>itm.id==id)
    return ext?ext.name:id
}

const shared={
    check:'Flight',
    title:'Flights',
    addTitle:'Flight',
    url:'flight',
    type:'flights',
    flightTypes:flightTypes,
    flightTypeName:typeName,
    addApi:'api/emission-factor',
    editApi:'api/emission-factor',
    detailApi:'api/emission-factor',
    listApi:'api/emission-factors',
    statusApi:'api/emission-factor',
    deleteApi:'api/emission-factor'
}

export default shared