import React from "react";
import './style.scss';
import Select from "react-select";
const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#FFF',  // Control background color
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#FFF' : '#333',  // White text on hover, default text color
    backgroundColor: state.isFocused ? '#71B55C' : 'transparent',  // Light green background on hover
    '&:active': {
      backgroundColor: '#71B55C',  // Keep consistent background color when active
    },
  }),
};
const Html = ({ options,selectedValues,handleChange,displayValue,id}) => {
    return <>
        <div className="selectDropdown">
             <Select
    defaultValue={displayValue}
    isMulti
    value={selectedValues||[]}
    options={options?.map(itm => { return { value: itm.id, label: itm[displayValue] } }) || []}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={e => handleChange(e)}
    styles={customStyles}
    theme={(theme) => ({
        ...theme,
       
        colors: {
          ...theme.colors,
          primary25: '#71B55C',
          primary: '#71B55C',
          neutral80: '#333',
        },
      })}
  />
        </div>
    </>
}

export default Html