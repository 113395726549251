import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login_success } from '../../actions/user';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import { Link } from 'react-router-dom';
import './style.scss';
import { toast, ToastContainer } from 'react-toastify';

const Login = () => {
  const history = useNavigate();
  const user = useSelector(state => state.user)
  useEffect(() => {
    if (localStorage.getItem('token')) {
      history('/dashboard')
    }
  }, [])

  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState('');
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false, currentPassword: false });

  useEffect(() => {
    loader(true)
    setTimeout(() => {
      loader(false)
    }, 500);
    let r = localStorage.getItem('remember')
    if (r) {
      let data = JSON.parse(r)
      setUsername(data.email)
      setPassword(data.password)
      setRemember(true)
    }
  }, [])

  const hendleSubmit = (e) => {
    e.preventDefault()
    const data = {
      email: username,
      password
    };
    loader(true)
    ApiClient.post('api/admin/login', data).then(res => {
      loader(false)
      if (res.success == true) {
        if (remember) {
          localStorage.setItem('remember', JSON.stringify(data))
        } else {
          localStorage.removeItem('remember')
        }
        // toast.success(res.message)
        localStorage.setItem('token', res.data.access_token)
        dispatch(login_success(res.data))


        let permissions = res.data.role.permissions[0]
        let url = '/dashboard'
        if (!permissions.readDashboard) url = '/profile'
        history(url);
      }
    })
  };
  return (
    <>
      <div className='grid items-center grid-cols-12'>
        {/* <div className="col-span-3 md:col-span-3 lg:col-span-3">
          <div className="relative w-full h-screen">
            <img src="/assets/img/login.jpg" alt="bg-logon" width="auto" height="auto" className=" object-center object-cover absolute inset-0 w-full h-full z-10" />
            <div className="bg-black/30 flex flex-col justify-between h-full   py-6 px-3 xs:px-4 md:px-4  xl:px-12 xl:py-16 relative z-20">
              <h6 className="text-lg xl:text-2xl  font-medium text-white mt-auto">Multipurpose
                tool you need to succeed
                in business</h6>
            </div>
          </div>
        </div> */}
        <div className="col-span-12 md:col-span-12 lg:col-span-12">
          <div className="relative flex flex-col items-center justify-center h-screen py-8 overflow-auto bg-[#71b55c1a]">
          <img src="/assets/img/logo.png" className="w-[200px] mb-10 mx-auto" alt="logo" />
            <div className="shadow-box w-full max-w-md px-4 py-6 overflow-hidden bg-white rounded-lg">
              <form className="" onSubmit={hendleSubmit}>
             
                <h4 className="text-typo mb-6 text-2xl ps-3 font-bold relative  before:content-[''] before:block before:w-[4px]  before:h-[30px] before:bg-[#71B55C] before:absolute  before:left-0 before:top-0 ">Sign in</h4>
                <label className='text-[14px] tracking-[0.76px] font-[500]'>Email</label>
                <input type="email" onChange={e => setUsername(e.target.value)} className=" border-1 border-gray-300 relative bg-[#f8f8f8] mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-[#71B55C]" placeholder="Email address" required value={username} />
                <div className="relative mb-6">
                <label className='text-[14px] tracking-[0.76px] font-[500]'>Password</label>
                  <input type={eyes.password ? 'text' : 'password'} className=" border-1 border-gray-300 relative bg-[#f8f8f8] w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-[#71B55C]" placeholder="Password" onChange={e => setPassword(e.target.value)} required value={password} />

                  <div className='absolute right-2 top-[50px] flex items-center text-gray-500 text-sm'>
                    <i className={eyes.password ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, password: !eyes.password })}></i>

                  </div>

                </div>
                <label className='flex items-center pointer'><input type="checkbox" checked={remember} onChange={(e) => setRemember(e.target.checked)} className="mr-2 h-4 w-4" /> <span className='text-md text-gray-600'>Remember Me</span></label>

                <div className="mt-8">
                  <button type="submit" className="px-4 w-full text-sm font-[17px] font-[600] text-white h-12 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2  disabled:cursor-not-allowed">Sign in</button>
                </div>


              </form>
            </div>

          </div>
        </div>
      </div>




      {/* <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-4 px-0">
            <div className='banner_img'>
              <div className='logo_img'>
                <Link to="/"> <img src="/assets/img/logonew2.svg" className='logo' /></Link>
              </div>
              <div className='loginLeftImg'>
                <h3>Multipurpose <br /> tool you need to succeed <br /> in business</h3>
              </div>

            </div>
          </div>
          <div className="col-md-8 p-0">
            <div className='right_side'>

              <form
                className="centerLogin"
                onSubmit={hendleSubmit}
              >
                <div className="text-center mb-4">
                  <h3 className="text-left lgtext">Sign in</h3>
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control mb-0 bginput" placeholder='Email address'
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <div className="inputWrapper">
                    <input
                      type={eyes.password ? 'text' : 'password'}
                      className="form-control mb-0 bginput"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      placeholder="Password"
                      required
                    />
                    <i className={eyes.password ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, password: !eyes.password })}></i>
                  </div>
                </div>

                <div className="mt-0">
                  <label><input type="checkbox" checked={remember} onChange={(e) => setRemember(e.target.checked)} className="mr-2" /> Remember Me</label>
                  <button type="submit" className="btn btn-primary loginclass mb-4 mt-3">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}




      <ToastContainer position="top-right" />
    </>
  );
};

export default Login;