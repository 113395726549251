import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import datepipeModel from "../../../models/datepipemodel";
import { useSelector } from "react-redux";
import "./style.scss";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const DateRangePicker = ({
  value,
  onChange,
  dynamicStyle = false,
  className = null,
  disabled = false,
  title = "",
  placeholder = "",
  isCompare = false,
  showcustom = true,
}) => {
  const user = useSelector((state) => state.user);
  const [toggle, setToggle] = useState(false);
  const [range, setRange] = useState("");

  const rangeList = [
    { id: "This Month", name: "This Month" },
    { id: "This Year", name: "This Year" },
    { id: "Last Month", name: "Last Month" },
    { id: "Last Year", name: "Last Year" },
    { id: "", name: "Custom" },
  ];

  const blockDateChange = (e) => {
    onChange({ ...value, ...e, compare: "" });
    if (
      datepipeModel.datetostring(e.startDate) !=
      datepipeModel.datetostring(e.endDate)
    ) {
      setToggle(false);
    }
  };

  const toggleChange = () => {
    setToggle(!toggle);
  };

  const getBlockValue = () => {
    let v = {
      startDate: value["startDate"] ? new Date(value["startDate"]) : new Date(),
      endDate: value["endDate"] ? new Date(value["endDate"]) : new Date(),
      key: "selection",
    };
    return [v];
  };

  const dropdownMenuClick = (e) => {
    // let el = document.getElementById(`daterangeDropdown-${id}`)
    // el.click()
  };

  const rangeClick = (e) => {
    let startDate = "";
    let endDate = "";

    if (e == "Last Month") {
      let current = new Date();
      let monthdate = current.setDate(0);
      monthdate = datepipeModel.datetostring(monthdate);
      startDate = `${monthdate.split("-")[0]}-${monthdate.split("-")[1]}-01`;
      endDate = monthdate;
    } else if (e == "This Month") {
      let current = datepipeModel.datetostring(new Date());
      startDate = `${current.split("-")[0]}-${current.split("-")[1]}-01`;

      let month2 = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      );
      endDate = datepipeModel.datetostring(month2);
    } else if (e == "This Year") {
      let current = datepipeModel.datetostring(new Date());
      startDate = `${current.split("-")[0]}-01-01`;

      let month2 = new Date(new Date().getFullYear() + 1, 0, 0);
      endDate = datepipeModel.datetostring(month2);
    } else if (e == "Last Year") {
      let current = new Date();
      startDate = `${current.getFullYear() - 1}-01-01`;
      let month2 = new Date(current.getFullYear(), 0, 0);
      endDate = datepipeModel.datetostring(month2);
    }
    setRange(e);
    onChange({ ...value, startDate, endDate, compare: "" });
    if (e != "") {
      setToggle(false);
    }
  };

  const getDays = (s, e) => {
    // Define two date objects
    const startDate = new Date(s); // Replace with your start date
    const endDate = new Date(e); // Replace with your end date
    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;
    // Convert milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const previousYear = () => {
    let start = "";
    let end = "";
    if (value.startDate && value.endDate) {
      let ssplit = value.startDate.split("-");
      let esplit = value.endDate.split("-");
      let year = Number(ssplit[0]);
      let eyear = Number(esplit[0]);

      let d = new Date(`${year - 1}-${ssplit[1]}-${ssplit[2]}`);
      start = datepipeModel.datetostring(d);

      let ed = new Date(`${eyear - 1}-${esplit[1]}-${esplit[2]}`);
      end = datepipeModel.datetostring(ed);
    }
    return { start, end };
  };

  const previousMonth = () => {
    let start = "";
    let end = "";
    if (value.startDate && value.endDate) {
      // let sMonth=new Date(value.startDate).getMonth()
      // start=new Date(value.startDate).setMonth(sMonth-1)
      // start=datepipeModel.datetostring(start)

      // let eMonth=new Date(value.endDate).getMonth()
      // end=new Date(value.endDate).setMonth(eMonth-1)
      // end=datepipeModel.datetostring(end)

      let current = new Date(value.startDate);
      let monthdate = current.setDate(0);
      monthdate = datepipeModel.datetostring(monthdate);
      start = `${monthdate.split("-")[0]}-${monthdate.split("-")[1]}-01`;
      end = monthdate;
    }
    return { start, end };
  };

  const previousPeriod = () => {
    let start = "";
    let end = "";
    if (value.startDate && value.endDate) {
      let days = getDays(value.startDate, value.endDate) + 1;

      let d = new Date(value.startDate);
      d.setDate(d.getDate() - days);
      start = datepipeModel.datetostring(d);

      let ed = new Date(value.startDate);
      ed.setDate(ed.getDate() - 1);
      end = datepipeModel.datetostring(ed);
    }
    return { start, end };
  };

  const compareChange = (e) => {
    let start = "";
    let end = "";
    if (e == "Previous Period") {
      start = previousPeriod().start;
      end = previousPeriod().end;
    } else if (e == "Previous Year") {
      start = previousYear().start;
      end = previousYear().end;
    } else if (e == "Previous Month") {
      start = previousMonth().start;
      end = previousMonth().end;
    }

    let v = {
      ...value,
      compareStart: start,
      compareEnd: end,
      compare: e,
    };
    setToggle(false);
    onChange({ ...v });
  };

  return (
    <>
      <div className="flex">
        <Menu as="div" className="relative  ">
          <div>
            <Menu.Button className="bg-[#E8E8E8] inline-flex w-full  -tracking-[0.084px] rounded-[8px] rounded-tr-[0px] rounded-br-[0px] px-[16px] py-[10px] text-[#2B2B2B] text-[14px] font-normal ">
              <div className="flex items-center">
                <div className="flex items-center">
                  <div className="ml-2 text-left">Last 7 days</div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="ms-[6px]"
                >
                  <path
                    d="M14 8L10 12L6 8"
                    stroke="#525866"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </Menu.Button>
          </div>

          <Transition
           as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right  bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none p-[8px] rounded-[12px]">
              <div className="">
                <Menu.Item className="p-[8px] active-menu">
                  <p className="text-[#0D0D12] text-[12px] font-normal tracking-[0.24px] cursor-pointer">Past 7 Days</p>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <path d="M2.5 6L5 8.5L10 3.5" stroke="#0D0D12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> */}
                </Menu.Item>
                <Menu.Item className="p-[8px] hover:bg-[#F8F9FB] rounded-[6px] hover:font-medium">
                  <p className="text-[#0D0D12] text-[12px] font-normal tracking-[0.24px] cursor-pointer">Past 14 Days</p>
                </Menu.Item>
                <Menu.Item className="p-[8px] hover:bg-[#F8F9FB] rounded-[6px] hover:font-medium">
                  <p className="text-[#0D0D12] text-[12px] font-normal tracking-[0.24px] cursor-pointer">Past 30 Days</p>
                </Menu.Item>
                <Menu.Item className="p-[8px] hover:bg-[#F8F9FB] rounded-[6px] hover:font-medium">
                  <p className="text-[#0D0D12] text-[12px] font-normal tracking-[0.24px] cursor-pointer">Past 90 Days</p>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <Menu
          as="div"
          className="relative list_box_active_state "
          title={title}
        >
          <div className="flex">
            <Menu.Button
              title={title}
              disabled={disabled}
              onClick={toggleChange}
              className="inline-flex  border border-[#E2E4E9] text-[#2B2B2B] text-[14px] font-normal bg-white justify-center border gap-x-1.5 rounded-[8px] 
            py-[9px] px-[8px] rounded-tl-[0px] rounded-bl-[0px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M3.74023 8.05469H17.1082"
                  stroke="#2B2B2B"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.4521 2.5V4.96812"
                  stroke="#525866"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.39355 2.5V4.96812"
                  stroke="#525866"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.5983 3.68359H7.24804C5.04506 3.68359 3.66992 4.91035 3.66992 7.16523V13.9534C3.66992 16.2439 5.04506 17.499 7.24804 17.499H13.5918C15.8013 17.499 17.17 16.2658 17.17 14.0101V7.16523C17.1764 4.91035 15.8078 3.68359 13.5983 3.68359Z"
                  stroke="#2B2B2B"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {!value?.startDate || !value?.endDate ? (
                <>{placeholder || "Start Date - End Date"}</>
              ) : (
                <>
                  {datepipeModel.date(
                    value?.startDate,
                    user?.companyDateFormat
                  )}{" "}
                  -{" "}
                  {datepipeModel.date(value?.endDate, user?.companyDateFormat)}
                </>
              )}
              {/* <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-[#2B2B2B] text-[14px] font-normal"
                aria-hidden="true"
              /> */}
            </Menu.Button>
            <div className="ms-[13px]">
              <button className="bg-[#2B2B2B] text-white rounded-[10px] font-medium text-[14px] flex items-center py-[10px] px-[16px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  className="me-[8px]"
                >
                  <path
                    d="M11.2539 14.625L11.8676 13.9822C14.601 11.1185 12.5713 6.375 8.61242 6.375H4.87891M4.87891 6.375L7.87892 9.375M4.87891 6.375L7.87892 3.375"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Reset
              </button>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`${
                dynamicStyle ? "" : ""
              }  focus:!outline-[#fff] focus:!outline text-sm absolute  shadow-lg z-40 rounded-[16px] ${
                className ? className : " min-w-[360px]"
              }  right-0 shadow-lg !py-2 !mt-3 overflow-auto bg-white  rounded-[16px] scrollbar`}
            >
              <div className="fffff">
                <div className="p-[16px]">
                  {/* <p className="text-center px-2">{!value?.startDate || !value?.endDate ? <>{placeholder || 'Start Date - End Date'}</> : <>{datepipeModel.date(value?.startDate, user?.companyDateFormat)} -  {datepipeModel.date(value?.endDate, user?.companyDateFormat)}</>}</p> */}
                  {/* <div className=" flex-wrap row-gap-2 column-gap-2 whitespace-nowrap flex p-2 mb-0">
                    {rangeList.map((itm) => {
                      return showcustom == false &&
                        itm.name == "Custom" ? null : (
                        <button
                          className={`btn ${
                            range == itm.id
                              ? "bg-primary text-white"
                              : "bg-transparent hover:border-gray-400 border-1 border-gray-400"
                          }`}
                          type="button"
                          onClick={(e) => rangeClick(itm.id)}
                        >
                          {itm.name}
                        </button>
                      );
                    })}
                  </div> */}
                  <h4 className="text-[18px] font-medium text-[#0A0D14] -tracking-[0.198px] pb-[16px] border-b border-[#F1F2F4]">Set Date</h4>

                  {range || showcustom == false ? (
                    <></>
                  ) : (
                    <>
                      <div className="w-full customcalender">
                        <DateRange
                          editableDateInputs={true}
                          onChange={(e) => blockDateChange(e.selection)}
                          moveRangeOnFirstSelection={false}
                          ranges={getBlockValue()}
                        />
                      </div>

                      <div className="calendar-btns flex items-center justify-end">
                      <button className="border border-solid border-[#0A0D14] text-[#111827] rounded-[10px] text-[14px] font-[700] text-center  px-[24px] py-[10px] w-[126px]">Cancel</button>
                          <button className="bg-[#0A0D14] rounded-[10px] text-[14px] font-[700] text-center text-white px-[24px] py-[10px] w-[126px] ms-[20px]">Save</button>
                      </div>
                    </>
                  )}

                  {isCompare ? (
                    <>
                      <Menu as="div" className="relative ">
                        <div className="text-center">
                          <Menu.Button className="inline-flex w-full justify-center border gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            Compare
                            <ChevronDownIcon
                              className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    className={`dropdown-item ${
                                      value.compare == "Previous Month"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={(e) =>
                                      compareChange("Previous Month")
                                    }
                                  >
                                    Previous Month (
                                    {datepipeModel.date(
                                      previousMonth().start,
                                      user.companyDateFormat
                                    )}{" "}
                                    -{" "}
                                    {datepipeModel.date(
                                      previousMonth().end,
                                      user.companyDateFormat
                                    )}
                                    )
                                  </a>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    className={`dropdown-item ${
                                      value.compare == "Previous Year"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={(e) =>
                                      compareChange("Previous Year")
                                    }
                                  >
                                    Previous Year(Same Date) (
                                    {datepipeModel.date(
                                      previousYear().start,
                                      user.companyDateFormat
                                    )}{" "}
                                    -{" "}
                                    {datepipeModel.date(
                                      previousYear().end,
                                      user.companyDateFormat
                                    )}
                                    )
                                  </a>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    className={`dropdown-item ${
                                      value.compare == "Previous Period"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={(e) =>
                                      compareChange("Previous Period")
                                    }
                                  >
                                    Previous Period(Custom Dates) (
                                    {datepipeModel.date(
                                      previousPeriod().start,
                                      user.companyDateFormat
                                    )}{" "}
                                    -{" "}
                                    {datepipeModel.date(
                                      previousPeriod().end,
                                      user.companyDateFormat
                                    )}
                                    )
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};
export default DateRangePicker;
