const shared={
    check:'Fuel',
    title:'Fuels',
    addTitle:'Fuel',
    url:'fuel',
    type:'fuel',
    addApi:'api/emission-factor',
    editApi:'api/emission-factor',
    detailApi:'api/emission-factor',
    listApi:'api/emission-factors',
    statusApi:'api/emission-factor',
    deleteApi:'api/emission-factor'
}

export default shared