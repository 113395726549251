import React from "react";
import environment from "../../../environment";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import styles from "./index.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import { RiHome6Line } from "react-icons/ri";
import { FiPackage, FiSettings, FiThumbsUp, FiUsers } from "react-icons/fi";
import { TiArrowSortedDown } from "react-icons/ti";
import { PiHandCoins, PiHandbagBold } from "react-icons/pi";
import { MdOutlineHolidayVillage, MdOutlineAttachEmail, MdOutlineCoPresent } from "react-icons/md";
import { IoFileTrayFullOutline, IoChatboxEllipsesOutline } from "react-icons/io5"
import { RiCoupon3Line, RiPresentationLine , RiExchangeDollarLine } from "react-icons/ri";
import { GrCatalogOption } from "react-icons/gr";
import shared from "../../../pages/Categories/shared";
import { AiOutlineUser } from "react-icons/ai";
import { TbChartBar } from "react-icons/tb";


const Html = ({ ListItemLink, urlAllow, route, isOpen }) => {
  const location = useLocation();


  const menus = [
    {name:'MAIN MENU'},
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: <RiHome6Line className="shrink-0 text-lg" />,
      check: 'readDashboard'
    },
    {
      name: 'User Management',
      icon: <AiOutlineUser className="shrink-0 text-lg"/>
      ,
      check: 'readDashboard',
      menus: [
        {
          path: '/roles',
          name: 'Roles',
          check: '',
        },
        {
          path: '/users',
          name: 'Users',
          check: '',
        },
      ]
    },
    {
      name: 'GHG Emission Factors',
      icon: <RiHome6Line className="shrink-0 text-lg" />,
      check: 'readDashboard',
      menus: [
        {
            path: '/emission/1',
            name: 'Scope 1',
            check: '',
          },
          {
            path: '/emission/2',
            name: 'Scope 2',
            check: '',
          },
          {
            path: '/emission/3',
            name: 'Scope 3',
            check: '',
          },
        // {
        //   path: '/company-facility',
        //   name: 'Company Facilities',
        //   check: '',
        // },
        // {
        //   path: '/fuel',
        //   name: 'Fuels',
        //   check: '',
        // },
        // {
        //   path: '/vehicle',
        //   name: 'Vehicles',
        //   check: '',
        // },
        // {
        //   path: '/waste',
        //   name: 'Wastes',
        //   check: '',
        // },
        // {
        //   path: '/procurement',
        //   name: 'Procurements',
        //   check: '',
        // },
        // {
        //   path: '/accomodation',
        //   name: 'Accommodations',
        //   check: '',
        // },
        // {
        //   path: '/flight',
        //   name: 'Flights',
        //   check: '',
        // },
        // {
        //   path: '/employee-commuting',
        //   name: 'Employee Commuting',
        //   check: '',
        // },
      ]
    },
    {
      name: 'Catalogue',
      icon: <GrCatalogOption className="shrink-0 text-lg" />,
      check: 'readDashboard',
      menus: [
        {
          path: '/types',
          name: 'Types',
          check: '',
        },
        {
          path: '/categories',
          name: 'Tour Categories',
          check: '',
        },
        ...shared.types.map(itm=>({
          path: `/category/${itm.id}`,
          name: `${itm.name} Categories`,
          check: '',
        }))
      ]
    },
    {
      name: 'Subscription Plan',
      icon:   <i className="material-icons shrink-0 text-lg " >subscriptions</i>,
      check: 'readDashboard',
      menus: [
        {
          path: '/features',
          name: 'Plan Features',
          check: '',
        },
        {
          path: '/plans',
          name: 'Plans',
          check: '',
        },
      ]
    },
    {
      name: 'Entry Data',
      icon: <RiCoupon3Line className="shrink-0 text-lg" />,
      check: 'readDashboard',
      path:'/entry-data',
    },
    {
      name: 'KPIs',
      icon: <RiCoupon3Line className="shrink-0 text-lg" />,
      check: 'readDashboard',
      path:'/kpi',
    },
    {
      name: 'Coupons',
      icon: <RiCoupon3Line className="shrink-0 text-lg" />,
      check: 'readDashboard',
      path:'/coupon',
    },
    {
      name: 'Department',
      icon: <RiCoupon3Line className="shrink-0 text-lg" />,
      check: 'readDashboard',
      path:'/department',
    },
    {
      name: 'Leads',
      icon: <RiCoupon3Line className="shrink-0 text-lg" />,
      check: 'readDashboard',
      path:'/leads',
    },
    {
      name: 'Currency',
      
      icon: <RiExchangeDollarLine lassName="shrink-0 text-lg"/>,
      check: 'readDashboard',
      path:'/currency',
    },
    {name:'OTHERS'},
    {
      name: 'Data Connections',
      icon:  <i className="material-icons shrink-0 text-lg" title="">book</i>,
      check: 'readDashboard',
      menus: [
        {
          path: '/bookingSystem',
          name: 'Booking System',
          check: '',
        },
        {
          path: '/reviews',
          name: 'Reviews',
          check: '',
        },
        {
          path: '/accountingSystem',
          name: 'Accounting System',
          check: '',
        },
      ]
    },
    {
      name: 'Geo',
      icon:  <i className="material-icons shrink-0 text-lg" title="">map</i>,
      check: 'readDashboard',
      menus: [
        {
          path: '/continents',
          name: 'Continents',
          check: '',
        },
        {
          path: '/countries',
          name: 'Countries',
          check: '',
        },
        {
          path: '/regions',
          name: 'Regions',
          check: '',
        },
        {
          path: '/cities',
          name: 'Cities',
          check: '',
        },
      ]
    },
    // {
    //   name: 'Dynamic Pricing',
    //   icon: <PiHandCoins className="shrink-0 text-lg" />,
    //   path:'/dynamicpricelist',
    //   check: 'readDashboard',
    // },
    // {
    //   name: 'Contract Templates',
    //   icon: <IoFileTrayFullOutline className="shrink-0 text-lg" />,
    //   path:'/crm',
    //   check: 'readDashboard',
    // },
    // {
    //   name: 'Waiver Templates',
    //   icon: <IoFileTrayFullOutline className="shrink-0 text-lg" />,
    //   path:'/waiver',
    //   check: 'readDashboard',
    // },
    {
      name: 'Ecometrixs Customers',
      icon: <RiPresentationLine className="shrink-0 text-lg" />,
      path:'/customer',
      check: 'readDashboard',
    },
    // {
    //   name: 'Holidays',
    //   icon: <MdOutlineHolidayVillage className="shrink-0 text-lg" />,
    //   path:'/holidays',
    //   check: 'readDashboard',
    // },
    {
      name: 'Email Template',
      icon: <MdOutlineAttachEmail className="shrink-0 text-lg" />,
      path:'/emailtemplate',
      check: 'readDashboard',
    },
    {
      name: 'Resellers / Agents',
      icon: <MdOutlineCoPresent className="shrink-0 text-lg" />,
      path:'/reseller',
      check: 'readDashboard',
    },
    {
      name: 'Refund Reason',
      icon: <IoFileTrayFullOutline className="shrink-0 text-lg" />,
      path:'/refund-reason',
      check: 'readDashboard',
    },
    // {
    //   name: 'Settings',
    //   icon:  <i className="material-icons shrink-0 text-lg" title="">settings</i>,
    //   check: 'readDashboard',
    //   menus: [
    //     {
    //       path: '/department',
    //       name: 'Departments',
    //       check: '',
    //     },
    //   ]
    // },
  ]
  

  const tabclass = (tab) => {
    let url = window.location.href;
    let value = false;
    let menu=tab.split(',')
    menu.map((itm) => {
      if (url.includes(itm)) value = true;
    });
    return value;
  };

  const MenuItem=({itm})=>{
    if(!itm.path&&!itm.menus){
      return <>
      <li>
      <h6
              className={`${isOpen ? "py-[12px] text-center" : "p-[12px]"
                } text-xs font-medium text-[#868C98] mt-[12px] tracking-[0.48px]`}>
              <span className="sidebar_text"> {itm.name} </span>
            </h6>
      </li>
      </>
    }
    else if (itm.menus) {
      return <>

        {urlAllow(itm.check) && (
          <li>
            <Disclosure as="div" defaultOpen={tabclass(itm.menus.map(itm=>itm.path).toString())}>
              {({ open }) => (
                <>
                  <Disclosure.Button className={`w-full py-[12px] px-[7px] rounded-[8px] flex items-center justify-between gap-[12px] hover:bg-[#71B55C]/10 transition-all duration-300 ${open?'!text-[#fff] !bg-[#71B55C] !font-[600]':'text-[#525866]'}`}>
                    <span className="text-sm font-normal text-inherit flex items-center gap-[12px] crm">
                      {itm.icon}
                      <span className="sidebar_text text-[14px] font-medium -tracking-[0.084px]">{itm.name}</span>
                    </span>
                    <TiArrowSortedDown
                      className={`${open ? "" : "-rotate-90 transform"
                        } h-4 w-4 transition-all duration-500`}
                    />
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-300 ease-in-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-300 opacity-300"
                    leave="transition duration-300 ease-in-out"
                    leaveFrom="transform scale-300 opacity-300"
                    leaveTo="transform scale-95 opacity-0">
                    <Disclosure.Panel
                      className={`${!isOpen && ""} mt-[4px] `}>
                      <ul className="space-y-2">
                        {itm.menus.map(mitm=>{
                          return <>
                          {urlAllow(mitm.check)?<>
                          <li className="relative ml-[20px] mt-0">
                          <NavLink
                            to={mitm.path}
                            className={(isActive) =>
                              "py-[12px] px-[16px]  before:content-[''] before:block before:w-[2px]  before:h-[44px] before:bg-[#EFEFEF] before:absolute  before:left-0 before:top-0 rounded-[8px] block text-sm font-normal text-[#6F767E] cursor-pointer hover:!text-[#71B55C] hover:bg-transparent !no-underline transition-all " +
                              (location?.pathname == mitm.path &&
                                " !text-[#71B55C] !bg-transparent !font-medium")
                            }>
                            {isOpen && (
                              <TbChartBar className="text-inherit shrink-0 text-lg" />
                            )}
                            <span className="text-inherit sidebar_text leading-none">
                              {mitm.name}
                            </span>
                          </NavLink>
                        </li>
                          </>:<></>}
                        
                          </>
                        })}
                      </ul>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </li>
        )}
      </>
    } else {
      return <>
        {urlAllow(itm.check) && (
          <li>
            <NavLink
              to={itm.path}
              className={(isActive) =>
                "py-[12px] px-[7px] rounded-[8px] flex items-center gap-[12px] text-[14px] font-normal text-[#525866] hover:!text-[#fff] hover:bg-[#71B55C] !no-underline transition-all leading-[20px] " +
                (location?.pathname == itm.path &&
                  " !text-[#fff] !bg-[#71B55C] !font-[600] ")
              }>
              {itm.icon}
              <span className="text-inherit sidebar_text leading-[18px]">
                {itm.name}
              </span>
            </NavLink>
          </li>
        )}
      </>
    }
  }
  
  return (
    <>

      <div className={`px-[8px] ${isOpen && styles.sm_sidebar}`} component="siderbar">

        <ul className="space-y-2 px-2" >
        {menus.map(itm => {
               return <MenuItem itm={itm} />
              })}

        </ul>
      </div>
    </>
  );
}

export default Html