import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/global/layout";
import "./style.scss";
import SelectDropdown from "../../components/common/SelectDropdown";
import ApiClient from "../../methods/api/apiClient";
import datepipeModel from "../../models/datepipemodel";
import ReactECharts from "echarts-for-react";
import DateRangePicker from "../../components/common/DateRangePicker";
import LineChart from "../../components/common/LineChart";
import pipeModel from "../../models/pipeModel";
import { Menu, Transition } from "@headlessui/react";
import methodModel from "../../methods/methods";
const Dashboard = () => {
  const user = useSelector((state) => state.user);
  const [filters, setFilter] = useState({
    startDate: "",
    endDate: "",
    compare: "",
  });
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [newSubsctiptions, setNewSubsctiptions] = useState([]);
  const [chartData, setChartData] = useState({
    netRevenue: [],
    recurringRevenue: [],
    customers: [],
  });

  const getData = (p = {}) => {
    let f = {
      ...filters,
      ...p,
      type: "monthly",
    };
    ApiClient.get("api/admin/dashboard", f).then((res) => {
      if (res.success) {
        const mapF = (itm) => {
          return {
            ...itm,
            date: `${datepipeModel.monthfind(itm.month - 1)}/${itm.year}`,
          };
        };

        const sortF = (a, b) => {
          if (a.month + a.year < b.month + b.year)
            //sort string ascending
            return -1;
          if (a.month + a.year > b.month + b.year) return 1;
          return 0; //default return value (no sorting)
        };

        let activeSubscriptions = res.activeSubscriptions.map(mapF).sort(sortF);
        let newSubsctiptions = res.newSubsctiptions.map(mapF).sort(sortF);
        res.netRevenue = res.netRevenue.map(mapF).sort(sortF);
        res.recurringRevenue = res.recurringRevenue.map(mapF).sort(sortF);
        res.activeCustomers = res.activeCustomers.map(mapF).sort(sortF);
        res.reActiveCustomers = res.reActiveCustomers.map(mapF).sort(sortF);
        res.newCustomer = res.newCustomer.map(mapF).sort(sortF);

        let barrAll = [
          ...res.activeCustomers,
          ...res.reActiveCustomers,
          ...res.newCustomer,
        ].sort(sortF);

        barrAll = [...new Set([...barrAll.map((itm) => itm.date)])];

        barrAll = barrAll.map((itm) => {
          return {
            date: itm,
            newUsers:
              res.newCustomer.find((sitm) => sitm.date == itm)?.newUsers || 0,
            activecustomers:
              res.activeCustomers.find((sitm) => sitm.date == itm)
                ?.activecustomers || 0,
            reActiveCustomers:
              res.reActiveCustomers.find((sitm) => sitm.date == itm)
                ?.activecustomers || 0,
          };
        });

        console.log("barrAll", barrAll);

        res.customers = barrAll;
        setActiveSubscriptions(activeSubscriptions);
        setNewSubsctiptions(newSubsctiptions);
        setChartData(res);
      }
    });
  };

  const filter = (p = {}) => {
    setFilter({ ...filters, ...p });
    getData(p);
  };

  const blockDateChange = (e) => {
    console.log("e", e);
    filter({
      endDate: datepipeModel.datetostring(e.endDate),
      startDate: datepipeModel.datetostring(e.startDate),
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <div className="main_dashboard">
        <div className="flex items-start justify-between mb-4 md:mb-8 lg:mb-12 lg:flex-col lg:flex-col xl:flex-row lg:items-start xl:items-center md:items-start">
          <div className="border border-[#E2E4E9] rounded-[8px] py-[12px] px-[16px] w-[60%]">
            <ul className="flex items-center flex-wrap justify-between">
              <li className="rounded-[8px] py-[4px] px-[16px] text-[#2b2b2bcc] text-[12px] font-medium border border-[#E2E4E9]  xl:w-[24%] lg:w-[49%]">
                AII Branches
              </li>
              <li className="rounded-[8px] py-[4px] px-[16px] text-[#2b2b2bcc] text-[12px] font-medium border border-[#E2E4E9]  xl:w-[24%] lg:w-[49%]">
                Dazhboards Pty. Ltd
              </li>
              <li className="rounded-[8px] py-[4px] px-[16px] text-[#2b2b2bcc] text-[12px] font-medium border border-[#E2E4E9] lg:mt-2  xl:mt-0 xl:w-[24%] lg:w-[49%]">
                Greymouth, New Zealand
              </li>
              <li className="rounded-[8px] py-[4px] px-[16px] text-[#2b2b2bcc] text-[12px] font-medium border border-[#E2E4E9] lg:mt-2 xl:mt-0 xl:w-[24%] lg:w-[49%]">
                Daztlboarcs Greymouth
              </li>
            </ul>
          </div>
          <div className="flex items-center lg:mt-3 xl:mt-0 md:mt-3">
            <p className="flex items-center bg-[#71b55c1a] py-[8px] px-[16px] rounded-[8px]  text-[#2B2B2B] text-[14px] font-normal ring-offset-2 ring-opacity-50 border border-[#E2E4E9]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="me-[12px]"
              >
                <path
                  d="M12 13V2L20 6L12 10"
                  stroke="#2B2B2B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20.5499 10.2295C21.0821 11.8696 21.1319 13.6281 20.6934 15.2957C20.2549 16.9633 19.3466 18.4699 18.0765 19.6361C16.8064 20.8024 15.2281 21.5792 13.5292 21.8743C11.8303 22.1693 10.0824 21.9701 8.4935 21.3004C6.90457 20.6306 5.54149 19.5185 4.56643 18.0964C3.59136 16.6742 3.04534 15.0019 2.99335 13.2784C2.94136 11.5549 3.38557 9.85267 4.27314 8.37434C5.16071 6.89601 6.45428 5.70377 7.99994 4.93945"
                  stroke="#2B2B2B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.00043 10C7.49934 10.667 7.17311 11.4488 7.05148 12.2742C6.92984 13.0996 7.01666 13.9422 7.30403 14.7254C7.5914 15.5087 8.07019 16.2075 8.69676 16.7584C9.32333 17.3092 10.0778 17.6945 10.8914 17.8792C11.7049 18.0638 12.5518 18.042 13.3548 17.8156C14.1578 17.5892 14.8913 17.1655 15.4886 16.5831C16.086 16.0007 16.5281 15.278 16.7747 14.481C17.0212 13.684 17.0645 12.838 16.9004 12.02"
                  stroke="#2B2B2B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Set Goals
            </p>
            <p className="flex items-center bg-[#71B55C] py-[8px] px-[16px] rounded-[8px]  text-white text-[14px] font-normal ring-offset-2 ring-opacity-50 border border-[#E2E4E9] ms-[20px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="me-[12px]"
              >
                <path
                  d="M12.5 3.11719C14.5405 3.11719 16.5204 3.81058 18.1151 5.08365C19.7098 6.35672 20.8245 8.13389 21.2764 10.1237C21.7284 12.1135 21.4908 14.1978 20.6026 16.0349C19.7143 17.8719 18.2282 19.3526 16.3879 20.234C14.5477 21.1155 12.4625 21.3455 10.4743 20.8862C8.48617 20.427 6.7131 19.3057 5.4459 17.7064C4.17869 16.1071 3.49257 14.1246 3.50006 12.0841C3.50755 10.0436 4.2082 8.0663 5.48712 6.47632"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M12.5 6.61719C11.4361 6.61719 10.395 6.92576 9.50295 7.5055C8.61088 8.08524 7.9061 8.91127 7.47401 9.88348C7.04193 10.8557 6.9011 11.9323 7.06858 12.983C7.23606 14.0336 7.70466 15.0131 8.4176 15.8028C9.13055 16.5925 10.0572 17.1585 11.0853 17.4321C12.1134 17.7058 13.1989 17.6753 14.21 17.3446C15.2212 17.0138 16.1147 16.3968 16.7824 15.5685C17.45 14.7401 17.863 13.7359 17.9714 12.6775"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M12.4998 14.1173C12.8866 14.1173 13.2652 14.005 13.5896 13.7942C13.914 13.5834 14.1703 13.283 14.3274 12.9295C14.4845 12.576 14.5357 12.1845 14.4748 11.8024C14.4139 11.4204 14.2435 11.0642 13.9843 10.777C13.725 10.4899 13.388 10.2841 13.0142 10.1845C12.6403 10.085 12.2456 10.0961 11.8779 10.2164"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
              View insight
            </p>
          </div>
        </div>

        <div className="flex items-center justify-between mb-4 md:mb-8 lg:mb-12">
          <div className="border border-[#E2E4E9] rounded-[8px] py-[9px] px-[8px] flex items-center w-[60%]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className="me-[8px]"
            >
              <path
                d="M9.25 2.5C12.976 2.5 16 5.524 16 9.25C16 12.976 12.976 16 9.25 16C5.524 16 2.5 12.976 2.5 9.25C2.5 5.524 5.524 2.5 9.25 2.5ZM9.25 14.5C12.1502 14.5 14.5 12.1502 14.5 9.25C14.5 6.349 12.1502 4 9.25 4C6.349 4 4 6.349 4 9.25C4 12.1502 6.349 14.5 9.25 14.5ZM15.6137 14.5532L17.7355 16.6742L16.6742 17.7355L14.5532 15.6137L15.6137 14.5532V14.5532Z"
                fill="#B3B3B3"
              />
            </svg>
            <input
              type="search"
              placeholder="Search..."
              className="text-[14px] font-normal text-[#222] -traclking-[0.084px] w-full"
            />
          </div>

          <div>
            <DateRangePicker
              value={
                filters.startDate
                  ? {
                      startDate: new Date(filters.startDate),
                      endDate: new Date(filters.endDate),
                    }
                  : { startDate: "", endDate: "" }
              }
              id="daterange"
              onChange={(e) => blockDateChange(e)}
            />
          </div>
        </div>

        <div className="row_charts">
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-4 mr-2 mb-3">
              <div className="layout_new shadow-[0px 1px 2px 0px rgba(228, 229, 231, 0.24)]  border border-[#E2E4E9] rounded-[12px]">
                <div className="">
                  <div className="flex items-center justify-between">
                    <h2 className="text-[#0A0D14] text-[16px] font-medium leading-[24px] -tracking-[0.176px] flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        className="me-[8px]"
                      >
                        <path
                          d="M12 21C7.0293 21 3 16.9707 3 12C3 7.9698 5.6487 4.5588 9.3 3.4122V5.3238C7.75283 5.95204 6.47202 7.09834 5.67665 8.5666C4.88129 10.0349 4.62079 11.7339 4.93973 13.373C5.25866 15.0121 6.13721 16.4895 7.42509 17.5524C8.71297 18.6153 10.3301 19.1977 12 19.2C13.4344 19.2 14.8361 18.7716 16.0256 17.9699C17.215 17.1682 18.138 16.0296 18.6762 14.7H20.5878C19.4412 18.3513 16.0302 21 12 21V21ZM20.955 12.9H11.1V3.045C11.3961 3.0153 11.6967 3 12 3C16.9707 3 21 7.0293 21 12C21 12.3033 20.9847 12.6039 20.955 12.9ZM12.9 4.8558V11.1H19.1442C18.9439 9.51385 18.2216 8.0394 17.0911 6.90891C15.9606 5.77842 14.4862 5.05613 12.9 4.8558Z"
                          fill="#525866"
                        />
                      </svg>
                      Sustainability Score
                    </h2>
                    <Menu as="div" className="relative  ">
                      <div>
                        <Menu.Button className="bg-[#fff] inline-flex w-full  -tracking-[0.084px] rounded-[8px]  px-[16px] py-[10px] text-[#2B2B2B] text-[14px] font-normal border border-[E8E8E8] ">
                          <div className="flex items-center">
                            <div className="flex items-center">
                              <div className="ml-2 text-left">Last Week</div>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              className="ms-[6px]"
                            >
                              <path
                                d="M14 8L10 12L6 8"
                                stroke="#525866"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </Menu.Button>
                      </div>

                      <Transition
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                              <p className="border-t"></p>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <h4 className=" text-2xl font-extrabold	mb-2 text-gray-700">
                    {pipeModel.currency(chartData?.totalrecurringRevenue)}
                  </h4>

                  <div className="right_layout">
                    {/* <h3><span class="material-icons green_icon">north_east</span>2.9%</h3>
                    <p className='text_light'>In Selected Period</p> */}
                  </div>
                </div>

                <LineChart
                  legends={[
                    {
                      label: "Monthly Recurring Revenue",
                      key: "totalactiveUserSubscriptions",
                    },
                  ]}
                  data={chartData.recurringRevenue}
                  hideLegends={true}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-8 ml-2 mb-3">
              <div className="layout_new">
                <div className="">
                  <div className="flex items-center justify-between">
                    <h2 className="text-[#0A0D14] text-[16px] font-medium leading-[24px] -tracking-[0.176px] flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        className="me-[8px]"
                      >
                        <path
                          d="M11.1004 7.5H12.9004V16.5H11.1004V7.5ZM14.7004 11.1H16.5004V16.5H14.7004V11.1ZM7.50039 12.9H9.30039V16.5H7.50039V12.9ZM14.7004 4.8H5.70039V19.2H18.3004V8.4H14.7004V4.8ZM3.90039 3.8928C3.90039 3.3996 4.30269 3 4.79949 3H15.6004L20.1004 7.5V20.0937C20.1012 20.2119 20.0788 20.3291 20.0343 20.4386C19.9898 20.5481 19.9242 20.6478 19.8412 20.7319C19.7583 20.8161 19.6595 20.8831 19.5506 20.9291C19.4418 20.9751 19.3249 20.9992 19.2067 21H4.79409C4.55773 20.9984 4.33151 20.9038 4.16429 20.7367C3.99707 20.5697 3.90228 20.3436 3.90039 20.1072V3.8928Z"
                          fill="#2B2B2B"
                        />
                      </svg>
                      Performance Over Time
                    </h2>
                    <Menu as="div" className="relative  ">
                      <div>
                        <Menu.Button className="bg-[#fff] inline-flex w-full  -tracking-[0.084px] rounded-[8px]  px-[16px] py-[10px] text-[#2B2B2B] text-[14px] font-normal border border-[E8E8E8] ">
                          <div className="flex items-center">
                            <div className="flex items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none "
                                className="me-[8px]"
                              >
                                <path
                                  d="M3.74023 8.05469H17.1082"
                                  stroke="#2B2B2B"
                                  stroke-width="1.2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.4521 2.5V4.96812"
                                  stroke="#2B2B2B"
                                  stroke-width="1.2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.39355 2.5V4.96812"
                                  stroke="#2B2B2B"
                                  stroke-width="1.2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M13.5983 3.68359H7.24804C5.04506 3.68359 3.66992 4.91035 3.66992 7.16523V13.9534C3.66992 16.2439 5.04506 17.499 7.24804 17.499H13.5918C15.8013 17.499 17.17 16.2658 17.17 14.0101V7.16523C17.1764 4.91035 15.8078 3.68359 13.5983 3.68359Z"
                                  stroke="#2B2B2B"
                                  stroke-width="1.2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <div className="ml-2 text-left">
                                1 Jun 2023 - 11 Feb 2024
                              </div>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              className="ms-[6px]"
                            >
                              <path
                                d="M14 8L10 12L6 8"
                                stroke="#525866"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </Menu.Button>
                      </div>

                      <Transition
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                              <p className="border-t"></p>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <h4 className=" text-2xl font-extrabold	mb-2 text-gray-700">
                    {" "}
                    {pipeModel.currency(chartData?.totalnetRevenue)}
                  </h4>

                  <div className="right_layout">
                    {/* <h3><span class="material-icons red_icon">south_east</span>19.9%</h3>
                    <p className='text_light'>In Selected Period</p> */}
                  </div>
                </div>
                <LineChart
                  legends={[{ label: "Net Revenue", key: "netRevenue" }]}
                  data={chartData.netRevenue}
                  hideLegends={true}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-8 mr-2 mb-3">
              <div className="layout_new">
                <div className="layout_header mb-3">
                  <h2 className="text-typo text-md font-semibold mb-2 text-gray-500">
                    Monthly Recurring Revenue Growth
                  </h2>
                  {/* <div class="dropdown">
                    <button class="btn btn_dropdown dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                      12 Months
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> */}
                </div>
                <LineChart
                  legends={[
                    {
                      label: "Monthly Recurring Revenue",
                      key: "totalactiveUserSubscriptions",
                    },
                  ]}
                  data={chartData.recurringRevenue}
                  hideLegends={true}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-4 ml-2 mb-3 ">
              <div className="layout_new h-full">
                <div className="layout_header mb-3">
                  <h2 className="text-typo text-md font-semibold mb-2 text-gray-500">
                    Breakdown
                  </h2>
                  {/* <div class="dropdown">
                    <button class="btn btn_dropdown dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                      Everything
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> */}
                </div>
                <div className="row_data">
                  <div className="border_row">
                    <div className="number_datarow">
                      <div>
                        <span className="number_text">
                          {pipeModel.number(chartData?.newTrialsCount)}
                        </span>
                        <span className="text_data">New Trials</span>
                      </div>
                      <span className=""></span>
                    </div>
                  </div>
                  <div className="border_row">
                    <div className="number_datarow">
                      <div>
                        <span className="number_text">
                          {pipeModel.number(chartData?.newCustomerCount)}
                        </span>
                        <span className="text_data">New Customers</span>
                      </div>
                      <span className=""></span>
                    </div>
                  </div>
                  <div className="border_row">
                    <div className="number_datarow">
                      <div>
                        <span className="number_text">
                          {pipeModel.number(chartData?.totalactiveCustomers)}
                        </span>
                        <span className="text_data">Active Customers</span>
                      </div>
                      <span className=""></span>
                    </div>
                  </div>
                  <div className="border_row">
                    <div className="number_datarow">
                      <div>
                        <span className="number_text">
                          {pipeModel.number(chartData?.totalReactiveCustomers)}
                        </span>
                        <span className="text_data">Reactive Customers</span>
                      </div>
                      <span className=""></span>
                    </div>
                  </div>
                  {/* <div className='number_datarow number_datarow2'>
                    <div>
                      <span className='text_data'>Total change</span>
                    </div>
                    <span className='green_data'>+ $109.50</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-4 mr-2 mb-3">
              <div className="layout_new">
                <div className="">
                  <h2 className="text-typo text-md font-semibold mb-2 text-gray-500">
                    Monthly Recurring Revenue Growth
                  </h2>
                  <h4 className=" text-2xl font-extrabold	mb-2 text-gray-700">
                    {" "}
                    {pipeModel.currency(chartData?.totalrecurringRevenue)}
                  </h4>

                  <div className="right_layout">
                    {/* <h3><span class="material-icons green_icon">north_east</span>2.9%</h3>
                    <p className='text_light'>In Selected Period</p> */}
                  </div>
                </div>
                <LineChart
                  legends={[
                    {
                      label: "Monthly Recurring Revenue",
                      key: "totalactiveUserSubscriptions",
                    },
                  ]}
                  data={chartData.recurringRevenue}
                  hideLegends={true}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-8 ml-2 mb-3">
              <div className="layout_new h-full">
                <div className="layout_header mb-3">
                  <h2 className="text-typo text-md font-semibold mb-2 text-gray-500">
                    Customer Overview
                  </h2>
                  {/* <div class="dropdown">
                    <button class="btn btn_dropdown dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                      Days
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div>
                      <div className="left_ser">
                        <i class="fa fa-circle circle1" aria-hidden="true"></i>
                        <div>
                          <p>New Customer</p>
                          <h3>
                            {pipeModel.number(chartData?.newCustomerCount)}
                          </h3>
                        </div>
                      </div>
                      <div className="left_ser">
                        <i class="fa fa-circle circle2" aria-hidden="true"></i>
                        <div>
                          <p>Active Customer</p>
                          <h3>
                            {pipeModel.number(chartData?.totalactiveCustomers)}
                          </h3>
                        </div>
                      </div>
                      <div className="left_ser">
                        <i class="fa fa-circle circle3" aria-hidden="true"></i>
                        <div>
                          <p>Reactive Customer</p>
                          <h3>
                            {pipeModel.number(
                              chartData?.totalReactiveCustomers
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <LineChart
                      legends={[
                        { label: "New Customer", key: "newUsers" },
                        { label: "Active Customer", key: "activecustomers" },
                        {
                          label: "Reactive Customer",
                          key: "reActiveCustomers",
                        },
                      ]}
                      data={chartData?.customers}
                      hideLegends={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 mb-3 mr-2">
              <div className="layout_new">
                <div className="">
                  <h2 className="text-typo text-md font-semibold mb-2 text-gray-500">
                    New Subscriptions
                  </h2>
                  <h4 className=" text-2xl font-extrabold	mb-2 text-gray-700">
                    {pipeModel.currency(chartData?.totalNewSubscription)}
                  </h4>
                  <div className="right_layout">
                    {/* <h3><span class="material-icons red_icon">south_east</span>19.9%</h3>
                    <p className='text_light'>In Selected Period</p> */}
                  </div>
                </div>
                <LineChart
                  legends={[
                    { label: "New Subscriptions", key: "newSubscriptions" },
                  ]}
                  data={newSubsctiptions}
                  hideLegends={true}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 mb-3 ml-2">
              <div className="layout_new">
                <div className="">
                  <h2 className="text-typo text-md font-semibold mb-2 text-gray-500">
                    Active Subscriptions
                  </h2>
                  <h4 className=" text-2xl font-extrabold	mb-2 text-gray-700">
                    {pipeModel.currency(chartData?.totalActiveSubscription)}
                  </h4>
                  <div className="right_layout">
                    {/* <h3><span class="material-icons green_icon">north_east</span>2.9%</h3>
                    <p className='text_light'>In Selected Period</p> */}
                  </div>
                </div>
                <LineChart
                  legends={[
                    {
                      label: "Active Subscriptions",
                      key: "totalactiveUserSubscriptions",
                    },
                  ]}
                  data={activeSubscriptions}
                  hideLegends={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
