import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Tooltip } from "antd";
import { FiEdit3, FiPlus } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';
import Table from "../../components/Table";
import SelectDropdown from '../../components/common/SelectDropdown';
import statusModel from '../../models/status.model';
import datepipeModel from '../../models/datepipemodel';
import shared from "./shared";
import FormControl from "../../components/common/FormControl";

const Html = ({
    sorting,
    edit,
    filter,
    view,
    statusChange,
    pageChange,
    deleteItem,
    clear,
    filters,
    loaging,
    data,
    changestatus,
    isAllow,
    total = { total }
}) => {
    const {scope}=useParams()
    const columns = [
        {
            key: 'emission_source', name: 'Emission Source', sort: true,
            render: (row) => {
                return <span className='capitalize'>{row?.emission_source}</span>
            }
        },
        {
            key: 'emission_type', name: 'Category', sort: true,
            render: (row) => {
                return <>{shared.catName(row?.emission_type)}</>
            }
        },
        {
            key: 'emission_factor', name: 'Emission Factor', sort: true,
            render: (row) => {
                return <>{row?.emission_factor}</>
            }
        },
        {
            key: 'tier', name: 'Tier', sort: true,
            render: (row) => {
                return <>{row?.tier}</>
            }
        },
        {
            key: 'unit', name: 'Unit', sort: true,
            render: (row) => {
                return <>{row?.unit}</>
            }
        },
        {
            key: 'scope', name: 'Scope', sort: true,
            render: (row) => {
                return <>{scope}</>
            }
        },
        {
            key: 'location', name: 'Location',
            render: (row) => {
                return <span className='capitalize'>{row?.country_detail?.name||'Globally'}</span>
            }
        },
        {
            key: 'createdAt', name: 'Created Date',sort: true,
            render: (row) => {
                return <>
                    {datepipeModel.date(row?.createdAt)}
                </>
            }
        },
        {
            key: 'status', name: 'Status',
            render: (itm) => {
                return <>
                    <div  className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                    <Tooltip placement="top" title="Active / Inactive">
              <span className="contract">
                {itm.status == "deactive" ? "Inactive" : "Active"}
              </span>
            </Tooltip>
                    </div>
                </>
            }
        },
        {
            key: 'action', name: 'Action',
            render: (itm) => {
                return <>
                    <div className="flex items-center justify-start gap-1.5">
                    {/* <Tooltip placement="top" title="View">
                                <a className="border cursor-pointer border-[#ff7641] hover:opacity-70 rounded-lg bg-[#ff764114] w-10 h-10 !text-primary flex items-center justify-center text-xl" onClick={e => view(itm.id)}>
                                <span class="material-symbols-outlined">visibility</span>
                                </a>
                            </Tooltip> */}
                        {isAllow(`edit${shared.check}`) ?
                            <Tooltip placement="top" title="Edit">
                                <a className="border cursor-pointer border-[#ff7641] hover:opacity-70 rounded-lg bg-[#ff764114] w-10 h-10 !text-primary flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                    <FiEdit3 />
                                </a>
                            </Tooltip>
                            : <></>}
                        {isAllow(`delete${shared.check}`) ? <Tooltip placement="top" title="Delete"> <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl ' onClick={() => deleteItem(itm.id)}>
                            <BsTrash3 />
                        </span> </Tooltip> : <></>}
                    </div>
                </>
            }
        },
    ]


    return (
        <>
            <div className="flex justify-between items-center">
                <div>
                   <h3 className="text-base font-medium text-[#0A0D14]"> {shared.title} (Scope {scope})</h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your {shared.title}</p>
                </div>

                <a id='downloadFile'></a>

                <div className="flex">


                    {/* <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed mr-3" onClick={() => exportfun()}>
                        <PiFileCsv className="text-typo text-xl" />  Export CSV
                    </button> */}

                    {isAllow(`add${shared.check}`) ?
                        <Link className="bg-primary border border-solid border-[#27A376] shadow-[1px 2px 4px 0px #0d0d121f] py-[10px] px-[12px] leading-[20px] mr-[8px] flex items-center   hover:bg-[#71B55C] text-[14px]  rounded-[8px] text-white  font-medium hover:no-underline" to={`/${shared.url}/${scope}/add`}>
                            <FiPlus className="text-xl text-white w-[18px] h-[18px] me-[4px]" />   Add {shared.addTitle}
                        </Link>
                        : <></>}
                </div>


            </div>



            <div className='shadow-box w-full bg-white rounded-[16px]  mt-6'>
                <div className='flex p-4 justify-end'>
                    <div className="flex gap-2">
                        <SelectDropdown
                            id="statusDropdown"
                            displayValue="name"
                            placeholder='All Status'
                            intialValue={filters.status}
                            result={e => { changestatus(e.value) }}
                            options={statusModel.list}
                        />
                        <FormControl
                                type="select"
                                displayValue="name"
                                placeholder="All Category"
                                value={filters.emission_type}
                                onChange={e => filter({ emission_type: e })}
                                options={shared.categories}
                                theme="search"
                            />
                        {filters.status||filters.emission_type ? <>
                            <button
                                className="bg-primary leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg"
                                onClick={() => clear()}>
                                Reset
                            </button>
                        </> : <></>}


                    </div>
                </div>



                {!loaging ? <>
                    <Table
                        className='mb-3'
                        data={data}
                        columns={columns}
                        page={filters.page}
                        count={filters.count}
                        total={total}
                        result={(e) => {
                            if (e.event == 'page') pageChange(e.value)
                            if (e.event == 'sort') sorting(e.value)
                        }}
                    />

                </> : <></>}

                {
                    loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>
                }

            </div>
        </>
    );
};

export default Html;
