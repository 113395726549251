const shared={
    check:'CompanyFacility',
    title:'Company Facilities',
    addTitle:'Company Facility',
    url:'company-facility',
    type:'company_facilities',
    addApi:'api/emission-factor',
    editApi:'api/emission-factor',
    detailApi:'api/emission-factor',
    listApi:'api/emission-factors',
    statusApi:'api/emission-factor',
    deleteApi:'api/emission-factor'
}

export default shared