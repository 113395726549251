import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'antd';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { BsTrash3 } from 'react-icons/bs';
import { FiEdit3, FiPlus } from 'react-icons/fi';

const Html = ({
    sortClass,
    sorting,
    view,
    edit,
    reset,
    add,
    colClick,
    tab,
    tabChange,
    ChangeRole,
    dynamicStyle = false,
    className = null,
    ChangeStatus,
    openModal,
    statusChange,
    pageChange,
    addCol,
    deleteItem,
    exportCsv,
    uTableCols,
    removeCol,
    filters,
    tableCols,
    blockunblock,
    loaging,
    data,
    exportfun,
    roles,
    role,
    isAllow,
    total = { total }
}) => {
    return (
        <Layout>
            <div className="flex justify-between items-center mb-6">
                <div>
                   <h3 className="text-base font-medium text-[#0A0D14]"> Roles</h3>
                    <p className="text-sm font-normal text-[#75757A]">Here you can see all about your  Roles</p>
                </div>
                <div className="flex items-center">

                    {isAllow('addRole') ? <Link className="bg-primary border border-solid border-[#27A376] shadow-[1px 2px 4px 0px #0d0d121f] py-[10px] px-[12px] leading-[20px] mr-[8px] flex items-center   hover:bg-[#71B55C] text-[14px]  rounded-[8px] text-white  font-medium hover:no-underline" to="/roles/add">
                        <FiPlus className="text-xl text-white w-[18px] h-[18px] me-[4px]" /> Add Role
                    </Link> : <></>}


                    <Menu as="div" className="relative list_box_active_state ml-auto">
                        <div>
                            <Menu.Button className="flex items-center w-full border justify-center rounded-[8px] bg-white py-[9px] px-[12px] text-[14px]   font-normal  text-[#525866]  hover:bg-gray-200 font-medium -tracking-[0.084px] border-[#DFE1E6]">
                            {filters.status ?filters.status=='deactive'?'inactive':filters.status : 'All Status'}
                                <ChevronDownIcon className="ms-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#6db562] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-lg scrollbar`}>
                                <div className="mt-2">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm mb-0 active' : 'text-gray-700 block px-4 py-2 mb-0 text-sm'} onClick={() => ChangeStatus("")} >
                                                All Status
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a

                                                className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm mb-0 active' : 'text-gray-700 block px-4 py-2 text-sm mb-0'} onClick={() => ChangeStatus("active")}
                                            >
                                                Active
                                            </a>
                                        )}
                                    </Menu.Item>

                                    {/* <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                        <p className="border-t"></p>
                                    </Menu.Item> */}


                                    <Menu.Item className="">
                                        {({ active }) => (
                                            <a

                                                className={filters.status == 'Inactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("deactive")}
                                            >
                                                Inactive
                                            </a>
                                        )}
                                    </Menu.Item>

                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>



                    {/* <div className="dropdown addDropdown">
                        <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                        </button>
                        <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                            <a className={filters.status == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("")}>All Status</a>
                            <a className={filters.status == 'active' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("active")} >Active</a>
                            <a className={filters.status == 'Inactive' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                        </div>
                    </div> */}


                </div>


            </div>


            {tab == 'grid' ? <>

            </> : <>

                <div className="shadow-box w-full bg-white rounded-[16px] p-[24px]">
                    <div className="scrollbar w-full overflow-auto">

                        <table className="w-full">
                            <thead className="text-[#525866] text-[14px] capitalize bg-[#F6F8FA] font-normal">
                                <tr>
                                    <th scope="col" className='



px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] ' onClick={e => sorting('name')}>Role Name <span className='ml-1'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                    {/* <th scope="col" className='table_data'>Status</th> */}
                                    <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {!loaging && data && data.map((itm, i) => {
                                    if (itm.id != environment.adminRoleId && itm.id != environment.userRoleId)
                                        return <tr className=''>
                                            <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]' onClick={e => edit(itm.id)}>{itm.name}</td>
                                            {/* <td className='table_dats'> <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                            <span className='contract'>
                                                {itm.status == 'deactive' ? 'inactive' : 'active'}
                                            </span>
                                        </div></td> */}

                                            {/* dropdown */}
                                            <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>
                                                <div className="flex items-center justify-start gap-1.5">
                                                    {isAllow('editRole') ? <>
                                                        <Tooltip placement="top" title="Edit">
                                                            <a className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                                                <FiEdit3 />
                                                            </a>
                                                        </Tooltip>
                                                    </> : <></>}


                                                    {itm.id != environment.adminRoleId && itm.id != environment.userRoleId && isAllow('deleteRole') ? <> <Tooltip placement="top" title="Delete"> <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl' onClick={() => deleteItem(itm.id)}>
                                                        <BsTrash3 />
                                                    </span></Tooltip>
                                                    </> : <></>}

                                                </div>

                                            </td>

                                            {/* end */}

                                        </tr>

                                })
                                }
                            </tbody>
                        </table>

                    </div>


                </div>

            </>}

                {!loaging && total == 0 ?  <div className="no-data-found text-center p-5">
                    <img
                        src="/assets/img/no-data-f.png"
                        className="w-[100px] mx-auto	opacity-50	"
                    />
                    <p className="uppercase	text-sm tracking-widest ">No Data Found</p>
                    </div> : <></>}

            {
                !loaging && total > filters.count ? <div className='paginationWrapper  mt-15 '>
                    {/* <span className='text-sm text-gray-600'>Show {filters.count} from {total} Categories</span> */}
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }

            {
                loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>
            }



        </Layout >
    );
};

export default Html;
