import React, { memo, useEffect } from 'react';
import './style.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Html from './Html';
import permissionModel from '../../../models/permisstion.model';

const Sidebar = ({ isOpen }) => {
  const user = useSelector(state => state.user);
  const history = useNavigate()

  const ListItemLink = ({ to, type = 'link', disabled = false, ...rest }) => {
    let url = location.href
    const host = location.host
    url = url.split(host)[1]
    return (<>
      {type == 'link' ? <li className={`nav-item ${url.includes(to) ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
        {/* {...rest} */}
        <Link to={to} {...rest} className="" />
      </li> : <li className={`nav-item main ${url.includes(to) ? 'active' : ''}`} {...rest}></li>}
    </>
    );
  };

  const urlAllow = (url) => {
    let permissions = user.role?.permissions[0]
    let arr = url.split(',')
    let value = false
    arr.map(itm => {
      if (permissionModel.urlAllow(permissions, itm)) value = true
    })

    return value
  }

  const route = (p) => {
    history(p)
  }
  const  scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'auto' });
    } else {
      console.error(`Element with id '${id}' not found`);
    }
  };
  useEffect(()=>{
    scrollToId(window.location.pathname)
  },[window.location.pathname])

  return <>
    <Html
      route={route}
      urlAllow={urlAllow}
      ListItemLink={ListItemLink}
      isOpen={isOpen}
    />
  </>
};

export default memo(Sidebar);
