const shared={
    check:'KPI',
    title:'KPIs',
    addTitle:'KPI',
    url:'kpi',
    type:'kpi',
    addApi:'api/dynamic-fields',
    editApi:'api/edit/dynamic-fields',
    detailApi:'api/dynamic-field',
    listApi:'api/all/dynamic-fields',
    statusApi:'api/dynamic-field',
    deleteApi:'api/delete/dynamic-fields'
}

export default shared