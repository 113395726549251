const shared={
    check:'Vehicle',
    title:'Vehicles',
    addTitle:'Vehicle',
    url:'vehicle',
    type:'vehicle',
    addApi:'api/emission-factor',
    editApi:'api/emission-factor',
    detailApi:'api/emission-factor',
    listApi:'api/emission-factors',
    statusApi:'api/emission-factor',
    deleteApi:'api/emission-factor'
}

export default shared