import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import shared from "./shared";
import { useSelector } from "react-redux";
import CompanyFacility from "./CompanyFacility";
import Fuel from "./Fuel";
import Vehicle from "./Vehicle";
import Waste from "./Waste";
import Procurement from "./Procurement";
import Accomodation from "./Accomodation";
import Flights from "./Flights";
import EmployeeCommuting from "./EmployeeCommuting";

const AddEdit = () => {
    const { id, scope } = useParams()
    const [form, setform] = useState({
        id: '',
        emission_type: '',
        emission_source: '',
        fuel_type: '',
        status: 'active',
        emission_factor: '',
        tier: '',
        unit: '',
        scope: [Number(scope||3)],
        country_id: '',
        source: '',
        vehicle_type: '',
        condition: '',
        category_id: '',
        waste_type: '',
        waste_id:'',
        disposal_type: '',
        fuel_id:'',
        procurement_type:'',
        accomodation_type:'',
        flight_type:'',
        vehicle_id:'',
        segment:''
    })
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'emission_type', required: true },
        { key: 'status', required: true },
    ]

    const back = () => {
        history(`/${shared.url}/${scope}`)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        let url = shared.addApi
        let value = {
            ...form,
        }

        Object.keys(value).map(itm => {
            value[itm] = value[itm] || null
        })

        if(!value.flight_type) value.flight_type='domestic_flight'

        if (value.id) {
            method = 'put'
            url = shared.editApi
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                back()
            }
            loader(false)
        })
    }


    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get(shared.detailApi, { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = form

                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })

         
                    if(payload.fuel_id?._id) payload.fuel_id=payload.fuel_id?._id
                    if(payload.category_id?._id) payload.category_id=payload.category_id?._id
                    if(payload.country_id?._id) payload.country_id=payload.country_id._id
                    if(payload.waste_id?._id) payload.waste_id=payload.waste_id?._id
                    if(payload.vehicle_id?._id) payload.vehicle_id=payload.vehicle_id?._id
                    
                    payload.id = id
                    setform({
                        ...payload
                    })


                }
                loader(false)
            })
        }

    }, [id])

    const getError = (key) => {
        return submitted ? methodModel.getError(key, form, formValidation)?.message : ''
    }

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">


                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <span onClick={back} className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"><i className='fa fa-angle-left text-lg'></i></span>
                        </Tooltip>
                        <div>
                            <h3 className="text-base font-medium text-[#0A0D14]">
                                {form && form.id ? 'Edit' : 'Add'}  {shared.addTitle}
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your {shared.addTitle}</p>
                        </div>
                    </div>



                    <h3 className="ViewUser mb-3"></h3>
                    <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="select"
                                label="Category"
                                displayValue="name"
                                placeholder="Select Category"
                                value={form.emission_type}
                                onChange={e => setform({ ...form, emission_type: e })}
                                options={shared.categories}
                                theme="search"
                                required
                                error={getError('emission_type')}
                            />
                        </div>

                        {form.emission_type=='company_facilities'?<>
                            <CompanyFacility  form={form} setform={setform} getError={getError} />
                        </>:<></>}
                        {form.emission_type=='fuel'?<>
                            <Fuel form={form} setform={setform} getError={getError} />
                        </>:<></>}

                        {form.emission_type=='vehicle'?<>
                            <Vehicle form={form} setform={setform} getError={getError} />
                        </>:<></>}

                        {form.emission_type=='waste'?<>
                            <Waste form={form} setform={setform} getError={getError} />
                        </>:<></>}
                        {form.emission_type=='procurement'?<>
                            <Procurement form={form} setform={setform} getError={getError} />
                        </>:<></>}
                        {form.emission_type=='accomodation'?<>
                            <Accomodation form={form} setform={setform} getError={getError} />
                        </>:<></>}
                        {form.emission_type=='flights'?<>
                            <Flights form={form} setform={setform} getError={getError} />
                        </>:<></>}
                        {form.emission_type=='employee_commuting'?<>
                            <EmployeeCommuting form={form} setform={setform} getError={getError} />
                        </>:<></>}
                   
                    </div>
                    <div className="text-right mt-4">

                        <button type="submit" className=" text-white bg-[#545454] font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                    </div>
                </div>


            </form>
        </Layout>
    </>
}

export default AddEdit