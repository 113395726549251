import {  useNavigate } from "react-router-dom";
import Layout from "../../components/global/layout";
import { useEffect, useState } from "react";
import datepipeModel from "../../models/datepipemodel";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import shared from "./shared";
const View = () => {
    const [data, setData] = useState()
    const [host, setHost] = useState()
    const history = useNavigate()
    const {id}=useParams()


    const getDetail=()=>{
        ApiClient.get(shared.detailApi,{id:id}).then(res=>{
            if(res.success){
                setData(res.data)
            }
        })
    }

    useEffect(()=>{
        getDetail()
    },[])

    return <>
        <Layout>
            <div className="text-right">
                <div>
                    <a to="/users" onClick={() => history(`/${shared.url}`)}>  <i className="fa fa-arrow-left mr-4 mb-3 " title='Back' aria-hidden="true"></i></a>
                </div>
            </div>
            <div className='bg-white shadow-box rounded-lg w-full p-4 mt-6'>

                <div className="grid grid-cols-12 gap-4">
                    <div className="sideclass col-span-12 md:col-span-12">
                        <h3 className="mt-3 mb-6 py-2 bg-gray-300 px-3">{shared.addTitle} Details</h3>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Fuel</label>
                                <div className='profiledetailscls'>{data?.fuel || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Emission Factor</label>
                                <div className='profiledetailscls capitalize'>{data?.emissionFactor || '--'}</div>
                            </div>
                            
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Tier</label>
                                <div className='profiledetailscls'>{data?.tier || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Unit</label>
                                <div className='profiledetailscls'>{data?.unit || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Scope</label>
                                <div className='profiledetailscls'>{data?.scope || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Location</label>
                                <div className='profiledetailscls'>{data?.location || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Created Date</label>
                                <div className='profiledetailscls'>{datepipeModel.date(data?.createdAt)}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    </>
}

export default View;