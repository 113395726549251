const shared={
    check:'Accommodation',
    title:'Accommodations',
    addTitle:'Accommodation',
    url:'accomodation',
    type:'accomodation',
    addApi:'api/emission-factor',
    editApi:'api/emission-factor',
    detailApi:'api/emission-factor',
    listApi:'api/emission-factors',
    statusApi:'api/emission-factor',
    deleteApi:'api/emission-factor'
}

export default shared