import React from "react";
import methodModel from "../../../methods/methods";

const Html = ({ inputElement, uploadImage, img, remove, loader, model, multiple, required ,err,label=''}) => {
    return <>
        <label className={`btn btn-primary ${img && !multiple ? 'd-none' : ''}`}>
            <input type="file" className="d-none" ref={inputElement} accept="image/*" multiple={multiple ? true : false} disabled={loader} onChange={(e) => { uploadImage(e); }} />
            {label||'Upload Image'}
        </label>

        {loader ? <div className="text-success">Uploading... <i className="fa fa-spinner fa-spin"></i></div> : <></>}

        {multiple ? <>
            <div className="imagesRow">
                {img && img.map((itm, i) => {
                    return <div className="imagethumbWrapper">
                        <img src={methodModel.noImg(itm, model)} className="thumbnail" />
                        <i className="fa fa-times" title="Remove" onClick={e => remove(i)}></i>
                    </div>
                })}
            </div>
        </> : <>
            {img ? <div className="relative w-[100px]">
                <img src={methodModel.noImg(img, model)} className="h-[100px] object-cover w-[100px]" />
                <i className="fa fa-times p-1 rounded-full w-[20px] h-[20px] text-xs flex items-center justify-center text-danger icon-css absolute -top-[8px] -right-[8px] bg-white shadow shadow-current" title="Remove" onClick={e => remove()}></i>
            </div> : <></>}
        </>}

        {required && !img ? <div className="text-danger">{err ? err : 'Image is Required'}</div> : <></>}
    </>
}
export default Html