import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import shared from "./shared";
import { useSelector } from "react-redux";
import environment from "../../environment";

const AddEdit = () => {
    const { id } = useParams()
    const [fuel, setFuel] = useState([])
    const [images, setImages] = useState({ image: ''});
    const [categories, setCategories] = useState([]);
    const [form, setform] = useState({ id: '', name:'',status: 'active',category_id:'',type:'',options:'',required:true})
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'status', required: true ,message:'Status is Required'},
        { key: 'category_id', required: true ,message:'Category is Required'},
        { key: 'type', required: true,message:'Input Type is Required' },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        let url = shared.addApi
        let value = {
            ...form,
            ...images,
        }
        if (value.id) {
            method = 'put'
            url = shared.editApi
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history(`/${shared.url}`)
            }
            loader(false)
        })
    }



    const getCategories=()=>{
        let f={
            page:1,
            status:"active",
            catType:environment.kpiTypeId
        }
        ApiClient.get('api/categories/listing',f).then(res=>{
            if(res.success){
                setCategories(res.data)
            }
        })
    }

    useState(()=>{
        getCategories()
    },[])


    const inputType=["Text","%","Yes/No","Multi Options"].map(itm=>({id:itm.toLowerCase(),name:itm}))

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get(shared.detailApi, { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = form

                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })

                    if(payload.category_id?._id) payload.category_id=payload.category_id._id
                    payload.required=payload.required?true:false

                    payload.id=id
                    setform({
                        ...payload
                    })

                    let img=images
                    Object.keys(img).map(itm => {
                        img[itm] = value[itm]
                    })
                    setImages({...img})


                }
                loader(false)
            })
        }

    }, [id])

    const imageResult = (e, key) => {
        images[key] = e.value
        setImages(images)
    }

    const getError = (key) => {
        return submitted?methodModel.getError(key, form, formValidation)?.message:''
    }


    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">


                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to={`/${shared.url}`} className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">
                                {form && form.id ? 'Edit' : 'Add'}  {shared.addTitle}
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your {shared.addTitle}</p>
                        </div>
                    </div>



                    <h3 className="ViewUser mb-3"></h3>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="text"
                                label="Name"
                                value={form.name}
                                onChange={e => setform({ ...form, name: e })}
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                        <FormControl
                                type="select"
                                name="status"
                                label="Status"
                                placeholder="Select Status"
                                value={form.status}
                                onChange={e => setform({ ...form, status: e })}
                                options={statusModel.list}
                                required
                                error={getError('status')}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="select"
                                label="Input Type"
                                options={inputType}
                                placeholder="Select Option"
                                value={form.type}
                                theme="search"
                                onChange={e => {
                                    let options=''
                                    if(e=='yes/no'||e=='multi options') options='Yes\nNo'
                                    setform({ ...form, type: e,options})
                                }}
                                error={getError('type')}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                        <FormControl
                                type="select"
                                label="Category"
                                placeholder="Select Option"
                                value={form.category_id}
                                onChange={e => setform({ ...form, category_id: e })}
                                options={categories}
                                required
                                theme="search"
                                error={getError('category_id')}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                        <FormControl
                                type="toggle"
                                label="Required"
                                value={form.required}
                                onChange={e => setform({ ...form, required: e })}
                            />
                        </div>

                        
                        {form.type=='yes/no'||form.type=='multi options'?<>
                        <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="textarea"
                                label="Options"
                                value={form.options}
                                onChange={e => setform({ ...form, options: e })}
                                disabled={form.type=='Yes/No'?true:false}
                                
                            />
                        </div>
                        </>:<></>}

                      
                        

                        
                    </div>
                    <div className="text-right mt-4">

                        <button type="submit" className=" text-white bg-[#545454] font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                    </div>
                </div>


            </form>
        </Layout>
    </>
}

export default AddEdit