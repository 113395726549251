import React, { useState, useEffect, useRef } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";
import { emailType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ReactQuill from 'react-quill';
import { Tooltip } from "antd";

const AddEditEmailTemplate = () => {
    const defaultvalue = () => {
        let keys = { ...emailType }
        Object.keys(emailType).map(itm => {
            if (itm != 'permissions') keys[itm] = ''
        })
        keys.status = 'active'
        return keys
    }
    const { id } = useParams()
    const [form, setform] = useState(emailType)
    const [tab, setTab] = useState('form')
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const specialChars = useRef([])
    const [variables, setVariables] = useState([])
    const [htmlCode, setHtmlCode] = useState(false)
    const formValidation = [
        { key: 'subject', required: true },
    ]

    const handleSubmit = (e) => {
        if (e) e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) {
            setTab('form')
            return
        }
        let method = 'post'
        let url = 'api/email'
        let value = {
            ...form,
        }
        if (value.id) {
            method = 'put'
            url = `api/update/template?id=${value.id}`
        } else {
            delete value.id
        }
        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                history("/emailtemplate")
            }
            loader(false)
        })
    }

    const getConstants = () => {
        ApiClient.get('api/constants').then(res => {
            if (res.success) {
                let data = res.data.map(itm => {
                    return {
                        text: itm, value: itm
                    }
                })
                // console.log("data",data)
                setVariables([...data])
                specialChars.current = data
            }
        })
    }

    useEffect(() => {
        // getConstants()
        if (id) {
            loader(true)
            ApiClient.get('api/template', { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = emailType
                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })
                    setform({
                        ...payload
                    })
                    setVariables([...value?.constants])
                                }
                loader(false)
            })
        } else {
            setform(defaultvalue())
        }
    }, [id])

    const onSelect = (e) => {
        console.log("onSelect", e)
    }

    const onRemove = (e) => {
        console.log("onRemove", e)
    }



    const textAreaRef = useRef(null);

    const insertVariable = (variable) => {
        try{        const textarea = textAreaRef.current;
            console.log(textarea,"Thisis the Value of Text Area --------")
        const cursorPos = textarea.selectionStart;
            const end = textarea.selectionEnd;
        const textBeforeCursor = form.content.substring(0, cursorPos);
        const textAfterCursor = form.content.substring(cursorPos, form.content.length);

        // Insert the variable at the cursor position
        const updatedText=textBeforeCursor +"{"+ variable+"}" + textAfterCursor
        textarea.value=updatedText
        setform({...form,content:updatedText})

            

            // Ensure the textarea maintains focus after insertion
            textarea.focus();
            textAreaRef.current.selectionEnd = end + variable.length+2;
             

        }catch(err){
            console.log(err)
        }
    };

    return <>
        <Layout>
            {tab == 'form' ? <>
                <form onSubmit={handleSubmit}>
                    <div className="pprofile1">

                        <div className='flex items-center mb-8'>
                            <Tooltip placement="top" title="Back">
                                <Link to="/emailtemplate" className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border  transition-all    mr-3"><i className='fa fa-angle-left text-lg'></i></Link>
                            </Tooltip>
                            <div>
                               <h3 className="text-base font-medium text-[#0A0D14]">
                                    {form && form.id ? 'Edit' : 'Add'} Email
                                </h3>
                                <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Email</p>
                            </div>
                        </div>




                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-6">
                                <label>Title<span className="star">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={form.title}
                                    onChange={e => setform({ ...form, title: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label>Subject<span className="star">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={form.subject}
                                    onChange={e => setform({ ...form, subject: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="col-span-12 md:col-span-12">
                                <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-12 ">

                                        <label>Body<span className="star">*</span></label>

                                        <ul class="nav nav-tabs mb-3 border-none">
                                            <li className="nav-item">
                                                <a className={`nav-link ${htmlCode ? 'active' : ''}`} onClick={e => setHtmlCode(true)}>Html Code</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${!htmlCode ? 'active' : ''}`} onClick={e => setHtmlCode(false)}>Editor</a>
                                            </li>
                                        </ul>
                                        {htmlCode ? <>
                                            <textarea ref={textAreaRef} className="form-control htmlCode" value={form.content} onChange={e => setform({ ...form, content: e.target.value })}></textarea>
                                        </> : <>
                                        <ReactQuill theme="snow" value={form?.content} onChange={e=>{
                               setform({ ...form, content: e })
                            }} />
                                        </>}

                                    </div>
                                    <div className="col-span-12 ">
                                        <div className="">
                                    
                                            <label>Variables</label>
                                            <ul className="flex items-center flex-wrap">
                                                {variables.map(itm => {
                                                    return <li onClick={e => insertVariable(itm)} className="bg-[#71B55C] px-3 py-1 rounded-[4px] text-white w-fit text-[13px] me-2 my-1">{itm}</li>
                                                })}

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="text-right">

                            <button type="button" className="btn btn-primary mr-2" onClick={e => setTab('preview')}>Preview</button>
                            <button type="submit" className="btn btn-primary">Save</button>
                        </div>
                    </div>


                </form>
            </> : <>
                <div className="pprofile1">
                    <h3 className="ViewUser mb-3">Preview</h3>
                    <div className="p-2 border" dangerouslySetInnerHTML={{ __html: form?.content }}></div>
                    <div className="text-right mt-3">
                        <button type="button" className="btn btn-secondary discard mr-2" onClick={e => setTab('form')}>Back</button>
                        <button className="btn btn-primary" onClick={e => handleSubmit()}>Save</button>
                    </div>
                </div>
            </>}

            <div>
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Body</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <span dangerouslySetInnerHTML={{ __html: form.content }}></span>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>
}

export default AddEditEmailTemplate