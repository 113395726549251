import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import shared from "./shared";
import { useSelector } from "react-redux";
import { FiEdit3, FiPlus } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

import { MdDelete } from "react-icons/md";



const AddEdit = () => {
  const { id } = useParams();
  const [images, setImages] = useState({ image: "" });
  const [emissions, setEmissions] = useState([]);
  const [feilds, setFeilds] = useState([{ status: "active" }]);
  const [form, setform] = useState({
    id: "",
    emission_id: "",
    field_name: "",
    field_type: "",
    emission_id: "",
    required: false,
    options: "",
  });
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const formValidation = [
    {
      key: "emission_id",
      required: true,
      message: "Emission source is Required",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    let method = "post";
    let url = shared.addApi;
    let value = {
      ...form,
      ...images,
    };
    if (value.id) {
      method = "put";
      url = shared.editApi;
    } else {
      delete value.id;
    }

    let payload = {
      fields: feilds.map((itm) => {
        return { ...itm, emission_id: form.emission_id };
      }),
    };

    if (id) {
      payload = { ...payload.fields[0] };
    }

    loader(true);
    ApiClient.allApi(url, payload, method).then((res) => {
      if (res.success) {
        // ToastsStore.success(res.message)
        history(`/${shared.url}`);
      }
      loader(false);
    });
  };

  const getEmissions = () => {
    let f = {
      page: 1,
      status: "active",
    };
    ApiClient.get("api/emission-factors", f).then((res) => {
      if (res.success) {
        setEmissions(res.data);
      }
    });
  };

  useState(() => {
    getEmissions();
  }, []);

  const inputType = ["Text", "%", "Yes/No", "Multi Options"].map((itm) => ({
    id: itm.toLowerCase(),
    name: itm,
  }));

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get(shared.detailApi, { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          let payload = form;

          Object.keys(payload).map((itm) => {
            payload[itm] = value[itm];
          });

          if (payload.emission_id?._id)
            payload.emission_id = payload.emission_id._id;

          payload.id = id;
          setform({
            ...payload,
          });

          setFeilds([payload]);

          let img = images;
          Object.keys(img).map((itm) => {
            img[itm] = value[itm];
          });
          setImages({ ...img });
        }
        loader(false);
      });
    }
  }, [id]);

  const getError = (key) => {
    return submitted
      ? methodModel.getError(key, form, formValidation)?.message
      : "";
  };

  const addField = () => {
    let payload = {
      status: "active",
    };
    let arr = feilds;
    arr.push(payload);
    setFeilds([...arr]);
  };

  const removeField = (i) => {
    let arr = feilds;
    arr = arr.filter((itm, ind) => ind != i);
    setFeilds([...arr]);
  };

  const updateField = (i, payload) => {
    let arr = feilds;
    arr[i] = {
      ...arr[i],
      ...payload,
    };
    setFeilds([...arr]);
  };

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="flex items-center justify-between mb-8">
              <div className="flex items-center ">
                <Tooltip placement="top" title="Back">
                  <Link
                    to={`/${shared.url}`}
                    className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"
                  >
                    <i className="fa fa-angle-left text-lg"></i>
                  </Link>
                </Tooltip>
                <div>
                  <h3 className="text-2xl font-semibold text-[#111827]">
                    {form && form.id ? "Edit" : "Add"} {shared.addTitle}
                  </h3>
                  <p class="text-sm font-normal text-[#75757A]">
                    Here you can see all about your {shared.addTitle}
                  </p>
                </div>
              </div>
              <button
                type="button"
                className=" text-white bg-[#71B55C] font-medium rounded-[8px] text-sm px-5 py-2.5 text-center flex items-center"
                onClick={addField}
              >
                <FiPlus className="text-xl text-white w-[18px] h-[18px] me-[4px]" />{" "}
                Add More Fields
              </button>
            </div>

            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <FormControl
                  type="select"
                  label="Emission source"
                  placeholder="Select Option"
                  value={form.emission_id}
                  onChange={(e) => setform({ ...form, emission_id: e })}
                  options={emissions}
                  required
                  displayValue="emission_source"
                  theme="search"
                  error={getError("emission_id")}
                />
              </div>
            </div>
            {feilds.map((itm, i) => {
              return (
                <>
                  <div className="grid grid-cols-12 gap-4  mb-3">
                    <div className="col-span-12 md:col-span-5">
                      <FormControl
                        type="text"
                        label="Name"
                        value={itm.field_name}
                        onChange={(e) => updateField(i, { field_name: e })}
                        required
                      />
                    </div>

                    <div className="col-span-12 md:col-span-5">
                      <FormControl
                        type="select"
                        label="Input Type"
                        options={inputType}
                        placeholder="Select Option"
                        value={itm.field_type}
                        theme="search"
                        onChange={(e) => {
                          let options = "";
                          if (e == "yes/no" || e == "multi options")
                            options = "Yes\nNo";
                          updateField(i, { field_type: e, options });
                        }}
                        // error={getError('field_type')}
                      />
                    </div>

                    <div className="col-span-12 md:col-span-1">
                      <FormControl
                        type="toggle"
                        label="Required"
                        value={itm.required}
                        onChange={(e) => updateField(i, { required: e })}
                      />
                    </div>

                    {itm.field_type == "yes/no" ||
                    itm.field_type == "multi options" ? (
                      <>
                        <div className="col-span-12 md:col-span-6">
                          <FormControl
                            type="textarea"
                            label="Options"
                            value={itm.options}
                            onChange={(e) => updateField(i, { options: e })}
                            disabled={itm.field_type == "Yes/No" ? true : false}
                            required
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {feilds.length > 1 ? (
                      <>
                        <div className="col-span-12 md:col-span-1">
                          <div className="flex items-center justify-center h-100 w-100">
                            <button
                              type="button"
                              className="flex items-center text-white bg-[#eb3a3a] font-medium rounded-lg text-sm px-3 py-2.5 text-center mt-5"
                              onClick={() => removeField(i)}
                            >
                            
                                <MdDelete  className="text-lg"/>

                            
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              );
            })}

            <div className="text-right mt-5">
              <button
                type="submit"
                className=" text-white bg-[#545454] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Save
              </button>
              {!id ? <></> : <></>}
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEdit;
