const categories=[
    {id:'company_facilities',name:'Company Facilities'},
    {id:'fuel',name:'Fuels'},
    {id:'vehicle',name:'Vehicles'},
    {id:'waste',name:'Wastes'},
    {id:'procurement',name:'Procurements'},
    {id:'accomodation',name:'Accommodations'},
    {id:'flights',name:'Flights'},
    {id:'employee_commuting',name:'Employee Commuting'},
]

const catName=(id)=>{
    let ext=categories.find(itm=>itm.id==id)
    return ext?ext.name:'--'
}

const shared={
    check:'EmissionFactor',
    title:'Emission Factors',
    addTitle:'Emission Factor',
    url:'emission',
    type:'company_facilities',
    addApi:'api/emission-factor',
    editApi:'api/emission-factor',
    detailApi:'api/emission-factor',
    listApi:'api/emission-factors',
    statusApi:'api/emission-factor',
    deleteApi:'api/emission-factor',
    categories:categories,
    catName:catName
}

export default shared