import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import userTableModel from '../../models/table.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { PiFileCsv } from 'react-icons/pi';
import { FiEdit3, FiPlus } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';
import { HiOutlineArrowDown } from 'react-icons/hi';

const Html = ({
    sorting,
    edit,
    statusChange,
    pageChange,
    deleteItem,
    dynamicStyle = false,
    className = null,
    filters,
    loaging,
    data,
    exportfun,
    changestatus,
    isAllow,
    clear,
    total = { total }
}) => {
    return (
        <Layout>
            <div className="flex justify-between items-center">
                <div>
                   <h3 className="text-base font-medium text-[#0A0D14]"> Refund Reasons</h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Refund Reasons</p>
                </div>

                <a id='downloadFile'></a>
                <div className="flex">


                    {/* <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed mr-3" onClick={() => exportfun()}>
                        <PiFileCsv className="text-typo text-xl" />  Export CSV
                    </button> */}

                        <Link className="bg-primary border border-solid border-[#27A376] shadow-[1px 2px 4px 0px #0d0d121f] py-[10px] px-[12px] leading-[20px] mr-[8px] flex items-center   hover:bg-[#71B55C] text-[14px]  rounded-[8px] text-white  font-medium hover:no-underline" to="/refund-reason/add">
                            <FiPlus className="text-xl text-white w-[18px] h-[18px] me-[4px]" />   Add Refund Reason
                        </Link>

                </div>


            </div>



            <div className='shadow-box w-full bg-white rounded-[16px] mt-6'>
                <div className='flex p-4 justify-end'>
                    <div className="dropdown1 addDropdown1 chnagesg flex gap-3">

                        <Menu as="div" className="relative list_box_active_state ml-auto">
                            <div>
                                <Menu.Button className="flex items-center w-full border justify-center rounded-[8px] bg-white py-[9px] px-[12px] text-[14px]   font-normal  text-[#525866]  hover:bg-gray-200 font-medium -tracking-[0.084px] border-[#DFE1E6]">
                                    {filters.status ? filters.status=='deactive'?'inactive':filters.status : 'All Status'}
                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#6db562] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                    }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-lg scrollbar`}>
                                    <div className="mt-2">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active mb-0' : 'text-gray-700 block px-4 py-2 text-sm mb-0'} onClick={() => changestatus("")}>All Status</a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm mb-1'} onClick={() => changestatus("active")} >Active</a>
                                            )}
                                        </Menu.Item>

                                        {/* <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                            <p className="border-t"></p>
                                        </Menu.Item> */}


                                        <Menu.Item className="">
                                            {({ active }) => (
                                                <a className={filters.status == 'deactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => changestatus("deactive")} >Inactive</a>
                                            )}
                                        </Menu.Item>

                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>


                        <button 
                        className="bg-primary leading-10 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg gap-2"
                        onClick={clear}
                        >
                             Clear
                        </button>

                    </div>
                </div>


                       


                        <div className="  mx-[24px] pt-[24px] border-t border-[#E2E4E9] ">
                            <div className="scrollbar w-full overflow-auto">


                                <table class="w-full">
                                    <thead className="text-[#525866] text-[14px] capitalize bg-[#F6F8FA] font-normal">
                                        <tr>
                                            <th scope="col" className='



px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] ' onClick={e => sorting('title')}>Name<span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                            {/* <th scope="col" className='



px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] '>Date</th> */}
                                            <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] '>Status</th>
                                            <th scope="col" className='



px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] '>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loaging && data && data.map((itm, i) => {
                                            return <tr className=''>
                                                <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9] capitalize' onClick={e => edit(itm.id)}>{itm.title}</td>
                                                {/* <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9] pointer' onClick={e => edit(itm.id)}>{datepipeModel.datetime(itm.createdAt)}</td> */}
                                                <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'> <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                    <Tooltip placement="top" title="Active / Inactive">
                                                        <span className='contract'>
                                                            {itm.status == 'deactive' ? 'inactive' : 'active'}
                                                        </span>
                                                    </Tooltip>
                                                </div></td>

                                                {/* dropdown */}
                                                <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>
                                                    <div className="flex items-center justify-start gap-1.5">
                                                            <Tooltip placement="top" title="Edit">
                                                                <a className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                                                    <FiEdit3  className='text-[#2b2b2bb3]' />
                                                                </a>
                                                            </Tooltip>
                                                        {itm.id != environment.planTypeId && itm.id != environment.resellerTypeId && itm.id != environment.productTypeId && isAllow('deleteTypes') ? <Tooltip placement="top" title="Delete"> <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl ' onClick={() => deleteItem(itm.id)}>
                                                            <BsTrash3 />
                                                        </span> </Tooltip> : <></>}
                                                    </div>
                                                </td>
                                                {/* end */}
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>


                        </div>

                


                {!loaging && total == 0 ? <div className="no-data-found text-center p-5">
                    <img
                        src="/assets/img/no-data-f.png"
                        className="w-[100px] mx-auto	opacity-50	"
                    />
                    <p className="uppercase	text-sm tracking-widest ">No Data Found</p>
                    </div> : <></>}

                {
                    !loaging && total > filters.count ? <div className='paginationWrapper flex items-center justify-between mt-15'>
                        <span>Show {filters.count} from {total} Refund Reasons</span>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }

                {
                    loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>
                }

            </div>
        </Layout >
    );
};

export default Html;
