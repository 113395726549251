import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import datepipeModel from "../../models/datepipemodel";
import SelectDropdown from "../../components/common/SelectDropdown";
import countryStateModel from "../../models/countryState.model";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import DateRangePicker from "../../components/common/DateRangePicker";
import { Tooltip } from "antd";


const AddEditCoupon = () => {
    const { id } = useParams()
    const [form, setform] = useState({
        name: '',
        dates: [{ start: '', end: '', year: '' }],
        start: '',
        end: '',
        counties: [],
        type: 'public',
        rule: '01-01',
        country: '',
        year: '',
        types: ["public"],
        id: ''
    })
    const [loaging, setLoader] = useState(false)
    const [categories, setCategories] = useState(false)
    const [category, setCategory] = useState([])
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const [country, setcountry] = useState([])
    const [states, setStates] = useState([])
    const formValidation = [
        { key: 'country', required: true },
        // { key: 'end', required: true },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)

        let ext = form?.dates?.find(itm => !itm.start)

        if (invalid
            || ((!form?.dates?.length || ext) && !id)

        ) return
        let method = 'post'
        let url = 'api/holiday'
        let value = {
            ...form
        }
        if (value.id) {
            method = 'put'
            url = 'api/holiday'
        } else {
            delete value.id
        }

        // value.year=value.start.split('-')[0]
        // value.date=value.start

        console.log("value", value)

        // return

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history("/holidays")
            }
            loader(false)
        })
    }

    const getCategories = (p = {}) => {
        setLoader(true)
        let filter = { page: 1, count: 500, status: 'active' }
        ApiClient.get('api/categories/listing', filter).then(res => {
            if (res.success) {
                setCategories(res.data.map(itm => {
                    itm.id = itm._id
                    return itm
                }))
            }
            setLoader(false)
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get('api/holiday/detail', { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = form

                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })

                    console.log("payload", payload)
                    if (payload.country) {
                        getState(payload.country)
                    }

                    setform({
                        ...payload
                    });
                }
                loader(false)
            })
        }
        getCategories()
        getCountry()
    }, [id])


    const getCountry = () => {
        ApiClient.get(`api/holidays/countries`).then(res => {
            if (res.success) {
                let data = Object.keys(res.data).map(item => {
                    return ({ id: item.toLocaleLowerCase(), name: res.data[item] })
                })
                data = data.sort((a, b) => {
                    return a.name - b.name
                })
                setcountry(data)
            }
        })
    }

    const getState = (ciso) => {
        let arr = countryStateModel.getStates(ciso).map(itm => {
            return {
                value: `${ciso.toUpperCase()}-${itm.state_code.toUpperCase()}`,
                id: `${ciso.toUpperCase()}-${itm.state_code.toUpperCase()}`,
                label: itm.name
            }
        })

        console.log("arr", arr)
        setStates([...arr])
    }

    const blockDateChange = (e, i) => {
        let payload = {
            end: datepipeModel.datetostring(e.endDate),
            start: datepipeModel.datetostring(e.startDate),
        }

        payload.year = payload.start.split('-')[0]

        console.log("payload", payload)

        let arr = form.dates
        arr[i] = payload
        console.log("arr", arr)
        setform({ ...form, dates: [...arr] })
    }

    const addDates = () => {
        let arr = form.dates
        arr.push({ start: '', end: '', year: '' })
        setform({ ...form, dates: [...arr] })
    }

    const removeDates = (index) => {
        let arr = form.dates
        arr = arr.filter((itm, i) => i != index)
        setform({ ...form, dates: [...arr] })
    }

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">

                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to="/holidays" className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border  transition-all    mr-3"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                           <h3 className="text-base font-medium text-[#0A0D14]">
                                {form && form.id ? 'Edit' : 'Add'} Holiday
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Holiday</p>
                        </div>
                    </div>




                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <label>Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                value={form.name}
                                onChange={e => setform({ ...form, name: e.target.value })}
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <label>Country<span className="star">*</span></label>
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder='All Countries'
                                intialValue={form.country}
                                theme='search'
                                result={e => {
                                    setform({ ...form, country: e.value, counties: [] });
                                    getState(e.value)
                                }}
                                options={country}
                            />
                            {submitted && !form.country ? <>
                                <div className="text-danger small">Country is required</div>
                            </> : <></>}
                        </div>

                        {form.country ? <>
                            <div className="col-span-12 md:col-span-6">
                                <label>States<span className="star">*</span></label>
                                <MultiSelectDropdown
                                    displayValue="label"
                                    result={e => {
                                        console.log("e", e)
                                        setform({ ...form, counties: e.value })
                                    }}
                                    intialValue={form.counties}
                                    options={states}
                                />
                            </div>
                        </> : <></>}
                        {id ? <>
                            <div className="col-span-12 md:col-span-12">
                                <label>Date<span className="star">*</span></label>
                                <DateRangePicker
                                    value={form.start ? { startDate: form.start, endDate: form.end } : { startDate: '', endDate: '' }}
                                    id="daterangess"
                                    onChange={e => { setform({ ...form, start: e.startDate, end: e.endDate }) }}
                                />
                            </div>

                        </> : <>
                            <div className="col-span-12 md:col-span-12">
                                <label>Dates<span className="star">*</span></label>
                                <div className="grid grid-cols-12 gap-4">
                                    {form.dates.map((itm, i) => {
                                        return <div className="col-span-12 md:col-span-6">
                                            <div className="flex">
                                                <DateRangePicker
                                                    value={itm.start ? { startDate: itm.start, endDate: itm.end } : { startDate: '', endDate: '' }}
                                                    id="daterange"
                                                    onChange={e => blockDateChange(e, i)}
                                                />
                                                {form.dates.length == 1 ? <></> : <>
                                                <div className="bg-[#e51111] pointer  flex justify-center px-4 py-3 ms-2 w-fit	items-center rounded-[4px] text-white text-sm" onClick={() => removeDates(i)}>
                                                <i className="fa fa-trash  text-[12px] text-white" ></i>
                                                </div>
                                                    
                                                </>}
                                            </div>
                                            {submitted && !itm.start ? <>
                                                <div className="text-danger small">Date range is required</div>
                                            </> : <></>}
                                        </div>
                                    })}

                                </div>
                                <div className="text-right mb-3">
                                    <button className=" text-white bg-[#545454] font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="button" onClick={addDates}>Add More Dates</button>
                                </div>
                            </div>
                        </>}




                    </div>
                    <div className="flex justify-end mt-6">

                        <button type="submit" className=" text-white bg-[#545454] font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                    </div>
                </div>


            </form>
        </Layout>
    </>
}

export default AddEditCoupon