import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import shared from "./shared";
import { useSelector } from "react-redux";

const AddEdit = () => {
    const { id } = useParams()
    const [country, setCountry] = useState([])
    const [countryLoader, setCountryLoader] = useState(false)
    const [images, setImages] = useState({ image: ''});
    const [form, setform] = useState({ id: '', accomodation_type:'',country_id:'',status: 'active',emission_factor:'',tier:'' ,unit:'',scope:'',location:'',source:''})
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'status', required: true },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        let url = shared.addApi
        let value = {
            ...form,
            ...images,
            emission_type:shared.type
        }
        Object.keys(value).map(itm=>{
            value[itm]=value[itm]||null
        })
        if (value.id) {
            method = 'put'
            url = shared.editApi
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                history(`/${shared.url}`)
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get(shared.detailApi, { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = form

                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })
                    if(payload.country_id?._id) payload.country_id=payload.country_id._id
                    payload.id=id
                    setform({
                        ...payload
                    })

                    let img=images
                    Object.keys(img).map(itm => {
                        img[itm] = value[itm]
                    })
                    setImages({...img})


                }
                loader(false)
            })
        }

    }, [id])

    const imageResult = (e, key) => {
        images[key] = e.value
        setImages(images)
    }

    const getError = (key) => {
        return submitted?methodModel.getError(key, form, formValidation)?.message:''
    }

    const units=[
        "kgCO2e/room per night",
        "CO2e",
    ].map(itm=>({name:itm,id:itm}))

    const scopes=[1,2,3].map(itm=>({name:itm,id:itm}))

    const getCountries=(p={})=>{
        setCountryLoader(true)
        ApiClient.get('api/country/listing',{status:'active',sortBy:'name asc',...p}).then(res=>{
            setCountryLoader(false)
            if(res.success){
                setCountry(res.data)
            }
        })
    }


    useEffect(()=>{
        getCountries()
    },[])

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">


                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to={`/${shared.url}`} className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-base font-medium text-[#0A0D14]">
                                {form && form.id ? 'Edit' : 'Add'}  {shared.addTitle}
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your {shared.addTitle}</p>
                        </div>
                    </div>



                    <h3 className="ViewUser mb-3"></h3>
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="text"
                                label="Accommodation"
                                value={form.accomodation_type}
                                onChange={e => setform({ ...form, accomodation_type: e })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="number"
                                label="Emission Factor"
                                value={form.emission_factor}
                                onChange={e => setform({ ...form, emission_factor: e })}
                                
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="text"
                                label="Tier"
                                value={form.tier}
                                onChange={e => setform({ ...form, tier: e })}
                                
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="select"
                                label="Unit"
                                options={units}
                                placeholder="Select Option"
                                value={form.unit}
                                theme="search"
                                onChange={e => setform({ ...form, unit: e })}
                                
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="select"
                                label="Scope"
                                placeholder="Select Option"
                                value={form.scope}
                                options={scopes}
                                theme="search"
                                onChange={e => setform({ ...form, scope: e })}
                                required
                            />
                        </div>
                        

                        <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="select"
                                onInputChange={e=>{
                                    // getCountries({search:e})
                                }}
                                label={<>
                                Location
                                {countryLoader?<>
                                 <span className="ml-2 text-success text-xs">Loading...</span>
                                </>:<></>}
                                </>}
                                placeholder="Globally"
                                options={[
                                    {id:'',name:'Globally'},
                                    ...country
                                ]}
                                value={form.country_id}
                                theme="search"
                                onChange={e => setform({ ...form, country_id: e })}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <FormControl
                                type="text"
                                label="Source"
                                value={form.source}
                                onChange={e => setform({ ...form, source: e })}
                                
                            />
                        </div>
                        

                        <div className="col-span-12 md:col-span-6">
                        <FormControl
                                type="select"
                                name="status"
                                label="Status"
                                displayValue="name"
                                placeholder="Select Status"
                                value={form.status}
                                onChange={e => setform({ ...form, status: e })}
                                options={statusModel.list}
                                required
                                error={getError('status')}
                            />
                        </div>

                        {/* <div className="col-span-12 md:col-span-6">
                                <label className='lablefontcls'>Image</label><br></br>
                                <ImageUpload model="users" result={e => imageResult(e, 'image')} value={images.image || form.image} />
                            </div> */}
                    </div>
                    <div className="text-right mt-4">

                        <button type="submit" className=" text-white bg-[#545454] font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                    </div>
                </div>


            </form>
        </Layout>
    </>
}

export default AddEdit