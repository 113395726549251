import { HiOutlineArrowDown } from "react-icons/hi";
import Pagination from "react-pagination-js";

const Table = ({
  className = "",
  data = [],
  columns = [],
  topHead = [],
  count = 50,
  total = 0,
  page = 1,
  result = (e) => {},
  nodata = (
    <div className="no-data-found">
      <img
        src="/assets/img/no-data-f.png"
        className="w-[100px] mx-auto	opacity-50	"
      />
      <p className="uppercase	text-sm tracking-widest	">No Data Found</p>
    </div>
  ),
}) => {
  const handlePaginate = (e) => {
    console.log("e", e);
    result({ event: "page", value: e });
  };
  const view = (row) => {
    result({ event: "row", row: row });
  };

  const headclick = (itm) => {
    if (itm.sort) {
      result({ event: "sort", value: itm.key });
    }
  };

  return (
    <>
      <div className={`${className}`}>
      <div className="relative overflow-x-auto  mx-[24px] pt-[24px] border-t border-[#E2E4E9]">
              <table className="w-full  text-left rtl:text-right ">
                <thead className="text-[#525866] text-[14px] capitalize bg-[#F6F8FA] font-normal">
                  {topHead?.length ? (
                    <>
                      <tr className="bg-gray-200 border-b border-black">
                        {topHead.map((itm, i) => {
                          return (
                            <>
                              <th
                                scope="col"
                                className={`



px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-center font-[400]  ${
                                  topHead.length - 1 == i
                                    ? ""
                                    : "border-r border-black"
                                }`}
                                colSpan={itm?.colSpan || 0}
                                key={i}
                              >
                                {itm.name}
                              </th>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                  <tr>
                    {columns.map((itm) => {
                      return (
                        <>
                          <th
                            scope="col"
                            className={`text-[#525866] text-[14px] -tracking-[0.084px] px-[12px] py-[10px] font-[400] bg-[#6db5621f] ${
                              itm.sort ? "cursor-pointer" : ""
                            }`}
                            onClick={() => headclick(itm)}
                            key={itm.key}
                          >
                            {itm.name}{" "}
                            {itm.sort ? (
                              <>
                                <span className="ml-2">
                                  <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                {total?<>
                    <tbody>
                  {data.map((itm) => {
                    return (
                      <>
                        <tr
                          onClick={() => view(itm)}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                          key={itm.id}
                        >
                          {columns.map((citm) => {
                            return (
                              <>
                                <td className="px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px]">
                                  {citm.render(itm) || "--"}
                                </td>
                              </>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
                </>:<></>}
               
              </table>
            </div>
        {total ? (
          <>
           
          </>
        ) : (
          <>
            <div className="p-5 text-center">{nodata}</div>
          </>
        )}

        {count < total ? (
          <>
            <div className="paginationWrapper flex items-center justify-between mt-15">
              <span>
                Show {count} from {total} data
              </span>
              <Pagination
                currentPage={page}
                totalSize={total}
                sizePerPage={count}
                changeCurrentPage={handlePaginate}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Table;
