import React from "react";
import { Link } from "react-router-dom";
import methodModel from "../../../methods/methods";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import Sidebar from "../sidebar";
import { FiMenu, FiX } from "react-icons/fi";
import { TbChartBar } from "react-icons/tb";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Html = ({
  isOpen,
  toggle,
  searchHandle,
  search,
  user,
  isOpen1,
  searchChange,
  clear,
  Logout,
}) => {
  return (
    <nav
      component="header"
      className={`${
        isOpen ? "min-sidebar w-[calc(100%-80px)]" : "w-[calc(100%-280px)] "
      } shadow-btn py-[11px] px-[32px] bg-white border-b  fixed transition-[width] duration-300 ml-auto right-0 z-50 flex items-center 
      `}
    >
     

  
     <div className="flex items-center">
     <button
        onClick={toggle}
        className="h-9 w-9 shrink-0 shadow-btn hover:shadow-none p-1 rounded-lg border border-gray-100 !text-primary"
      >
        {!isOpen ? (
          <FiMenu className="w-full h-full" />
        ) : (
          <FiX className="w-full h-full" />
        )}
      </button>
        <h2 className="text-[#2B2B2B] font-medium text-[18px] ms-[10px]">
          Home
        </h2>
      </div>
      {/* <form className='headerSearch ml-3' onSubmit={searchHandle}>
        <input type="text" placeholder="Search..." value={search} onChange={e => searchChange(e.target.value)} className="Searchbar"></input>
        <i className="fa fa-search" onClick={searchHandle} aria-hidden="true"></i>
        {search ? <i className="fa fa-times" onClick={clear} aria-hidden="true"></i> : <></>}
      </form> */}

      {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button> */}

      <div className="flex justify-end items-center ml-auto">
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 11.75H15.5V13.25H0.5V11.75H2V6.5C2 4.9087 2.63214 3.38258 3.75736 2.25736C4.88258 1.13214 6.4087 0.5 8 0.5C9.5913 0.5 11.1174 1.13214 12.2426 2.25736C13.3679 3.38258 14 4.9087 14 6.5V11.75ZM12.5 11.75V6.5C12.5 5.30653 12.0259 4.16193 11.182 3.31802C10.3381 2.47411 9.19347 2 8 2C6.80653 2 5.66193 2.47411 4.81802 3.31802C3.97411 4.16193 3.5 5.30653 3.5 6.5V11.75H12.5ZM5.75 14.75H10.25V16.25H5.75V14.75Z"
            fill="#A5A3A3"
          />
        </svg>
        <Menu as="div" className="relative  ">
          <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-1 text-sm font-semibold text-gray-900 ">
              <div className="flex items-center">
                <div className="flex items-center">
                  <img
                    alt="image"
                    src={methodModel.userImg(user.image)}
                    className="h-10 w-10 rounded-full object-cover me-[12px] ms-[25px]"
                  />
                  <div className="ml-2 text-left">
                    <b>{user.fullName}</b>
                    <p className="grayCls mb-0 text-capitalize">
                      {user.role?.name}
                    </p>
                  </div>
                </div>
                <i
                  className="fa fa-angle-down top-1 relative h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/profile"
                      className={classNames(
                        active ? "bg-[#6db562] text-gray-900 hover:text-white" : "text-gray-700",
                        "block px-4 py-2 text-sm mb-1"
                      )}
                    >
                      <i className="far fa-user mr-2" /> Profile
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/profile/change-password"
                      className={classNames(
                        active ? "bg-[#6db562] text-gray-900 hover:text-white" : "text-gray-700",
                        "block px-4 py-2 text-sm mb-1"
                      )}
                    >
                      <i className="fa fa-cog mr-2" aria-hidden="true"></i>{" "}
                      Change-password
                    </Link>
                  )}
                </Menu.Item>

                <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                  <p className="border-t"></p>
                </Menu.Item>

                <Menu.Item className="">
                  {({ active }) => (
                    <Link
                      type="submit"
                      onClick={() => Logout()}
                      className={classNames(
                        active ? "bg-[#6db562] text-gray-900 hover:text-white" : " hover:text-white",
                        "block w-full px-4 py-2 text-left text-sm ancortag hover:text-white"
                      )}
                    >
                      <i className="fas fa-sign-out-alt mr-2" /> Logout
                    </Link>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>


      {isOpen1 ? (
        <div className="w-100 mobi-dropdown">
          <Sidebar />
        </div>
      ) : (
        <></>
      )}
    </nav>
  );
};

export default Html;
