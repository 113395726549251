const shared={
    check:'Entrydata',
    title:'Entry Data',
    addTitle:'Entry Data',
    url:'entry-data',
    type:'entry-data',
    addApi:'api/customfield',
    editApi:'api/customfield',
    detailApi:'api/customfield',
    listApi:'api/customfields',
    statusApi:'api/customfield',
    deleteApi:'api/customfield'
}

export default shared