import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { useSelector } from 'react-redux';
import datepipeModel from "../../../models/datepipemodel";
import SelectDropdown from '../../../components/common/SelectDropdown';
import { toast } from 'react-toastify';
import moment from 'moment';
import methodModel from '../../../methods/methods';
import pipeModel from '../../../models/pipeModel';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { Tooltip } from 'antd';
import { AiOutlineEye } from 'react-icons/ai';
import { FiEdit3 } from 'react-icons/fi';
import { BsDownload } from 'react-icons/bs';
import environment from '../../../environment';
import axios from 'axios';

const UserDetail = (p) => {
    const history = useNavigate()
    const user = useSelector(state => state.user)
    const { id, userId } = useParams()
    const [data, setData] = useState()
    const [planDetails, setplanDetails] = useState()
    const [trailDays, settrailDays] = useState()
    const [invoices, setInvoices] = useState([])
    const  [CustomerUsers,setCustomerUsers]=useState([]);

    const getDetail = (did) => {
        loader(true)
        ApiClient.get(`api/user/profile`, { id: did }).then(res => {
            if (res.success) {
                setData(res.data)
                settrailDays('')
            }
            loader(false)
        })
    }
    const back = () => {
        history(-1)
    }

    const statusChange = (itm) => {
        let modal = 'users'
        let status = 'active'
        if (itm.status == 'active') status = 'deactive'

        if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this user`)) {
            loader(true)
            ApiClient.put(`api/user/status/change`, { status, id: itm.id }).then(res => {
                if (res.success) {
                    GetCustomerUsers()
                }
                loader(false)
            })
        }
    }
    const getPlanDetails = () => {
        ApiClient.get(`api/my/plan?id=${id}`).then(res => {
            if (res.success) {
                setplanDetails(res.data)
            }
        })
    }

    useEffect(() => {
        getDetail(userId ? userId : id)
        getPlanDetails()
        getInvoices()
    }, [id, userId])

    const cancelPlan = () => {
        if (window.confirm('Do you really want to cancel the plan')) {
            ApiClient.delete(`api/cancel/subscription?id=${planDetails?.id}&userId=${id}`).then(res => {
                if (res.success) {
                    getDetail(userId ? userId : id)
                    getPlanDetails()
                }
            })
        }
    }



    const view = (id) => {
        history("/customer/view/" + id)
        
        window.scrollTo(0,0)
        setTimeout(() => {
            getDetail(id)
            getPlanDetails()
            GetCustomerUsers();
            getInvoices()
            
        }, 100);
    }
    const handleTrailDays = () => {
        let payload = {
            user_id: userId ? userId : id,
            extend_days: Number(trailDays)
        }
        ApiClient.put(`api/extend-trial`, payload).then(res => {
            if (res.success) {
                // toast.success(res.message)
                getPlanDetails()
                getDetail(userId ? userId : id)
            }
        })
    }

    const getInvoices = () => {
        let f = { userId: userId ? userId : id }
        ApiClient.get('api/invoices/list', f).then(res => {
            if (res.success) {
                setInvoices(res.data)
            }
        })
    }


    const GetCustomerUsers=()=>{
        ApiClient.get(`api/account/users?addedBy=${id}`).then(res=>{
            if(res.success){
                setCustomerUsers([...res?.data]);
            }
        })
    }
    useEffect(()=>{
        GetCustomerUsers()
    },[id])

    const edit = (id) => {
        history(`/customer/edit/${id}`)
    }

    const handleInvoiceDownloader=async(e,data)=>{
        loader(true);
        e.preventDefault();
        
            const token = await localStorage.getItem("token");
            const req = await axios({
                method: "get",
                url: `${environment.api}api/invoice/download?id=${data?.id||data?._id}`,
                responseType: "blob",
                body: { token: token }
            });
            var blob = new Blob([req.data], {
                type: req.headers["content-type"],
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${data?.invoiceNumber}.pdf`;
            loader(false)
            link.click();
        }

    
    return (
        <Layout>
            <div className='bg-white shadow-box rounded-lg w-full p-4 mt-6'>
                <div className="flex items-center mb-5">
                <div>
                        <a to="/users" onClick={back} className='!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border  transition-all    mr-3'>  <i className="    fa fa-angle-left text-lg " title='Back' aria-hidden="true"></i></a>
                    </div>
                <div>
                   <h3 className="text-base font-medium text-[#0A0D14]">
                    Ecometrixs Customers
                    </h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your Ecometrixs Customers</p>
                </div>
                   
                </div>
                <div className="grid grid-cols-12 gap-4">
                    <div className="sideclass col-span-12 md:col-span-12">
                        <h3 className="mt-3 mb-3 py-3 bg-[#6db5621f] px-3">Ecometrixs Customer Details</h3>
                        <div className="grid grid-cols-12 gap-4 mb-6">
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Name</label>
                                <div className='profiledetailscls'>{data && data.fullName}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Email</label>
                                <div className='profiledetailscls'>{data && data.email || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Mobile No</label>
                                <div className='profiledetailscls'>{data?.dialCode ? <>({data?.dialCode&& data?.dialCode}) {data && data.mobileNo}</> : <>--</>}</div>
                            </div>
                        </div>

                        <h3 className="mt-3 mb-3 py-3 bg-[#6db5621f] px-3">Company Profile</h3>
                        <div className="grid grid-cols-12 gap-4 mb-6">
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Company Name</label>
                                <div className='profiledetailscls'>{data && data?.companyName || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Contact Phone Number</label>
                                <div className='profiledetailscls'> {data?.companymobileno ? `+${data?.companymobileno}`: '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Company Email</label>
                                <div className='profiledetailscls'>{data && data.contactemail || '--'}</div>
                            </div>
                        </div>

                        <h3 className="mt-3 mb-3 py-3 bg-[#6db5621f] px-3">Company Information</h3>
                        <div className="grid grid-cols-12 gap-4 mb-6">
                            <div className="col-span-12 md:col-span-12">
                                <label className="profileheddingcls">Address</label>
                                <div className='profiledetailscls'>{data && data?.companyAddress || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Contact Name</label>
                                <div className='profiledetailscls'>{data && data.contactName || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Time Zone</label>
                                <div className='profiledetailscls'>{data && data.companytimezone || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Website</label>
                                <div className='profiledetailscls'>{data && data.website || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Financial Year</label>
                                <div className='profiledetailscls'>{data && datepipeModel.monthname(data.financialYear)}</div>
                            </div>
                        </div>
                        <h3 className='mt-3 mb-3 py-3    bg-[#6db5621f] px-3 flex justify-between items-center'>Plan Details {planDetails?.isActive ? <span class="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl " onClick={e => cancelPlan()}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"></path></svg></span> : null}</h3>
                        <div className='grid grid-cols-12 gap-4 mb-6'>
                            <div className='col-span-12 md:col-span-6'>
                                <label className="profileheddingcls">Plan Active</label>
                                <div className='profiledetailscls'>{planDetails && planDetails?.isActive ? 'Yes' : 'No'}</div>
                            </div>
                            {planDetails?.on_trial ?
                                <>
                                    <div className='col-span-12 md:col-span-6'>
                                        <label className="profileheddingcls">Extend Trail Period (Days)</label>
                                      <div className='flex items-center'>
                                      <input type='text' value={trailDays} minLength="1" maxLength="3" onChange={e => settrailDays(methodModel.isNumber(e))} className="shadow-box bg-white w-20 text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary" placeholder='Enter days to Extend Trail Period' />
                                        <button className='btn btn-primary ms-3' onClick={e => handleTrailDays()}>Save</button>
                                      </div>
                                    </div>
                                    
                                    <div className='col-span-12 md:col-span-6'>
                                        <label className='profileheddingcls'>Valid Upto</label>
                                        <div className='profiledetailscls'>{moment(planDetails && planDetails?.validUpTo).format('DD MMM YYYY')}</div>
                                    </div>
                                </>
                                :
                                null
                            }
                            {planDetails?.isActive ?
                                <>
                                    <div className='col-span-12 md:col-span-6'>
                                        <label className="profileheddingcls">Plan</label>
                                        <div className='profiledetailscls'>{planDetails && planDetails?.planId?.name || '--'}</div>
                                    </div>
                                    {!planDetails?.on_trial ?
                                        <div className='col-span-12 md:col-span-6'>
                                            <label className="profileheddingcls">Months</label>
                                            <div className='profiledetailscls'>{planDetails && planDetails?.planInterval || '--'}</div>
                                        </div>
                                        : null}
                                    <div className='col-span-12 md:col-span-6'>
                                        <label className="profileheddingcls">Payments Made</label>
                                        <div className='profiledetailscls'>{planDetails && datepipeModel.date(planDetails?.updatedAt)}</div>
                                    </div>

                                    <div className='col-span-12 md:col-span-6'>
                                        <label className="profileheddingcls">Next Payment Due</label>
                                        <div className='profiledetailscls'>{planDetails && datepipeModel.date(planDetails?.validUpTo)}</div>
                                    </div>
                                </>
                                :
                                null
                            }
                        </div>

                        <h3 className='mt-3 mb-3 py-3 bg-[#6db5621f] px-3'>Plan Invoices</h3>
                        <table class="w-full mb-6">
                            <thead className='text-[#525866] text-[14px] capitalize bg-[#F6F8FA] font-normal'>
                                <tr>
                                    <th className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]'>Invoice Number</th>
                                    <th className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]'>Paid At</th>
                                    <th className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]'>Paid Amount</th>
                                    <th className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]'>Description</th>
                                    <th className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map(itm => {
                                    return <tr>
                                        <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{itm.invoiceNumber}</td>
                                        <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{datepipeModel.date(itm.createdAt)}</td>
                                        <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{pipeModel.currency(itm.paidAmount)}</td>
                                        <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{itm.description}</td>
                                        <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'><BsDownload className="!mt-3 flex gap-1.5 items-center text-sm font-normal !text-primary"  title='Download Invoice' onClick={e=>handleInvoiceDownloader(e,itm)}/></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        {invoices.length ? <></> : <>
                            <div className="no-data-found text-center p-5">
                                <img
                                    src="/assets/img/no-data-f.png"
                                    className="w-[100px] mx-auto	opacity-50	"
                                />
                                <p className="uppercase	text-sm tracking-widest ">No Data Found</p>
                                </div>
                        </>}


{/* Here is the Data for the  */}
                        <h3 className='mt-3 mb-3 py-3 bg-[#6db5621f] px-3'>Users</h3>
                        <table class="w-full">
                            <thead className="text-[#525866] text-[14px] capitalize bg-[#F6F8FA] font-normal">
                                <tr>
                                    <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]' onClick={e => sorting('fullName')}>Contact Person<span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                    <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]' >Create Date</th>
                                    <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]' >Status</th>
                                    <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f]' >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { CustomerUsers.map((itm, i) => {
                                    return <tr className=''>
                                        <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]' onClick={e => view(itm.id)}>
                                            <div className='user_detail'>
                                                <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                                <div className='user_name'>
                                                    <h4 className='user'>
                                                        {itm.fullName}
                                                    </h4>
                                                    <p className='user_info'>
                                                        {itm.email}
                                                    </p>
                                                    <p className='user_info'>
                                                        {itm.mobileNo ? <>+{itm.mobileNo}</> : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>

                                        <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{datepipeModel.date(itm?.createdAt)}</td>
                                        <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'> <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                            <span className='contract'>
                                                {itm.status == 'deactive' ? 'Inactive' : 'Active'}
                                            </span>
                                        </div></td>
                                        {/* <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{itm.companyAddress || '--'}</td> */}
                                        {/* <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{itm.website||'--'}</td> */}
                                        <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>
                                            <div className="flex items-center  gap-1.5">
                                                <Tooltip placement="top" title="View">
                                                    <a className='border border-[#EBEBEB] rounded-lg bg-[#FAFAFA] hover:opacity-70 w-10 h-10 text-typo flex items-center justify-center text-xl' title="View" onClick={e => view(itm.id)}>
                                                        <AiOutlineEye />
                                                    </a>
                                                </Tooltip>
                                                <Tooltip placement="top" title="Edit">
                                                    <a className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl' title="Edit" onClick={e => edit(itm.id)}>
                                                        <FiEdit3 />
                                                    </a>
                                                </Tooltip>
                                            </div>
                                        </td>
                                        {/* end */}
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>
                        {CustomerUsers.length ? <></> : <>
                            <div className="no-data-found text-center p-5">
                            <img
                                src="/assets/img/no-data-f.png"
                                className="w-[100px] mx-auto	opacity-50	"
                            />
                            <p className="uppercase	text-sm tracking-widest ">No Data Found</p>
                            </div>
                        </>}

                    </div>
                </div>
            </div>



        </Layout >

    );
};

export default UserDetail;
