import React, { useEffect, useState } from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import datepipeModel from '../../models/datepipemodel';
import methodModel from '../../methods/methods';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { HiOutlineArrowDown } from 'react-icons/hi';
import { FiEdit3 } from 'react-icons/fi';


const Html = ({
    sorting, sortClass, filters, data, user, loaging, total, edit, statusChange, dynamicStyle = false,
    className = null, ChangeStatus,
}) => {

    return (
        <Layout>
            <div className="flex justify-between items-center">

                <div>
                   <h3 className="text-base font-medium text-[#0A0D14]">
                        User Reviews
                    </h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Users</p>
                </div>




                <div className="dropdown1 addDropdown1 mr-2">
                    <Menu as="div" className="relative list_box_active_state ml-auto">
                        <div>
                            <Menu.Button className="flex items-center w-full border justify-center rounded-[8px] bg-white py-[9px] px-[12px] text-[14px]   font-normal  text-[#525866]  hover:bg-gray-200 font-medium -tracking-[0.084px] border-[#DFE1E6]">
                                {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#6db562] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-lg scrollbar`}>
                                <div className="mt-2">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("")}>All Status</a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("active")} >Active</a>
                                        )}
                                    </Menu.Item>

                                    <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                        <p className="border-t"></p>
                                    </Menu.Item>


                                    <Menu.Item className="">
                                        {({ active }) => (
                                            <a className={filters.status == 'Inactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                                        )}
                                    </Menu.Item>

                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>


                </div>
            </div>

            <div className="shadow-box w-full bg-white rounded-[16px] mt-6 p-[24px]">
                <div className="scrollbar w-full overflow-auto">


                    <table class="w-full">
                        <thead className="text-[#525866] text-[14px] capitalize bg-[#F6F8FA] font-normal">
                            <tr>
                                <th scope="col" className='



px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] pointer' >Logo </th>
                                <th scope="col" className='



px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] pointer' onClick={e => sorting('name')}>Platform Name <HiOutlineArrowDown className="shrink-0 inline text-sm" /></th>
                                <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] pointer'>Status</th>
                                <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] pointer' onClick={e => sorting('createdAt')}>Date Created <HiOutlineArrowDown className="shrink-0 inline text-sm" /></th>
                                <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] pointer' onClick={e => sorting('updatedAt')}>Date Updated <HiOutlineArrowDown className="shrink-0 inline text-sm" /></th>

                                <th scope="col" className='px-[12px] py-[10px] text-[#525866] text-[14px] -tracking-[0.084px] text-left  font-[400] bg-[#6db5621f] pointer'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loaging && data && data.map((itm, i) => {
                                return <tr className=''>
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9] pointer' onClick={e => {
                                        localStorage.setItem('review', JSON.stringify({ platform: itm.platform, logo: itm.logo }))
                                        edit(itm.id)
                                    }
                                    }> <img src={methodModel.userImg(itm.logo || itm.image)} className="cat_img" /></td>
                                    {/* <img src="/assets/img/gogle.png" class="wallets_img3 mr-2"></img> */}
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{itm.name || itm.platform}</td>
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'> <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                        <Tooltip placement="top" title="Active / Inactive">
                                            <span className='contract'>
                                                {itm.status == 'deactive' ? 'inactive' : 'active'}
                                            </span>
                                        </Tooltip>
                                    </div></td>
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{datepipeModel.date(itm.createdAt, user?.companyDateFormat)}</td>
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>{datepipeModel.date(itm.updatedAt, user?.companyDateFormat)}</td>


                                    {/* dropdown */}
                                    <td className='px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]'>
                                        <div className="flex items-center  gap-1.5">
                                            {itm.isAdmin ? <></> : <>
                                                <Tooltip placement="top" title="Edit">
                                                    <a className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl" onClick={e => {
                                                        localStorage.setItem('review', JSON.stringify({ platform: itm.platform, name: itm.name, logo: itm.logo }))
                                                        edit(itm.id)
                                                    }
                                                    }
                                                    >
                                                        <FiEdit3 />
                                                    </a>
                                                </Tooltip>

                                            </>}



                                        </div>
                                    </td>
                                    {/* end */}
                                </tr>
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {!loaging && total == 0 ?  <div className="no-data-found text-center p-5">
                    <img
                        src="/assets/img/no-data-f.png"
                        className="w-[100px] mx-auto	opacity-50	"
                    />
                    <p className="uppercase	text-sm tracking-widest ">No Data Found</p>
                    </div> : <></>}
            {
                !loaging && total > filters.count ? <div className='paginationWrapper  mt-15'>
                    {/* <span className='text-sm text-gray-600'>Show {filters.count} from {total} Categories</span> */}
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }

            {loaging ? <div className="text-center py-4">
                <img src="/assets/img/loader.gif" className="pageLoader" />
            </div> : <></>}
        </Layout>
    );
};

export default Html;
