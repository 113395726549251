const shared={
    check:'Procurement',
    title:'Procurements',
    addTitle:'Procurement',
    url:'procurement',
    type:'procurement',
    addApi:'api/emission-factor',
    editApi:'api/emission-factor',
    detailApi:'api/emission-factor',
    listApi:'api/emission-factors',
    statusApi:'api/emission-factor',
    deleteApi:'api/emission-factor'
}

export default shared