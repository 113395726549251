import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import methodModel from "../../../methods/methods";
import SelectDropdown from "../../../components/common/SelectDropdown";
import { earlybirdpricingType } from "../../../models/type.model";
import requiredModel from "../../../models/required.model";
import daysModel from "../../../models/days.modal";
import NowLaterModel from "../../../models/nowlater.model";
import countModel from "../../../models/count.model";
import dynamicPriceModel from "../../../models/dynamicPrice.model";
import Layout from "../../../components/global/layout";
import './style.scss';
import DatePicker from "react-datepicker";
import datepipeModel from "../../../models/datepipemodel";
import MultiSelectDropdown from "../../../components/common/MultiSelectDropdown";
import Select from "react-select";
import DateRangePicker from "../../../components/common/DateRangePicker";
import { Tooltip } from "antd";

const AddEditLastMinuteFixedDates = () => {
    const { id, type, copy } = useParams()
    const defaultvalue = () => {
        let keys = { ...earlybirdpricingType }
        Object.keys(earlybirdpricingType).map(itm => {
            if (itm != 'permissions') keys[itm] = ''
        })
        keys.status = 'active'
        return keys
    }

    const symbol = [
        { id: '<', name: '<' },
        { id: '>', name: '>' }
    ]

    const dummyArray = [
        { time: '0900 to 1000' },
        { time: '1000 to 1100' },
        { time: '1400 to 1500' },
        { time: '1500 to 1600' }
    ]
    const [form, setform] = useState(earlybirdpricingType)
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const [date, setdate] = useState([{ startDate: '', endDate: '' }])
    const [timeslot, settimeslot] = useState()
    const [country, setcountry] = useState()

    const formValidation = [
        { key: 'name', required: true },
        // { key: 'amountOrPercent', required: true },
        { key: 'country', required: true },
        // { key: 'changesDate', required: true },
        // { key: 'changesDateTo', required: true },
        // { key: 'lastMinutePricingFromDate', required: true },
        // { key: 'lastMinutePricingToDate', required: true },
        { key: 'notApplicableFor', required: true },
        // { key: 'discOrPre', required: true },

    ]

    const applicables = ["State", "National"]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        let url = 'api/dynamic/pricing'
        let value = {
            ...form, type: 'lastminutefixeddates', blackOutDates: date
        }
        if (value.id && copy == 'false') {
            method = 'put'
            url = 'api/dynamic/pricing/update'
        } else {
            delete value.id
        }
        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                history("/dynamicpricelist")
            }
            loader(false)
        })
    }

    useEffect(() => {
        getCountry()
    }, [])

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get('api/dynamic/pricing/detail', { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = earlybirdpricingType
                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })
                    console.log(payload, "Payload");
                    if (payload.lastMinutePricingFromDate) payload.lastMinutePricingFromDate = new Date(payload.lastMinutePricingFromDate)
                    if (payload.lastMinutePricingToDate) payload.lastMinutePricingToDate = new Date(payload.lastMinutePricingToDate)
                    payload.changesDate = payload.changesDate ? new Date(payload.changesDate) : ''
                    payload.changesDateTo = payload.changesDateTo ? new Date(payload.changesDateTo) : ''
                    if (payload.blackOutDates) {
                        setdate([...payload.blackOutDates.map(itm => {
                            return { startDate: itm.startDate ? new Date(itm.startDate) : '', endDate: itm.endDate ? new Date(itm.endDate) : '' }
                        })])
                    }
                    if (copy == 'true') {
                        payload.name = `Copy of ${payload.name}`
                    }
                    setform({
                        ...payload
                    })
                }
                loader(false)
            })
        } else {
            setform(defaultvalue())
        }
    }, [id])

    const selectAll = (checked) => {
        if (checked) {
            setform({ ...form, notApplicableFor: [...applicables] });
        } else {
            setform({ ...form, notApplicableFor: [] });
        }

    }

    const setchecks = (value, checked) => {
        let applyFor = form.notApplicableFor || []
        if (checked == true) {
            applyFor.push(value)
        }
        else {
            applyFor = applyFor.filter(itm => itm != value)
        }
        setform({ ...form, notApplicableFor: applyFor })
    }

    const addmore = () => {
        setdate([...date, { blackOutDates: '' }])
    }
    const remove = (index) => {
        const rows = [...date];
        rows.splice(index, 1);
        setdate(rows);
    }

    const setalldates = (index, value, key) => {
        const field = [...date]
        let endDate = value
        if (key == 'startDate') endDate = value
        field[index] = { ...field[index], [key]: value, endDate: endDate }
        setdate([...field]);
    }

    const setalldays = (checked) => {
        if (checked) {
            setform({ ...form, daysToApply: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] });
        } else {
            setform({ ...form, daysToApply: [] });
        }
    }

    const setdays = (value, checked) => {
        let array = form.daysToApply || []
        if (checked == true) {
            array.push(value)
        }
        else {
            array = array.filter(item => item != value)
        }
        setform({ ...form, daysToApply: array })
    }

    const setTimeSlot = (data, checked) => {
        let timeslotarray = form.timeSlots || []
        if (checked == true) {
            timeslotarray.push(data)
        }
        else {
            timeslotarray = timeslotarray.filter(item => item.time != data.time)
        }
        setform({ ...form, timeSlots: timeslotarray })
    }

    const setAllTimeSlots = (checked) => {
        if (checked == true) {
            setform({ ...form, timeSlots: dummyArray.map(item => item) })
        }
        else {
            setform({ ...form, timeSlots: [] })
        }
    }

    const getCountry = () => {
        ApiClient.get(`api/holidays/countries`).then(res => {
            if (res.success) {
                let data = Object.keys(res.data).map(item => {
                    return ({ ...country, value: item.toLowerCase(), label: res.data[item] })
                })
                data = data.sort((a, b) => {
                    return a.name - b.name
                })
                setcountry(data)
            }
        })
    }

    const dateconvert = (date) => {
        if (!date) {
            setform({ ...form, startDate: '', endDate: '' });
        }
        else {
            const startdatedate = date[0];
            const enddate = date[1];
            const convertedstart = datepipeModel.datetostring(startdatedate);
            const convertedend = datepipeModel.datetostring(enddate);
            setform({ ...form, startDate: convertedstart, endDate: convertedend });
        }
    }

    const handlecountry = () => {
        let value = ''
        if (form.country) {
            value = country && country.find(item => item.value == form.country)
        }
        return value
    }

    const isAllNotApplicable = () => {
        let value = true
        if (form && !form.notApplicableFor) {
            return false
        }
        applicables.map(itm => {
            if (form && !form.notApplicableFor.find(titm => titm == itm)) {
                value = false
            }
        })
        return value
    }

    const blockDateChange = (e, index) => {
        const field = [...date]
        let startDate = e.startDate ? datepipeModel.datetostring(e.startDate) : null
        let endDate = e.endDate ? datepipeModel.datetostring(e.endDate) : null
        field[index] = { ...field[index], endDate, startDate }
        setdate([...field]);
    }

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">

                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to={`/dynamicpricelist`} className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border  transition-all    mr-3"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                           <h3 className="text-base font-medium text-[#0A0D14]">
                                {form && form.id && copy == 'false' ? 'Edit' : 'Add'} Last Minute Pricing Fixed Dates
                            </h3>

                        </div>
                    </div>




                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <label>Rule Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder='Last Minute Pricing'
                                value={form.name}
                                onChange={e => setform({ ...form, name: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Country<span className="star">*</span></label>
                            <Select
                                options={country}
                                placeholder="Select Country"
                                isClearable={true}
                                value={handlecountry()}
                                onChange={e => setform({ ...form, country: e ? e.value : '' })}
                            />
                            {submitted && !form?.country ? <div className="text-danger">Country is Required</div> : <></>}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Display Date From and To<span className="star">*</span></label>
                            <div className="grid grid-cols-12 gap-4">
                                <span className="col-span-12 md:col-span-6">
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Choose From Date"
                                        minDate={new Date()}
                                        selected={form.changesDate}
                                        onChange={(date) => { setform({ ...form, changesDate: date, changesDateTo: date }) }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </span>
                                <span className="col-span-12 md:col-span-6">
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Choose To Date"
                                        selected={form.changesDateTo}
                                        minDate={form.changesDate || new Date()}
                                        onChange={(date) => { setform({ ...form, changesDateTo: date }) }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </span>
                            </div>
                            {/* {submitted && (!form?.changesDate || !form?.changesDateTo) ? <div className="text-danger">Display Date Range is Required</div> : <></>} */}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Apply Last Minutes Pricing - From Dates<span className="star">*</span></label>
                            <div>
                                <DatePicker
                                    className="form-control"
                                    placeholderText="Choose a Date"
                                    selected={form.lastMinutePricingFromDate}
                                    minDate={new Date()}
                                    onChange={(date) => { setform({ ...form, lastMinutePricingFromDate: date, lastMinutePricingToDate: date }) }}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </div>
                            {/* {submitted && !form?.lastMinutePricingFromDate ? <div className="text-danger">Apply Last Minutes Pricing From Dates is Required</div> : <></>} */}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Apply Last Minutes Pricing - To Dates<span className="star">*</span></label>
                            <div>
                                <DatePicker
                                    className="form-control"
                                    placeholderText="Choose a Date"
                                    selected={form.lastMinutePricingToDate}
                                    minDate={form.lastMinutePricingFromDate || new Date()}
                                    onChange={(date) => { setform({ ...form, lastMinutePricingToDate: date }) }}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </div>
                            {/* {submitted && !form?.lastMinutePricingToDate ? <div className="text-danger">Apply Last Minutes Pricing To Dates is Required</div> : <></>} */}
                        </div>
                        {/* <div className="col-span-12 md:col-span-6">
                            <label>Do not apply early bird pricing if the number of available spaces is<span className="star">*</span></label>
                            <span className="d-flex">
                                <SelectDropdown
                                    id="statusDropdown"
                                    displayValue="name"
                                    placeholder="Select One"
                                    intialValue={form.notApplyCondition}
                                    result={e => { setform({ ...form, notApplyCondition: e.value }) }}
                                    options={symbol}
                                />
                                <input
                                    type="text"
                                    className="form-control w-25 ml-3"
                                    placeholder="50%"
                                    required
                                    minLength={1}
                                    maxLength={10}
                                    value={form.notApply}
                                    onChange={e => setform({ ...form, notApply: methodModel.isNumber(e) })}
                                    />
                            </span>
                            {submitted && !form.notApplyCondition ? <div className="text-danger">Early Bird Pricing is Required</div> : <></>}
                        </div> */}
                        <div className="col-span-12 md:col-span-12">
                            <label>Do not apply last minute pricing<span className="star">*</span></label>
                            <div class="checkPrice d-flex">
                                <label>
                                    <input type="checkbox" onChange={e => selectAll(e.target.checked)} checked={isAllNotApplicable()} className="mr-1" />
                                    All
                                </label>
                                {applicables.map(itm => {
                                    return <label>
                                        <input type="checkbox" className="mr-1" checked={form.notApplicableFor.includes(itm)} onChange={e => setchecks(itm, e.target.checked)} />
                                        {itm}
                                    </label>
                                })}
                            </div>
                            {submitted && !form.notApplicableFor ? <div className="text-danger">Do not apply early is Required</div> : <></>}
                        </div>
                        <div className="col-span-12 md:col-span-12">
                            <label>Add Block out Dates or Date Range<span className="star">*</span></label>
                            <div className="grid grid-cols-12 gap-4">
                                {date && date.map((item, index) => {
                                    return <div className="col-span-12 md:col-span-4">

                                        <DateRangePicker
                                            value={item}
                                            id={index}
                                            onChange={e => blockDateChange(e, index)}
                                        />

                                        {index > 0 ?
                                            <i class="material-icons inputOverlapIcon text-danger pointer" title="Remove" onClick={e => remove(index)}>remove</i>
                                            : null}
                                    </div>
                                })}
                                <div className="col-span-12 md:col-span-4 text-left">
                                    <i class="material-icons bg-primary addIc" title="Add More" onClick={e => addmore()}>add</i>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4 mb-3">
                            <label>Apply to all days and all time slots<span className="star">*</span></label>
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Yes/No"
                                intialValue={form.applyToDaysTimeSlot}
                                result={e => { setform({ ...form, applyToDaysTimeSlot: e.value }) }}
                                options={requiredModel.list}
                            />
                            {submitted && !form.applyToDaysTimeSlot ? <div className="text-danger">All days and all time slot is Required</div> : <></>}
                        </div>
                        {form?.applyToDaysTimeSlot == 'no' ?
                            <div className="col-md-12 mb-3">
                                <label>Select Days to apply<span className="star">*</span></label>
                                <div>
                                    <div className='d-flex'>
                                        <div class="form-check ml-1">
                                            <input type="checkbox" class="form-check-input" id="exampleCheck1" onClick={e => setalldays(e.target.checked)} checked={form.daysToApply.includes('monday') && form.daysToApply.includes('tuesday') && form.daysToApply.includes('wednesday') && form.daysToApply.includes('thursday') && form.daysToApply.includes('friday') && form.daysToApply.includes('saturday') && form.daysToApply.includes('sunday')} />
                                            <label class="form-check-label" for="exampleCheck1">All</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Monday" checked={form.daysToApply.includes('monday')} onClick={e => setdays('monday', e.target.checked)} />
                                            <label class="form-check-label" for="Monday">Monday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Tuesday" checked={form.daysToApply.includes('tuesday')} onClick={e => setdays('tuesday', e.target.checked)} />
                                            <label class="form-check-label" for="Tuesday">Tuesday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Wednesday" checked={form.daysToApply.includes('wednesday')} onClick={e => setdays('wednesday', e.target.checked)} />
                                            <label class="form-check-label" for="Wednesday">Wednesday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Thursday" checked={form.daysToApply.includes('thursday')} onClick={e => setdays('thursday', e.target.checked)} />
                                            <label class="form-check-label" for="Thursday">Thursday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Friday" checked={form.daysToApply.includes('friday')} onClick={e => setdays('friday', e.target.checked)} />
                                            <label class="form-check-label" for="Friday">Friday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Saturday" checked={form.daysToApply.includes('saturday')} onClick={e => setdays('saturday', e.target.checked)} />
                                            <label class="form-check-label" for="Saturday">Saturday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Sunday" checked={form.daysToApply.includes('sunday')} onClick={e => setdays('sunday', e.target.checked)} />
                                            <label class="form-check-label" for="Sunday">Sunday</label>
                                        </div>
                                    </div>
                                </div>
                                {submitted && !form.daysToApply ? <div className="text-danger">Days to Apply is Required</div> : <></>}
                            </div>
                            : null} */}
                        <div className="col-span-12 md:col-span-6">
                            <label>Would you like to Apply Discount (-) or Add Premium (+)<span className="star">*</span></label>
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Discount (-) Or Add Premium (+)"
                                name="discOrPre"
                                intialValue={form.discOrPre}
                                result={e => { setform({ ...form, discOrPre: e.value }) }}
                                options={[
                                    { name: 'Discount', id: 'Discount' },
                                    { name: 'Premium', id: 'Premium' }
                                ]}
                            />
                            {/* {submitted && !form?.discOrPre ? <div className="text-danger">Discount (-) or Add Premium (+) is Required</div> : <></>} */}
                        </div>
                        <div className="col-span-12 md:col-span-4">
                            <label>Apply % or Amount<span className="star">*</span></label>
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="% or Amount"
                                intialValue={form.amountOrPercent}
                                result={e => { setform({ ...form, amountOrPercent: e.value }) }}
                                options={countModel.list}
                            />
                            {/* {submitted && !form.amountOrPercent ? <div className="text-danger">Apply % or Amount is Required</div> : <></>} */}
                        </div>
                        <div className="col-span-12 md:col-span-4">
                            <label>Add Number(please add number only not $ or % sign)<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control py-3"
                                placeholder=''
                                minLength={1}
                                maxLength={10}
                                value={form.number}
                                onChange={e => setform({ ...form, number: methodModel.isNumber(e) })}
                            />
                        </div>
                        {/* <div className="col-md-12 mb-3">
                            <label>Apply to all price types<span className="star">*</span></label>
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Yes/No"
                                intialValue={form.applyPriceType}
                                result={e => { setform({ ...form, applyPriceType: e.value }) }}
                                options={requiredModel.list}
                            />
                            {submitted && !form.applyPriceType ? <div className="text-danger">All Price Types is Required</div> : <></>}
                        </div>
                        {form?.applyPriceType == 'no' ?
                            <div className="col-md-12">
                                <table class="table table-striped">
                                    <thead className="text-[#525866] text-[14px] capitalize bg-[#F6F8FA] font-normal">
                                        <tr className="heading_row">
                                            <th className="table_data" scope="col">Label</th>
                                            <th className="table_data" scope="col">Current Price</th>
                                            <th className="table_data" scope="col"></th>
                                            <th className="table_data" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><label><input type='checkbox' />Adult</label></th>
                                            <td>$220</td>
                                            <td>
                                                <SelectDropdown
                                                    id="statusDropdown"
                                                    displayValue="name"
                                                    placeholder="Discount % or $"
                                                    intialValue={form.amountOrPercent}
                                                    result={e => { setform({ ...form, amountOrPercent: e.value }) }}
                                                    options={countModel.list}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder='Amount or %'
                                                    minLength={1}
                                                    maxLength={10}
                                                    required
                                                    value={form.number}
                                                    onChange={e => setform({ ...form, number: methodModel.isNumber(e) })}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><label><input type='checkbox' />Child</label></th>
                                            <td>$220</td>
                                            <td>
                                                <SelectDropdown
                                                    id="statusDropdown"
                                                    displayValue="name"
                                                    placeholder="Discount % or $"
                                                    intialValue={form.amountOrPercent}
                                                    result={e => { setform({ ...form, amountOrPercent: e.value }) }}
                                                    options={countModel.list}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder='Amount or %'
                                                    minLength={1}
                                                    maxLength={10}
                                                    required
                                                    value={form.number}
                                                    onChange={e => setform({ ...form, number: methodModel.isNumber(e) })}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><label><input type='checkbox' />Family</label></th>
                                            <td>$220</td>
                                            <td>
                                                <SelectDropdown
                                                    id="statusDropdown"
                                                    displayValue="name"
                                                    placeholder="Discount % or $"
                                                    intialValue={form.amountOrPercent}
                                                    result={e => { setform({ ...form, amountOrPercent: e.value }) }}
                                                    options={countModel.list}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder='Amount or %'
                                                    minLength={1}
                                                    maxLength={10}
                                                    required
                                                    value={form.number}
                                                    onChange={e => setform({ ...form, number: methodModel.isNumber(e) })}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><label><input type='checkbox' />Student</label></th>
                                            <td>$220</td>
                                            <td>
                                                <SelectDropdown
                                                    id="statusDropdown"
                                                    displayValue="name"
                                                    placeholder="Discount % or $"
                                                    intialValue={form.amountOrPercent}
                                                    result={e => { setform({ ...form, amountOrPercent: e.value }) }}
                                                    options={countModel.list}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder='Amount or %'
                                                    minLength={1}
                                                    maxLength={10}
                                                    required
                                                    value={form.number}
                                                    onChange={e => setform({ ...form, number: methodModel.isNumber(e) })}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            : null} */}
                        {/* <div className="col-md-6 mb-3">
                            <label>Apply to all available spaces<span className="star">*</span></label>
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Yes/No"
                                intialValue={form.availablespaces}
                                result={e => { setform({ ...form, availablespaces: e.value }) }}
                                options={requiredModel.list}
                            />
                            <div className="table-responsive table_section border">
                                <table class="table table-striped">
                                    <thead className="text-[#525866] text-[14px] capitalize bg-[#F6F8FA] font-normal">
                                        <tr className="heading_row">
                                            <th className="table_data" scope="col">Times</th>
                                            <th className="table_data" scope="col">Spaces</th>
                                            {form.daysToApply && form.daysToApply.map((item, index) => {
                                                return <th scope="col" className="table_data text-capitalize">{item}</th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dummyArray && dummyArray.map((itm, i) => {
                                            return <tr>
                                                <td>{itm.time}</td>
                                                {form.daysToApply && form.daysToApply.map((item, index) => {
                                                    return <td scope="row"></td>
                                                })}
                                                <td className="w-50"><input type='text' placeholder="Spaces" className="p-1" /></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {submitted && !form.timeSlots ? <div className="text-danger">Time Slot is Required</div> : <></>}
                        </div> */}
                    </div>
                    <div className="flex justify-end mt-6">

                        <button type="submit" className=" text-white bg-[#545454] font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                    </div>
                </div>
            </form>
        </Layout>
    </>
}

export default AddEditLastMinuteFixedDates